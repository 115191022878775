import { Injectable } from '@angular/core';
import { UserService } from 'src/app/services/core/user.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from 'src/app/services/core/events.service';
import { TranslationService } from 'src/app/services/core/translation.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private _settings: any = {};

  constructor(
    private cache: CacheService,
    private events: EventsService,
    private translations: TranslationService,
    public userService: UserService,
  ) {

  }

  async getCachedSettings() {
    let cacheKey: string = this.getCacheKey(),
        fromCache: cacheItem = await this.cache.get(cacheKey, -1);

    console.log('cacheKey', cacheKey);
    console.log('settings fromCache', fromCache);
    
    return (!!fromCache && !!fromCache.data ? JSON.parse(fromCache.data) : {});
  }

  getCacheKey(userId: number|null = null) {

    if(!userId) {
      let user = this.userService.getUser() || { uid: -1};
      userId = (!!user && !!user.uid ? user.uid : -1);
    }

    return `user_settings_${userId || -1}`.replace('-', '__');
  }

  getSetting(key: string, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.getSettings(null, blForceRefresh)
      .then((settings: any) => {
        resolve(settings[key]);
      })
      .catch((error: any) => {
        if(error && error.toLowerCase().indexOf('error') !== -1) {
          reject(error);
        } else {
          resolve(error); // resolve(), because empty setting is okay here
          // @todo validate error
        }
      });
    });
  }

  getSettings(userId: number = null, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      userId = userId || this.userService.getUid();

      if(this._settings[userId] && !blForceRefresh) {
        resolve(this._settings[userId]);
      } else
      if(!userId || (userId === -1)) {
        this.getCachedSettings().then(resolve).catch(reject);
      } else {
        this.userService.getByUid(userId, blForceRefresh)
        .then((user: user) => {
          this._settings[userId] = user && user.classifications && user.classifications.settings ? user.classifications.settings : {};
          resolve(this._settings[userId]);
        })
        .catch(reject);
      }
    });
  }

  setSettings(settings: settings, userId: number = null) {
    let cacheKey: string = this.getCacheKey(userId);
    return this.cache.set(cacheKey, JSON.stringify(settings));
  }

  async showUpdatedToast() {
    this.translations.get([
      'settings_updated',
    ])
    .subscribe((response: any) => {
      this.events.publish('toast', {
        icon: 'checkmark-outline',
        message: response.settings_updated || 'settings_updated',
        color: 'primary',
      });
    });
  }

  updateSettings(settings: settings, blAllowLocal: boolean = true) {
    return new Promise((resolve, reject) => {
      let user = this.userService.getUser() || {};
      
      console.log('updateSettings', settings);

      if(!user.uid && !!blAllowLocal) {
        this.setSettings(settings).then(resolve).catch(reject);
      } else
      if(!user.uid && !blAllowLocal) {
        reject('error_missing_user_uid');
      } else
      if(user.uid === -1) {
        user.classifications = user.classifications || {};
        user.classifications.settings = user.classifications.settings || {};
        user.classifications.settings = Object.assign(user.classifications.settings, settings);

        this.userService.setUser(user, false);
        resolve(user);
      } else {
        this.getSettings(user.uid, true)
        .then((_settings: settings) => {
          settings = Object.assign(_settings, settings);
          user.classifications.settings = settings;
          
          this.setSettings(settings)
          .then(() => {
            this.userService.setUser(user, true).then(resolve).catch(reject);
          })
          .catch(reject);
        })
        .catch(reject);
      }
    });
  }

  updateSetting(key: string, value: any) {
    let settings = {};
    settings[key] = value;
    return this.updateSettings(settings);
  }

}
