import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

import { CardOptionsPage } from 'src/app/pages/legacy/card-options/card-options.page';
import { VerifiedInfoPage } from 'src/app/pages/account/verified-info/verified-info.page';

import { AccountsService } from 'src/app/services/core/accounts.service';
import { AdminService } from 'src/app/services/core/admin.service';
import { BionicReadingService } from 'src/app/services/extensions/bionic-reading.service';
import { CommentsService } from 'src/app/services/social/comments.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ExternalService } from 'src/app/services/core/external.service';
import { ReactionsService } from 'src/app/services/social/reactions.service';
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'pipeline-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostCardComponent implements OnInit {
  @Input() options: postCardOptions = {};
  @Input() post: post|col;
  @Input() size: number;

  config: pipelineAppConfig;

  view: any = {
    expandReactionsView: false,
  };

  constructor(
    private accounts: AccountsService,
    private bionicReading: BionicReadingService,
    private changeDetectorRef: ChangeDetectorRef,
    private comments: CommentsService,
    private configService: ConfigService,
    private admin: AdminService,
    private events: EventsService,
    private external: ExternalService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private popoverCtrl: PopoverController,
    private reactions: ReactionsService,
    public userService: UserService,
    private zone: NgZone,
    private _sanitizer: DomSanitizer,
  ) {
    this.config = this.configService.getConfig();

    if(!!this.config.useReactions) {
      this.view.emojis = this.reactions.getEmojis();
    }
  }

  accept() {
    this.admin.acceptPost(this.post as post)
    .then((response: any) => {
      this.events.publish('toast', {
        message: 'Beitrag akzeptiert',
        color: 'primary',
      });
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  calcOptions() {
    let isOwnPost: boolean = (this.post && !!this.post.user && this.userService.isLoggedIn() && (this.post.user === this.userService.getUid()));

    this.options = this.options || {};
    this.options.admin = (this.options.admin || isOwnPost);
  }

  calcVideoMode() {
    if(!!this.post && !!this.post.video) {
      this.view.video_formatted = this._sanitizer.bypassSecurityTrustHtml(this.post.video);
    }
  }

  async cardOptions(col: col|post, event: any = null) {
    event.preventDefault();
    let optionsPopover = await this.popoverCtrl.create({
      animated: true,
      component: CardOptionsPage,
      componentProps: {
        col: col,
        event: event,
      },
      cssClass: 'cardOptionsPopover',
      event: event,
      translucent: true
    });
    await optionsPopover.present();
    return false;
  }

  commentPost() {
    this.comments.commentPost(this.post as post)
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  detectChanges() {
    setTimeout(() => {
      this.zone.run(() => {
        this.events.publish('post:card:updated', this.post);
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  async editPost() {

    if(!this.userService.isType('Admin')) {
      this.navCtrl.navigateForward('/create-contents-banner');
      return false;
    }

    this.admin.editPost(this.post as post);
  }

  expandReactions() {
    this.view.expandReactionsView = !this.view.expandReactionsView;
    setTimeout(() => {
      this.view.reactionsSegment = null;
    });
    this.detectChanges();
  }

  async loadAvatar() {
    if(this.post && !!this.post.type && (this.post.type === 'feed_post') && !!this.post.user && !this.post.avatar) {
      try {
        let user: user = await this.userService.getByUid(this.post.user);
        
        if(user && user.photo) {
          this.post.avatar = user.photo;
          this.detectChanges();
        }

      } catch(e) {
        console.warn('> load avatar error', e);
      }
    }
  }

  async loadBionicReadingState() {
    try {
      this.view.enableBionicReading = await this.bionicReading.isEnabled();

      if(!!this.view.enableBionicReading && !!this.post.excerpt) {
        let convertedExcerpt: any = await this.bionicReading.convert(this.post.excerpt);
        let convertedContent: any = await this.bionicReading.convert(this.post.post_content);
  
        if(!!convertedExcerpt.output) {
          this.post.excerpt = convertedExcerpt.output;
        }
  
        if(!!convertedContent.output) {
          this.post.post_content = convertedContent.output;
        }
      }
    } catch(e) {
      console.warn('> bionic reading convertion failed', e);
    }
  }

  ngOnInit() {
    this.zone.run(() => {

      if(!!this.post) {

        if(!!this.size) {
          this.post.size = this.size;
        }
  
        this.post.excerpt = ((this.post as any).post_excerpt || this.post.excerpt);
      }

      this.calcOptions();
      this.calcVideoMode();

      this.loadAvatar();
      this.loadBionicReadingState();

      this.detectChanges();
    });
  }

  onColClick(_col: col|post, event: any = null) {
    let col: col|post = JSON.parse(JSON.stringify(_col));

    if(event && event.target && event.target.classList.contains('optionsButton')) {
      event.preventDefault();
      return false;
    }

    if(!!col.type && col.type === 'post_video') {
      col.type = 'post';
    }
    
    switch (col.type) {
      case "feed_post":
        return this.onPostClick(col as post);
      case "person":
        return this.onPersonClick(col);
      case "post":
        return this.onPostClick(col as post);
      case "product":
        return this.onProductClick(col);
      case "review":
        return this.onPostClick(col as post);
      case "simple":
        return this.onProductClick(col);
      case 'variable':
        return this.onProductClick(col);
      default:
        console.warn('> broken col', col);
        this.events.publish("error", "Unbekannter Typ " + col.type);
        break;
    }
  }
  
  async onPersonClick(user: user) {
    this.events.publish('view:profile', user);
  }

  async onPostClick(post: post) {
    this.events.publish('view:post', post);
  }

  async onProductClick(product: any) {
    this.events.publish('view:product', product);
  }

  onReactionsSegmentChanged() {
    if(this.view && !!this.view.reactionsSegment && this.view.reactionsSegment === 'expand') {
      return this.expandReactions();
    }
  }

  openProfile(col: col|post) {
    //console.log('col', col);
  }

  performReact(emoji: emoji) {

    this.events.publish('reactions:stage:render', {
      emoji: emoji.emoji,
    });

    setTimeout(() => {
      this.view.reactionsSegment = null;
      this.detectChanges();
    }, 2 * 1000);

    // submit reaction to server
    this.reactions.reactOnPost({
      emoji: emoji.name,
      item: this.post.uid,
      type: 'post',
    })
    .then((response: any) => {
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  react(emoji: emoji) {
    if(!this.userService.isLoggedIn()) {
      this.accounts.switch()
      .then(() => {
        if(this.userService.isLoggedIn()) {
          this.performReact(emoji);
        }
      });
      return false;
    } else {
      this.performReact(emoji);
    }
  }

  reject() {
    this.external.reject(this.post as post)
    .then((response: any) => {
      this.events.publish('toast', {
        message: 'Beitrag abgelehnt',
        color: 'primary',
      });
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  thumbnailLoadingFailed(col: col|post) {
    col.hidden = true;
    this.detectChanges();
  }

  async verifiedInfo() {
    let verifiedModal = await this.modalCtrl.create({
      component: VerifiedInfoPage,
      componentProps: {
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: 'verifiedInfoModal'
    });
    verifiedModal.present();
  }

}
