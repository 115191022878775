import { Component, OnInit } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';

import { AccountsService } from 'src/app/services/core/accounts.service';
import { BasketService } from 'src/app/services/ecommerce/basket.service';
import { BrowserService } from 'src/app/services/utils/browser.service';
import { CalendarService } from 'src/app/services/extensions/calendar.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { UserService } from 'src/app/services/core/user.service';

import * as moment from 'moment';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.page.html',
  styleUrls: ['./event-detail.page.scss'],
})
export class EventDetailPage implements OnInit {

  basketStorage: any = {};

  config: pipelineAppConfig;

  event: calendarEvent = {};

  googleMapConfig: googleMapConfig = {
    id: 'eventMap',
    classes: 'eventMap map',
    data: [],
    size: {
      height: (window.innerWidth * 0.5),
      width: window.innerWidth,
    },
    sources: ['calendar'],
  };

  sliderOptions: any = {
    loop: true,
    zoom: false,
    autoHeight: true,
    slidesPerView: (window.innerWidth >= 1024) ? 3 : 1,
  };

  user: user;
  
  viewData: any = {
    external: false,
    placeholders: [{},{},{},{},{},{},{},{}],
    segment: 'details',
    shop: {
      products: {},
    },
    user_response_segment: 1,
    userResponsesByValue: [],
  };

  constructor(
    private accounts: AccountsService,
    private basket: BasketService,
    private browser: BrowserService,
    private calendar: CalendarService,
    private configService: ConfigService,
    private events: EventsService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private navParams: NavParams,
    private userService: UserService,
  ) {
    this.config = this.configService.getConfig();
    
    this.event = this.navParams.get('event') || this.event;
  }

  _basket() {
    this.dismiss();
    this.events.publish('view:basket');
  }

  add() {
    this.addToBasket(false);
    
    this.basket.getBasket()
    .then((basket: basketStorage) => {
      this.basketStorage = basket;
      this.detectChanges();
    });
  }

  addToBasket(redirect: boolean = true) {
    this.event.amount = this.event.amount || 0;
    this.event.amount++;

    this.detectChanges();
    
    this.basket.add(this.event)
    .then(() => {
      if(redirect) {
        this.dismiss();
        this.events.publish('view:basket');
      } else {
        this.event.amount = this.event.amount || 1;
        this.detectChanges();
      }
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });

  }

  detectChanges() {

  }

  dismiss() {
    this.modalService.dismiss();
  }

  initEvents() {
    this.events.subscribe('appcms:user:updated', () => {
      this.viewData.isLoggedIn = this.userService.isLoggedIn();
    });
  }
  
  ionViewWillEnter() {
    this.viewData.isLoggedIn = this.userService.isLoggedIn();
  }

  loadBasket() {
    this.basket.getBasket()
    .then((basket: basketStorage) => {
      this.basketStorage = basket;
      this.detectChanges();
    });
  }

  loadEvent() {
    if(!!this.event) {
      let now: number = (moment().unix() * 1000);

      this.event.available = !!(this.event.start && this.event.start > now);

      if(!!this.event.user_responses) {

        this.viewData.userResponsesByValue = {
          '-1': 0,
          '0': 0,
          '1': 0,
        };

        this.event.user_responses.forEach((user_response: calendarEventUserResponse) => {
          this.viewData.userResponsesByValue[user_response.value] = this.viewData.userResponsesByValue[user_response.value] || 0;
          this.viewData.userResponsesByValue[user_response.value]++;
          
          user_response.user = this.userService.getFullUser(user_response.user);
          user_response.user.classifications = user_response.user.classifications || {};
          user_response.user.classifications.displayName = this.userService.getDisplayName(user_response.user);
        });

      }

    }
  }

  minus() {
    this.basket.removeFromBasket(this.event)
    .then(async () => {
      this.basketStorage = await this.basket.getBasket();
      this.event.amount = this.event.amount > 0 ? this.event.amount-- : 0;
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  ngOnInit() {
    this.initEvents();

    this.viewData.isLoggedIn = this.userService.isLoggedIn();

    this.loadEvent();
    this.loadBasket();
  }

  showLoginModal() {
    this.accounts.switch();
  }

  respond(value: number) {
    if(!this.userService.isLoggedIn()) {
      this.navCtrl.navigateRoot('/login');
    } else {
      this.calendar.sendUserResponseToCalendarEvent(this.event.uid, value)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }
  }

  toggleCollection() {
  }
  
  viewEvent() {
    if(!!this.event.url) {
      this.browser.create(this.event.url);
    }
  }

  viewProfile(profile: user) {
    this.events.publish('view:profile', profile);
  }

}
