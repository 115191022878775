import { Injectable } from '@angular/core';
import { CacheService } from 'src/app/services/core/cache.service';
import { SettingsService } from 'src/app/services/core/settings.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class ScoreService {

  cacheKey: string = 'score_uids';

  _scoreData: scoreData;

  key: string = 'scoreData';

  steps: scoreStep[] = [
    {
      icon: 'gift-outline',
      nextGoal: 'score_receive_points',
      points: 10,
      requiredInvitations: 1,
      step: 1,
    },
    {
      icon: 'gift-outline',
      nextGoal: 'score_receive_points',
      points: 25,
      requiredInvitations: 3,
      step: 2,
    },
    {
      icon: 'gift-outline',
      nextGoal: 'score_receive_points',
      points: 100,
      requiredInvitations: 10,
      step: 3,
    },
    {
      icon: 'gift-outline',
      nextGoal: 'score_receive_points',
      points: 500,
      requiredInvitations: 25,
      step: 4,
    },
  ];

  constructor(
    private cache: CacheService,
    private settings: SettingsService,
    private translations: TranslationService,
    public userService: UserService,
  ) {

  }

  async alreadyScored(post: post, bl: boolean|null = null) {
    let scoredUids = await this.getScoredUids();

    if(!!bl) {
      scoredUids.push(post.uid);
      return this.setScoredUids(scoredUids);
    }

    return !!(scoredUids.indexOf(post.uid) !== -1);
  }

  decrease() {

  }

  getData() {
    return new Promise(async (resolve, reject) => {
      let scoreSettings: scoreData = (await this.settings.getSetting('score')) || {};
      let step = scoreSettings.step || 1;

      if(typeof step === 'number' || typeof step === 'string') {
        scoreSettings.step = await this.getStep(step);
      }

      this._scoreData = scoreSettings;
      this._scoreData.steps = (await this.getSteps(this._scoreData) as any);

      resolve(this._scoreData);
    });
  }

  async getScoredUids() {
    let fromCache: cacheItem = await this.cache.get(this.cacheKey, (60 * 60 * 24 * 365));
    return (fromCache && fromCache.data ? fromCache.data : []);
  }

  async getStep(iStep: number) {
    return new Promise(async (resolve, reject) => {
      let steps = await this.getSteps();
      resolve(
        (steps && steps[iStep-1] ? steps[iStep-1] : {})
      );
    });
  }

  async getSteps(scoreData: scoreData = null) {

    return new Promise((resolve, reject) => {
      let steps: scoreStep[] = JSON.parse(JSON.stringify(this.steps));
  
      if(steps && steps.length) {
        steps.forEach((step: scoreStep, index: number) => {
          let baseText = 'invite_friends_score_text_' + (step.requiredInvitations === 1 ? 'singular' : 'plural');
          let translationKey = ((step.requiredInvitations === 1) ? (step.requiredInvitations + '_m') : step.requiredInvitations) as string;

          this.translations.get([
            step.nextGoal,
            baseText,
            translationKey,
          ])
          .subscribe((translations: any) => {
            step.text = translations[baseText]
              .replace(/\$ /g, '$')
              .replace('$1', translations[translationKey] || step.requiredInvitations)
              .replace('$2', (translations[step.nextGoal] || step.nextGoal).replace('$3', ((step.points || 0) as any)));
  
            if(index === (steps.length-1)) {
              resolve(steps);
            }
          });
        })
      }
    });
  }

  increase(iPoints: number) {
    return new Promise((resolve, reject) => {
      let user = this.userService.getUser() || {};

      if(!user || !user.uid) {
        return false;
      }

      user.score = (user.score || 0);
      user.score += iPoints;
      //console.log('user score is now', user.score);

      this.userService.setScore(user.score).then(resolve).catch(reject);
    });
  }

  scoreReadArticle(post: post, iPoints: number = 5) {
    return new Promise(async (resolve, reject) => {
      let blAlreadyScored = await this.alreadyScored(post);

      if(blAlreadyScored) {
        reject('already_scored');
      } else {
        this.alreadyScored(post, true)
        .then(() => {
          this.increase(iPoints).then(resolve).catch(reject);
        })
        .catch(reject);
      }
    });
  }

  setScoredUids(scoredUids: number[]) {
    return this.cache.set(this.cacheKey, scoredUids);
  }

}
