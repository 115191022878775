import { Injectable } from "@angular/core";
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: "root",
})
export class LogfileService {

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    public userService: UserService
  ) {
  }

  add(logEntry: logEntry) {
    let user = this.userService.getUser() || {};
    logEntry.user = logEntry.user || user.uid;

    return this.AppCMS.loadPluginData(
      "logfile",
      {
        entry: logEntry,
      },
      ["add"]
    );
  }

  async countProfileView(profileId: number) {
    let counts = (await this.getProfileViewCounts()) || {};
    counts[profileId] = counts[profileId] || 0;
    counts[profileId]++;

    return this.setProfileViewCounts(counts);
  }

  delete(filter: any = {}) {
    return this.AppCMS.loadPluginData("logfile", {
      filter: filter,
    }, ['delete']);
  }

  get(filter: any = {}) {
    return this.AppCMS.loadPluginData("logfile", {
      filter: filter,
    });
  }

  async getProfileViewCounts() {
    let fromCache: cacheItem = await this.cache.get('profile_view_counts', -1);
    
    if(fromCache && fromCache.data) {
      return fromCache.data;
    }

    return {};
  }

  loggedIn() {
    /*
    setTimeout(() => {
      let user = this.userService.getUser();
      return this.add({
        class: 'user_logged_in',
        group: 'user_logged_in_' + user.uid,
        description: (user.nickname || user.firstname) + ' hat sich angemeldet',
      })
      .catch((error: any) => {
        console.warn('log error:', error);
      });
    }, 2250);
    */
  }

  loggedOut() {
    /*
    setTimeout(() => {
      let user = this.userService.getUser();
  
      return this.add({
        class: "user_logged_out",
        group: "user_logged_out_" + user.uid,
        description: (user.nickname || user.firstname) + " hat sich angemeldet",
      }).catch((error: any) => {
        console.warn("log error:", error);
      });
    }, 250);
    */
  }

  setProfileViewCounts(counts: any) {
    return this.cache.set('profile_view_counts', counts);
  }

}
