import { Injectable } from "@angular/core";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { SettingsService } from "src/app/services/core/settings.service";
import { ToolsService } from 'src/app/services/utils/tools.service';
import { EventsService } from 'src/app/services/core/events.service';
import { PostsService } from 'src/app/services/posts/posts.service';

@Injectable({
  providedIn: "root",
})
export class BlogService {

  all: any[];

  categories: any[];

  public: any[];

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private events: EventsService,
    private settings: SettingsService,
    private tools: ToolsService,
    private posts: PostsService,
  ) {
  }

  getActive() {
    return this.getSelfHosted({
      active: true,
    });
  }

  getAll(options: any = {}) {
    options.beta = true;
    options.blForceRefresh = options.blForceRefresh || false;

    return new Promise(async (resolve, reject) => {
      let key = "blog_all",
        fromCache: cacheItem = await this.cache.get(key, 10 * 60);
      if (!this.all && fromCache && fromCache.data) {
        this.all = fromCache.data;
      }
      if (!options.blForceRefresh && this.all) {
        resolve(this.all);
      } else {
        this.AppCMS.loadPluginData("blog", options)
          .then((posts: any) => {
            if (posts) {

              posts.forEach((post: any, index: number) => {
                posts[index] = this.posts.getFullPost(post);
              });

              this.all = posts;
              this.cache.set(key, this.all);
              resolve(this.all);

            } else {
              reject();
            }
          })
          .catch(reject);
      }
    });
  }

  getByCategory(categoryId: number, options: any = {}) {
    options.beta = true;
    return new Promise(async (resolve, reject) => {
      let key = "blog_byCategory_" + categoryId,
        fromCache: cacheItem = await this.cache.get(key, 10 * 60);

      if (fromCache && fromCache.data) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData("blog", options, ["category", categoryId])
          .then((posts: any) => {
            if (posts) {
              posts.forEach((post: any, index: number) => {
                posts[index] = this.posts.getFullPost(post);
              });
              this.cache.set(key, posts);
              resolve(posts);
            } else {
              reject();
            }
          })
          .catch(reject);
      }
    });
  }

  getByUid(uid: number, options: any = {}) {
    options.beta = true;
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData("blog", options, [uid])
        .then((post: post) => {
          resolve(this.posts.getFullPost(post));
        })
        .catch(reject);
    });
  }

  getCategories(blForceRefresh: boolean = false, blFilterResults: boolean = true) {
    return new Promise(async (resolve, reject) => {
      let key = "blog_categories";
      let categoriesFromCache: cacheItem = await this.cache.get(key, 10 * 60);

      if (!blForceRefresh && (categoriesFromCache && categoriesFromCache.data)) {
        this.events.publish("categories:loaded", categoriesFromCache.data);
        resolve(categoriesFromCache.data);
      } else {
        this.AppCMS.loadPluginData("blog", {}, ["categories"])
          .then((categories: any) => {
            this.settings
              .getSetting("categories", blForceRefresh)
              .then((categoriesSettings: any) => {

                if (categories && categories.length) {

                  categories.forEach((category: blogCategory, index: number) => {
                    let id = (category.id || category.uid) || (category.cat_ID || category.term_id);
                    if (category) {
                      categories[index] = this.getFullCategory(category);
                    }
                    this.cache.set(`category_${id}`, categories[index]);
                  });

                  let filteredCategories: blogCategory[];

                  if (blFilterResults) {
                    filteredCategories = categories.filter((_category: blogCategory) => {
                      let blReturn: boolean = false;
                      if (
                        categoriesSettings &&
                        categoriesSettings[_category.cat_ID]
                      ) {
                        blReturn = categoriesSettings[_category.cat_ID].rank > 1;
                      }
                      blReturn = _category.name !== 'Allgemein';
                      return blReturn;
                    });
                  }

                  categories = filteredCategories && filteredCategories.length ? filteredCategories : categories;
                  this.cache.set(key, categories);
                }
                this.events.publish("categories:loaded", categories);
                resolve(categories);
              })
              .catch(reject);
          })
          .catch(reject);
      }
    });
  }

  getCategory(categoryId: number, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let cacheKey: string = `category_${categoryId}`,
        fromCache: cacheItem = await this.cache.get(cacheKey, (60 * 60 * 24));

      if (!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(this.getFullCategory(fromCache.data));
      } else {
        this.getCategories()
          .then((categories: blogCategory[]) => {
            let categorySelect = categories.filter((_category: blogCategory) => {
              return _category.term_id == categoryId;
            });
            let category = categorySelect && categorySelect[0] ? this.getFullCategory(categorySelect[0]) : null;
            this.cache.set(cacheKey, category);
            resolve(category);
          })
          .catch(reject);
      }
    });
  }

  getCommentsByPostId(postId: number) {
    return this.AppCMS.loadPluginData("blog", {}, ["comments", postId]);
  }

  getFullCategory(category: blogCategory) {
    category.name = category.name.replace("&amp;", "&");
    category.icon = this.tools.getCategoryIcon(category.name);

    return category;
  }

  getMainCategories(blForceRefresh: boolean = false, blFilterResults: boolean = true) {
    return new Promise((resolve, reject) => {
      this.getCategories(blForceRefresh, blFilterResults)
        .then((categories: blogCategory[]) => {
          let mainCategories = categories.filter((category: blogCategory) => {
            if (category.hasOwnProperty('categories') && !category.hasOwnProperty('parent')) {
              return category.categories && category.categories.length;
            }
            return !category.parent;
          });
          this.events.publish("categories:main:loaded", mainCategories);
          resolve(mainCategories);
        })
        .catch(reject);
    });
  }

  getPublic(options: any = {}) {
    options.beta = true;
    options.blForceRefresh = options.blForceRefresh || false;
    return new Promise(async (resolve, reject) => {
      let key = "blog_public",
        fromCache: cacheItem = await this.cache.get(key, 10 * 60);
      if (!options.blForceRefresh && this.public) {
        resolve(this.public);
      } else {
        this.getAll()
          .then((all: any) => {
            if (!this.public && fromCache && fromCache.data) {
              this.public = fromCache.data;
              resolve(this.public);
            } else {
              this.getSelfHosted({
                active: true,
              })
                .then((posts: any) => {
                  if (posts) {
                    this.public = all.concat(posts);
                    this.cache.set(key, this.public);
                    resolve(this.public);
                  } else {
                    reject();
                  }
                })
                .catch(reject);
            }
          })
          .catch(reject);
      }
    });
  }

  getSelfHosted(
    filter: any = {},
    blForceRefresh: boolean = false,
    config: any = null
  ) {
    return new Promise(async (resolve, reject) => {
      config = config || {};

      if (filter.host) {
        filter.host = filter.host.replace('https://', '').replace('http://', '');
        config.like = true;
      }


      config.includeRating = !!(typeof config.includeRating === 'boolean' ? config.includeRating : true);
      config.includeRelatedArticles = !!(typeof config.includeRelatedArticles === 'boolean' ? config.includeRelatedArticles : false);
      config.includeReactions = !!(typeof config.includeReactions === 'boolean' ? config.includeReactions : true);

      let route = ["posts"],
        key =
          "self_hosted_" +
          JSON.stringify(filter) +
          "_" +
          JSON.stringify(config),
        fromCache: cacheItem = await this.cache.get(key);

      if (!blForceRefresh && fromCache && fromCache.data) {
        resolve(fromCache.data);
      } else {

        if (filter.hasOwnProperty("region") && filter.region && filter.region.length) {
          route = ["posts", "region", filter.region];
          delete filter.region;
        }

        this.AppCMS.loadPluginData(
          "pipeline",
          Object.assign(config, { filter: filter }),
          route,
          config
        )
          .then((response: any) => {

            if (response && response.length) {
              response = this.posts.getFullPosts(response);
            } else
              if (response.posts && response.posts.length) {
                response.posts = this.posts.getFullPosts(response.posts);
              }

            this.cache.set(key, response)
              .then(() => {
                resolve(response);
              })
              .catch(reject);
          })
          .catch(reject);
      }
    });
  }

  parseCategories(categories: blogCategory[]) {
    if (categories && categories.length) {
      categories.forEach((category: blogCategory) => {
        if (category) {
          category = this.getFullCategory(category);
        }
      });
    }
    return categories;
  }

  removePublic() {
    this.cache.remove("blog_public");
    this.public = null;
  }

  submit(post: post) {
    return this.AppCMS.loadPluginData(
      "blog",
      {
        post: post,
      },
      ["submit"]
    );
  }

  submitComment(comment: any, postId: number) {
    return this.AppCMS.loadPluginData(
      "blog",
      {
        comment: comment,
        entryId: postId,
      },
      ["comments", "add"]
    );
  }

}