<ion-header class="ion-no-border">

  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title [innerHTML]="folder.title"></ion-title>


    <ion-buttons slot="end">
      <ion-button icon-only (click)="update()" *ngIf="!!folder.uid"
        [disabled]="!folder.title">
        <ion-icon name="checkmark-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>


  <ion-toolbar class="container">
    <ion-segment [(ngModel)]="view.segment">

      <ion-segment-button value="general">
        <ion-icon name="information-outline"></ion-icon>
        <ion-label [innerHTML]="'general'|translate"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="access" [disabled]="!folder.uid || !folder.title">
        <ion-icon name="people-outline"></ion-icon>
        <ion-label [innerHTML]="'access'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>
  </ion-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <ion-card [hidden]="view.segment != 'general'">
      
      <ion-list>

        <ion-item lines="none">

          <ion-icon name="eye-outline" slot="start"></ion-icon>

          <ion-label position="floating" [innerHTML]="'folder_name'|translate"></ion-label>
  
          <ion-input [(ngModel)]="folder.title"
            [placeholder]="'folder_name_placeholder'|translate"></ion-input>
  
        </ion-item>

        <ion-item lines="none">
          <ion-checkbox slot="start" [(ngModel)]="folder.active"></ion-checkbox>
          <ion-label [innerHTML]="'state_active'|translate"></ion-label>
        </ion-item>

      </ion-list>

    </ion-card>

    <ion-card [hidden]="view.segment != 'access'">

      <ion-card-header>
        <ion-card-title [innerHTML]="'projects'|translate"></ion-card-title>
      </ion-card-header>

      <ion-card-content>

        <ion-button color="primary" (click)="addProject()">
          <ion-icon name="folder-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'add'|translate"></ion-label>
        </ion-button>

        <ion-button color="primary" (click)="createProject()">
          <ion-icon name="add-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'create_project'|translate"></ion-label>
        </ion-button>
        
      </ion-card-content>

    </ion-card>

  </div>

</ion-content>
