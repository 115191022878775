import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from 'src/app/services/core/events.service';
import { NetworkService } from 'src/app/services/core/network.service';

import { LoginService } from 'src/app/services/core/login.service';
import { BackgroundService } from 'src/app/services/core/background.service';
import { ConfigService } from 'src/app/services/core/config.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private alertCtrl: AlertController,
    public background: BackgroundService,
    private config: ConfigService,
    private events: EventsService,
    public loginService: LoginService,
    private network: NetworkService,
    private translate: TranslateService,
  ) {
  }

  async handleActionRequiresUserLoginError(error: any) {
    let config: pipelineAppConfig = this.config.getConfig();

    if(!config.allowUserLogin) {
      this.events.publish('error', 'error_login_not_allowed');
      return false;
    }

    return this.loginService.show(true);
  }

  init() {
    this.initEvents();
  }

  initEvents() {
    this.initOnErrorEvent();
  }

  initOnErrorEvent() {
    this.events.subscribe("error", async (error: any) => {
      let blConnected: boolean = await this.network.isConnected();

      if(!blConnected) {
        this.network.showOfflineMessage();
        return false;
      } 

      if(this.background.isBackground()) {
        console.warn('[PREVENTED ALERT] device was in background mode');
        return false;
      }

      if(error && typeof error === 'object') {
        error = (error.message || (error.error || error.errorMessage));
      }
     
      if( (error === 'Der Benutzer konnte nicht gefunden werden') || 
          ((error || '').indexOf('401 Unauthorized') !== -1) ||
          ((error || '').indexOf('missing_user') !== -1)
        ) {
        return this.handleActionRequiresUserLoginError(error);
      }

      let alertParams = {
        animated: true,
        header: "Oh oh!",
        subHeader: 'Das hat nicht geklappt',
        message: error,
        buttons: [
          {
            text: "Okay",
          },
        ],
      };
      
      this.translate.get(['error', 'error_subHeader', error])
      .subscribe(async (translations: any) => {
        alertParams.header = translations['error'];
        alertParams.subHeader = translations['error_subHeader'];
        alertParams.message = translations[error as string] || error;

        const alert = await this.alertCtrl.create(alertParams);
        alert.present();

      }, async (error: any) => {
        console.warn('main error handler -> translations -> error', error);

        const alert = await this.alertCtrl.create(alertParams);
        alert.present();
      });

    });
  }

}
