import { Injectable } from "@angular/core";

import { AlertController } from "@ionic/angular";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from 'src/app/services/core/events.service';
import { FriendsService } from 'src/app/services/social/friends.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { NetworkService } from 'src/app/services/core/network.service';
import { ScoreService } from "src/app/services/pipeline/score.service";
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { MessagesService } from 'src/app/services/social/messages.service';

import { CollectionPickerPage } from "src/app/pages/collections/collection-picker/collection-picker.page";
import { ConfigureCollectionPage } from "src/app/pages/collections/configure-collection/configure-collection.page";

@Injectable({
  providedIn: "root",
})
export class CollectionsService {

  collections: any = [];

  constructor(
    private alertCtrl: AlertController,
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private events: EventsService,
    public friends: FriendsService,
    private messages: MessagesService,
    private modalCtrl: ModalService,
    private network: NetworkService,
    private posts: PostsService,
    private score: ScoreService,
    private translations: TranslationService,
    public userService: UserService,
  ) {
  }

  /**
   * @param itemId
   * @param type 
   * @param collectionData 
   * @returns 
   */
  add(itemId: number, type: string, collectionData: any = {}) {
    return new Promise((resolve, reject) => {
      let parentId = this.userService.getUid();

      let collectionItem: collectionItem = Object.assign(collectionData, {
        user: parentId,
        item: itemId,
        type: type,
      });

      this.AppCMS.loadPluginData(
        "pipeline",
        {
          item: collectionItem,
        },
        ["collections", "add"]
      )
        .then((createResponse: any) => {

          this.translations
            .get(["added_to_profile"])
            .subscribe((translations: any) => {
              this.events.publish("toast", {
                message:
                  translations.added_to_profile || "Zum Profil hinzugefügt",
                color: "primary",
              });
            });

          this.cache.remove("collections_byUser_" + parentId);

          try {
            this.score.increase(25)
            .catch((error) => {
              console.warn('score error', error);
            });
          } catch(e) {
            console.warn('score error', e);
          }

          this.messages
            .sendPushToFriends({
              data: {
                action: "viewPost",
                item: createResponse.item,
              },
            })
            .catch((error: any) => {
              console.warn("push error", error);
              resolve(createResponse);
            });
          resolve(createResponse);
        })
        .catch(reject);
    });
  }

  /**
   * @param collection 
   * @returns 
   */
  configureCollection(collection: collectionsResponseItem) {
    return new Promise(async (resolve, reject) => {

      let configureCollectionModal = await this.modalCtrl.create({
        component: ConfigureCollectionPage,
        componentProps: {
          collection: collection,
          collectionsService: this,
        },
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: "configureCollectionModal",
      });

      configureCollectionModal.onWillDismiss().then((response: any) => {
        response.data = response.data || {};
        let collection: string = response.data.collection;

        resolve(response.data);
      });

      configureCollectionModal.present();
    });
  }

  /**
   * @param collection 
   * @returns 
   */
  create(collection: collectionItem) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        collection: collection,
      },
      ["collections", "create"]
    );
  }

  /**
   * @param item 
   * @param type 
   * @param collectionData 
   * @returns 
   */
   delete(collectionId: number) {
    return new Promise((resolve, reject) => {
      let parentId: number = this.userService.getUid();

      this.AppCMS.loadPluginData(
        "pipeline",
        {
          item: collectionId,
        },
        ["collections", "delete"]
      )
        .then((deleteResponse: any) => {
          this.translations
            .get(["removed_from_collections"])
            .subscribe((translations: any) => {

              this.events.publish('profile:refresh');

              this.events.publish("toast", {
                message:
                  translations.removed_from_collections || "removed_from_collections",
                color: "primary",
              });
            });

          this.cache.remove("collections_byUser_" + parentId);
          resolve(deleteResponse);
        })
        .catch(reject);
    });
  }

  /**
   * @param type 
   * @returns 
   */
  get(type: string) {
    return new Promise((resolve, reject) => {
      this.getByProfileUid()
        .then((collections: any) => {
          resolve(collections[type] || []);
        })
        .catch(reject);
    });
  }

  /**
   * @param profileId 
   * @param blForceRefresh 
   * @param options 
   * @returns 
   */
  getByProfileUid(
    profileId: number = null,
    blForceRefresh: boolean = false,
    options: any = {}
  ) {
    return new Promise(async (resolve, reject) => {
      let user = this.userService.getUser();
      if (!user || !user.uid) {
        reject("error_missing_user");
      } else {
        let key = "collections_byUser_" + profileId + "_" + options,
          fromCache: cacheItem = await this.cache.get(key, 30 * 60);

        if (fromCache && fromCache.data && !blForceRefresh) {
          resolve(this.parseCollections(fromCache.data));
        } else {
          let filter: any = Object.assign(options.filter || {}, {
            user: profileId || user.uid,
          });

          let params: any = Object.assign(options, {
            filter: filter,
          });

          this.AppCMS.loadPluginData("pipeline", params, ["collections"])
            .then((collectionsResponse: collectionsResponse) => {
              this.friends
                .asCols(profileId, null, blForceRefresh)
                .then((friendsRows: user[]) => {
                  let collections = Object.assign(collectionsResponse || {}, {
                    people: friendsRows,
                  });

                  /*
                if(collectionItems && collectionItems.length) {
                  collectionItems = collectionItems.filter((collectionItem: collectionItem) => {
                    return collectionItem && collectionItem.type !== 'revision';
                  });
                  collectionItems.forEach((collectionItem: collectionItem) => {
                    collectionItem.indent = collectionItem.indent || collectionItem.type;
                    collections[collectionItem.indent] = collections[collectionItem.indent] || [];
                    collections[collectionItem.indent].push(collectionItem);
                  });
                }
                */

                  this.collections = this.parseCollections(collections);

                  this.cache.set(key, this.collections);
                  resolve(this.collections);
                })
                .catch(reject);
            })
            .catch(reject);
        }
      }
    });
  }

  /**
   * 
   * @todo Improve this method to call the collection directly
   * 
   * @param collectionId
   * @param blForceRefresh 
   * @returns 
   */
  getByUid(
    collectionId: number = null,
    blForceRefresh: boolean = false,
  ) {
    return new Promise(async (resolve, reject) => {
      let user = this.userService.getUser();
      if (!user || !user.uid) {
        reject("error_missing_user");
      } else {
        let key = "collections_byUid_" + collectionId,
          fromCache: cacheItem = await this.cache.get(key, 30 * 60);

        if (fromCache && fromCache.data && !blForceRefresh) {
          resolve(this.parseCollections(fromCache.data));
        } else {

          let filter: any = {
            uid: collectionId,
          };

          let params: any = {
            filter: filter,
          };

          this.AppCMS.loadPluginData("pipeline", params, ["collections"])
            .then((collectionsResponse: collectionsResponse) => {
              if(collectionsResponse.custom) {
                let collectionLookup: collectionsResponseItem[] = (collectionsResponse.custom as any).filter((collectionsResponseItem: collectionsResponseItem) => {
                  return collectionsResponseItem.uid === collectionId;
                });
  
                let collection = this.parseCollection(collectionLookup && collectionLookup[0] ? collectionLookup[0] : []);
  
                this.cache.set(key, collection)
                .then(() => {
                  resolve(collection);
                })
                .catch(reject);
              } else {
                reject(collectionsResponse);
              }
            })
            .catch(reject);
        }
      }
    });
  }

  /**
   * @param profileId 
   * @param blForceRefresh 
   * @param options 
   * @returns 
   */
  getCustomCollectionNames(
    profileId: number = null,
    blForceRefresh: boolean = false,
    options: any = {}
  ) {
    return new Promise((resolve, reject) => {
      options.filter = options.filter || {};
      options.filter.type = "custom";

      if (!options.hasOwnProperty("people")) {
        options.people = false;
      }

      this.getByProfileUid(profileId, blForceRefresh, options)
        .then((collections: any) => {
          resolve(collections.custom || []);
        })
        .catch(reject);
    });
  }

  /**
   * @param profileId 
   * @param blForceRefresh 
   * @param options 
   * @returns 
   */
  async getNamesByProfileUid(
    profileId: number = null,
    blForceRefresh: boolean = false,
    options: any = {}
  ) {
    let collections = await this.getByProfileUid(
      profileId,
      blForceRefresh,
      options
    );
    return Object.keys(collections);
  }

  /**
   * Check if an item is inside a user's collection
   * (check if it's bookmarked)
   * 
   * @param item
   * @returns bool
   */
  inCollection(item: any) {
    return new Promise(async (resolve, reject) => {
      let blConnected: boolean = await this.network.isConnected();

      if (!blConnected) {
        this.network.showOfflineMessage();
        resolve({});
      } else {
        this.AppCMS.loadPluginData(
          "pipeline",
          {
            uid: item.uid,
            parent: this.userService.getUid(),
          },
          ["collections", "inCollection"]
        )
          .then((response: any) => {
            resolve(!!response.result);
          })
          .catch(reject);
      }
    });
  }

  /**
   * Parse a single collection
   * 
   * @param collectionItem
   * @returns 
   */
  parseCollection(collectionItem: any) {
    collectionItem.uid =
      collectionItem.uid || collectionItem.ID || collectionItem.id;

    collectionItem.icon = collectionItem.icon || 'folder-outline',
    collectionItem.type = collectionItem.type || collectionItem.post_type;
    collectionItem.color = collectionItem.color || "light";

    let wpImage = collectionItem.images
      ? collectionItem.images[0].src
      : (collectionItem.thumbnail || collectionItem.image);

    if (wpImage && wpImage.length) {
      collectionItem.thumbnail = wpImage;
    } else
    if(collectionItem.items && collectionItem.items[0]) {
      collectionItem.thumbnail = collectionItem.items[0].thumbnail || collectionItem.items[0].image;
    } else
    if(collectionItem.image && collectionItem.image.length) {
      collectionItem.thumbnail = collectionItem.image;
    }

    if(collectionItem.items) {
      collectionItem.items = this.posts.getFullPosts(collectionItem.items);
    }

    delete collectionItem.added;
    delete collectionItem.color;
    delete collectionItem.comment_status;
    delete collectionItem.comment_count;
    delete collectionItem.filter;
    delete collectionItem.ID;
    delete collectionItem.ping_status;
    delete collectionItem.to_ping;
    delete collectionItem.pinged;
    delete collectionItem.post_author;
    delete collectionItem.post_type;
    delete collectionItem.menu_order;
    delete collectionItem.post_content_filtered;
    delete collectionItem.post_status;
    delete collectionItem.post_date;
    delete collectionItem.post_modified;
    delete collectionItem.post_name;
    delete collectionItem.post_parent;
    delete collectionItem.post_password;

    return collectionItem;
  }

  /**
   * Parse a list of collections
   * 
   * @param collections 
   * @returns 
   */
  parseCollections(collections: any) {
    Object.keys(collections).forEach((collectionName: string) => {
      if (
        collections[collectionName] &&
        collections[collectionName].items &&
        collections[collectionName].items.length
      ) {
        collections[collectionName].items.forEach((collectionItem: any, index: number) => {
          collections[collectionName].items[index] = this.parseCollection(collectionItem);
        });
      } else
      if(typeof collections[collectionName] === 'object' && collections[collectionName].length) {
        collections[collectionName].forEach((collectionItem: any, index: number) => {
          collections[collectionName][index] = this.parseCollection(collectionItem);
        });
      }
    });

    return collections;
  }

  /**
   * Remove an item from a collection
   * 
   * @param item 
   * @param collection 
   * @returns 
   */
  removeFromCollection(item: any, collection: string) {
    item.uid = item.uid || item.id;
    return new Promise((resolve, reject) => {
      let parentId = this.userService.getUid();
      if (!parentId) {
        reject("error_missing_parent_uid");
      } else if (!item.uid) {
        reject("error_missing_item_uid");
      } else {
        this.AppCMS.loadPluginData(
          "pipeline",
          {
            filter: {
              item: item.uid,
              user: parentId,
            },
          },
          ["collections", "delete"]
        )
          .then((response: any) => {
            this.events.publish('profile:refresh');
            
            this.translations
              .get(["removed_from_profile"])
              .subscribe((translations: any) => {
                this.events.publish("toast", {
                  message:
                    translations.removed_from_profile || "Aus Profil entfernt",
                  color: "primary",
                });
              });

            this.cache.remove("collections_byUser_" + parentId);
            resolve(response);
          })
          .catch(reject);
      }
    });
  }

  /**
   * @param item 
   * @param type 
   * @returns 
   */
  showAddDialog(item: any, type: string) {
    return new Promise(async (resolve, reject) => {

      let dialogModal = await this.modalCtrl.create({
        component: CollectionPickerPage,
        componentProps: {
          collectionsService: this,
          item: item,
          type: type,
        },
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: "collectionPickerModal",
      });

      dialogModal.onWillDismiss().then((response: any) => {
        response.data = response.data || {};
        let collection: string = response.data.collection;

        let type = 'custom';

        if(collection === 'posts' || collection === 'products') {
          type = collection;
        }
        
        if (!!collection) {
          this.add((item.uid || item.id), type, {
            name: collection,
          })
            .then((response: any) => {
              this.events.publish('profile:refresh');
              resolve(response);
            })
            .catch(reject);
        } else {
          reject("user_cancelled");
        }
      });

      dialogModal.present();
    });
  }

  showCreateFolderDialog() {
    return new Promise((resolve, reject) => {
      this.translations
        .get([
          "cancel",
          "create_folder_dialog_header",
          "create_folder_dialog_message",
          "enter_folder_name",
          "folder_name",
          "okay",
        ])
        .subscribe(async (translations: any) => {
          const alert = await this.alertCtrl.create({
            header: translations.enter_folder_name,
            message: translations.create_folder_dialog_message,
            inputs: [
              {
                type: "text",
                name: "folder_name",
                placeholder: translations.folder_name,
              },
            ],
            buttons: [
              {
                text: translations.okay,
                handler: (data: any) => {
                  let error: string|null = null;

                  if (!data.folder_name || !data.folder_name.length) {
                    error = "missing_folder_name";
                    return false;
                  }

                  if (error) {
                    alert.inputs[0].cssClass = "danger";
                    this.events.publish("error", error);
                    return false;
                  }

                  this.create({
                    name: data.folder_name,
                    type: "custom",
                  })
                    .then(resolve)
                    .catch(reject);
                },
              },
              {
                text: translations.cancel,
                role: "cancel",
              },
            ],
          });
          alert.onWillDismiss().then((data: any) => {
            //console.log("collection alert will dismiss", data);
          });
          alert.present();
        });
    });
  }

  /**
   * Update a collection
   * 
   * @param collection 
   * @returns {
   *  success: bool
   * }
   */
  update(collection: collectionsResponseItem, settings: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', {
      collection: collection,
      settings: settings,
    }, ['collections', 'update']);
  }

}