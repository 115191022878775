import { Component, Input, NgZone, OnInit } from '@angular/core';
import { IonContent, Platform, PopoverController } from '@ionic/angular';

import { PostReaderAppearancePopupPage } from 'src/app/pages/post/post-reader-appearance-popup/post-reader-appearance-popup.page';

import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { RatingsService } from 'src/app/services/social/ratings.service';
import { ReactionsService } from 'src/app/services/social/reactions.service';
import { SharingService } from 'src/app/services/sharing/sharing.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { UserService } from 'src/app/services/core/user.service';
import { WebService } from 'src/app/services/core/web.service';

@Component({
  selector: 'pipeline-post-footer-default',
  templateUrl: './post-footer-default.component.html',
  styleUrls: ['./post-footer-default.component.scss'],
})
export class PostFooterDefaultComponent implements OnInit {
  
  config: pipelineAppConfig;
  
  @Input() content: IonContent;
  @Input() post: any;
  @Input() view: any;

  user: user;
  
  utterance: SpeechSynthesisUtterance;
  utteranceConfig: any = {};

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private ratings: RatingsService,
    private reactions: ReactionsService,
    private sharing: SharingService,
    private tools: ToolsService,
    public userService: UserService,
    private zone: NgZone,
    private webService: WebService,
  ) {
    this.config = this.configService.getConfig();

    this.user = this.userService.getUser() || {};
    this.view = this.view || {};
  }

  detectChanges() {
    this.events.publish('post:reader:update', this.post);
  }

  getUtterance(blForceNew: boolean = false) {
    if(!this.utterance || blForceNew) {
      this.utterance = new SpeechSynthesisUtterance();
    }
    return this.utterance;
  }

  initSpeech() {
    try {
      if ('onvoiceschanged' in speechSynthesis) {
        speechSynthesis.onvoiceschanged = () => {
          this.parseSpeechVoices();
        };
      } else {
          this.parseSpeechVoices();
      }
    } catch(e) {
      console.warn('init speech error', e);
    }
  }
  
  ngOnInit() {
    this.view = this.view || {};
    
    this.platform.ready()
    .then(() => {
      this.initSpeech();
    });

    this.events.subscribe('post:view:updated', (view: any) => {
      this.view = view;
    });

    this.events.subscribe('tts:start', () => {
      this.textToSpeech();
    });

    this.events.subscribe('tts:stop', () => {
      this.stopTTS();
    });
  }

  parseSpeechVoices() {
    try {
      let voices = speechSynthesis.getVoices();
      const foundVoices = voices.filter((voice: any) => {
        let a = voice.voiceURI.indexOf('_male_') !== -1;
        let b = voice.lang.indexOf(this.view.language || 'de') !== -1;
        return a && b;
      });
      const foundVoice = foundVoices[0] || null;
      speechSynthesis.cancel();
      if (foundVoice) {
        this.utteranceConfig.voice = foundVoice;
      } else {
        console.warn('no voice found, using default');
      }
    } catch(e) {
      console.warn('parse speech voices failed', e);
    }
  }
  
  async rate() {
    
    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    // show rate modal
    this.ratings.rate(this.post)
    .then((response: any) => {
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }
  
  react(emoji: emoji) {

    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    this.events.publish('reactions:stage:render', {
      emoji: emoji.emoji,
    });

    setTimeout(() => {
      this.view.reaction = null;
      this.detectChanges();
    }, 3 * 1000);

    // submit reaction to server
    this.reactions.reactOnPost({
      emoji: emoji.name,
      item: this.post.uid,
      type: 'post',
    })
    .then((response: any) => {
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });

  }

  share() {

    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    this.sharing.sharePost(this.post);
  }

  stopTTS() {
    try {
      this.post.playing = false;
      this.view.mode = 'reader';
      speechSynthesis.pause();
    } catch(e) {
      console.warn('stop tts error', e);
    }
  }

  tagFriend() {

    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    this.sharing.internal(this.post)
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  textToSpeech(text: string|null = null) {
    
    if(this.tools.isWeb()) {
      return this.webService.appFeaturesRequested();
    }

    try {
      if(speechSynthesis && speechSynthesis.paused) {
        speechSynthesis.resume();
      } else
      if(this.post.playing) {
        this.stopTTS();
        return false;
      }
    } catch(e) {
      console.warn('speech synthesis resume error', e);
    }

    let blEmptyText = !!(text && text === '');
    text = this.tools.stripHtml(text !== null ? text : this.post.post_content);

    this.post.playing = true;

    let language = this.view.language || 'de';
    let locale = language + '-' + language.toUpperCase();
    let rate = 1.05;

    try {
      var utterance = this.getUtterance(blEmptyText);
      
      utterance.lang = locale;
      utterance.rate = rate;

      if(this.utteranceConfig.voice) {
        utterance.voice = this.utteranceConfig.voice;
      }

      this.view.mode = 'tts';
      this.view.ttsIndex = 0;

      // @todo fix this (tts sometimes not detected first time)
      utterance.text = '';
      speechSynthesis.speak(utterance);

      this.zone.run(() => {

        setTimeout(() => {

          utterance.onboundary = (event) => {
            if(event.name === 'word') {
              this.view.ttsIndex++;
              //let word = global_words[this.view.ttsIndex];
              
              if(!!this.content) {
                let scrollTop = (this.view.ttsIndex * (window.outerHeight * 0.017));
                if((this.view.ttsIndex % 5) === 4) {
                  this.content.scrollToPoint(0, scrollTop, 1000);
                  this.detectChanges();
                }
              }
            }
          };
      
          utterance.onend = (event) => {
            this.view.mode = 'reader';
            this.post.playing = false;
            //console.log('tts: end', event);
          };
      
          utterance.text = text;
          speechSynthesis.speak(utterance);
      
          //this.SpeechKit.tts(this.tools.stripHtml(this.post.post_content), 'DEU-DEU', 'Anna-ML')
          /*
          this.tts.speak({
            text: text,
            locale: locale,
            rate: rate, 
          })
          .then(() => {
            this.post.playing = false;
          })
          .catch((reason: any) => console.warn(reason));
          */
    
        });
      });

    } catch(e) {
      console.warn('speech error', e);
    }
  }

  async togglePostReaderAppearancePopup(event: any = null) {
    let popup = await this.popoverCtrl.create({
      component: PostReaderAppearancePopupPage,
      componentProps: {
        size: this.view.size,
      },
      event: event,
      cssClass: 'postReaderAppearancePopup',
    });
    popup.present();
  }

}
