import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { AuthService } from 'src/app/services/core/auth.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { HashService } from 'src/app/services/core/hash.service';
import { NetworkService } from 'src/app/services/core/network.service';

@Injectable({
  providedIn: 'root'
})
export class AppcmsService {

  _testingApiUrl: string = 'https://api.pipeline.page/api';

  api: apiConfig = {
    version: 3,
  };

  apiCredentials: any;

  apiUrl: string;

  // this will store the original api url, if changed programatically
  _apiUrl: string;
  
  blUseAuthorization: boolean = true;

  debug: boolean = false;

  debugApiEnvironment: 'staging';
  debugApiUrl: string;

  filters: any = {};

  mainApiUrl: string;

  requestParams: any = {};

  user: user;

  constructor(
    private auth: AuthService,
    private cache: CacheService,
    private config: ConfigService,
    private hash: HashService,
    private http: HttpClient,
    private network: NetworkService,
  ) {
    this.apiUrl = this.getApiUrl() || this.getMainApiUrl();
    this.mainApiUrl = this.config.getEnvironmentUrl('production', 'api');
  }

  executeLoadPluginData(
    plugin: string,
    data: any = {},
    actions: any = undefined,
    params: any = {},
  ) {
    params = params || {};
    
    return new Promise((resolve, reject) => {
      let action = (actions ? typeof actions === 'string' ? actions : actions.join('/') : null);
      let url: string;
      let headers = new Headers({ 'Content-Type': 'application/json' });

      if(action) {
        url = (params.apiUrl || this.getApiUrl()) + '/' + plugin + '/' + action + '.json';
      } else {
        url = (params.apiUrl || this.getApiUrl())  + '/' + plugin + '.json';
      }

      data.appPackageName = this.config.getAppPackageName();
      data.maxPostAge = this.config.getMaxPostAge();

      if(this.blUseAuthorization) {
        data.user = data.user || this.getApiCredentials();
        data.userId = data.userId || this.getUserUid();
      }

      this.http.post(url, JSON.stringify(data), { headers: headers as any })
          .subscribe(
            (response: any) => {
              if(!response) {
                reject('Ein unbekannter Fehler ist aufgetreten');
              } else
              if(response.success !== false && !response.message) {
                resolve(response);
              } else {
                reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
              }
            },
            async (error: any) => {
              console.warn('> error', error);
              
              let message: string = error.message;
              let blLoaded: boolean = (error && error.error) ? !!error.error.loaded : false;

              if(message.indexOf('Http failure') === 0 || message.indexOf('http failure') === 0) {
                message = 'Wir arbeiten zurzeit an Updates und sind in Kürze wieder da. Bitte versuche es in Kürze erneut.';
              }

              console.warn('appcms error 1', error);
              console.warn('loaded?', blLoaded);

              if(!blLoaded) {
                this.network.showOfflineMessage();
                reject('error_offline');
              } else {
                reject(message || 'Ein unbekannter Fehler ist aufgetreten');
              }

            }
          );
    });
  }

  get(route: string, options: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let url = `${this.getTestingApiUrl()}/${route}`;

      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');

      let credentials = this.getApiCredentials();

      // @debug
      credentials.email = 'max@mustermann.de';
      credentials.password = 'demodemo';

      let authorize: any = await this.auth.authorize(credentials as any);

      if(authorize && authorize.access_token) {
        headers.append('Authorization', authorize.access_token);

      } else {
        let basicToken: string = this.auth.getAuthorizationBasicToken(credentials as any);
        
        if(!!basicToken) {
          headers.append('Authorization', basicToken);
        }
      }
      
      this.http.get(url, {
        headers: headers,
        params: options,
      })
      .subscribe(
        (response: any) => {
          console.log('> http response', response);

          if(response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        },
        (error: any) => {
          let errorMsg: string = (error && error.hasOwnProperty('message') && !!error.message ? error.message : error);
          console.log('> errorMsg', errorMsg);

          reject(errorMsg);
        }
      );

    });
  }

  getApiCredentials(user: user|null = null) {
    user = user || this.user;
    return {
      'email': user.email,
      'password': user.password,
    };
  }

  getApiUrl() {
    if(this.debug && !this.apiUrl) {
      return this.getDebugApiUrl();
    }
    return this.apiUrl || this.getMainApiUrl();
  }

  getApiVersion() {
    return this.api.version;
  }

  getDebugApiUrl() {
    if(!this.debugApiUrl || !this.debugApiUrl.length) {
      this.debugApiUrl = this.config.getEnvironmentUrl(this.debugApiEnvironment, 'api');
    }
    return this.debugApiUrl;
  }

  getFilters() {
    return this.filters;
  }

  getMainApiUrl() {
    return this.mainApiUrl;
  }

  getRequestParams() {
    return this.requestParams;
  }

  getTestingApiUrl() {
    return this._testingApiUrl;
  }

  getUserUid(user: user|null = null) {
    user = user || this.user;
    return user && user.uid ? user.uid : null;
  }

  isMainApi() {
    return this.getApiUrl() === this.getMainApiUrl();
  }

  loadPluginData(
    plugin: string,
    data: any = {},
    actions: any = undefined,
    params: any = {},
    blForceRefresh: boolean = true
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        let requestParams: any = this.getRequestParams();
        
        if(!!requestParams) {
          data = Object.assign(data, requestParams);
        }

        if(this.debug) {
          data.debugDb = true;
        }

        data = data || {};
        data.version = (data.version || this.getApiVersion());

        if(!blForceRefresh) {
          let keyA: number = this.hash.hashCode(data),
            keyB: number = this.hash.hashCode(actions),
            keyC: number = this.hash.hashCode(params),
            fromCacheKey: string = `pipeline_${plugin}_${keyA}_${keyB}_${keyC}`,
            fromCache: cacheItem = await this.cache.get(fromCacheKey);

            if(fromCache && fromCache.data) {
              resolve(fromCache.data);
            } else {
              this.executeLoadPluginData(
                plugin, data, actions, params
              )
              .then((response: any) => {
                this.cache.set(fromCacheKey, response);
                resolve(response);
              }).catch(reject);
            }
        } else {
          this.executeLoadPluginData(
            plugin, data, actions, params
          )
          .then(resolve).catch(reject);
        }

        
      } catch(e) {
        reject(e.message);
      }
    });
  }

  loadUrl(url: string, data: any = {}, blForceReturnContent: boolean = false) {
    return new Promise((resolve, reject) => {
      try {
        this.http.get(url)
        .subscribe(
          (response: any) => {
            if(!!blForceReturnContent) {
              resolve(response);
            } else
            if(!response) {
              reject('Ein unbekannter Fehler ist aufgetreten');
            } else
            if(response.success !== false && !response.message) {
              resolve(response);
            } else {
              reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
            }
          },
          (response: any) => {
            if(!!blForceReturnContent) {
              resolve(response && response.error && response.error.text ? response.error.text : response);
            } else {
              let message = response.message;
              if(message.indexOf('Http failure') === 0 || message.indexOf('http failure') === 0) {
                message = 'Wir arbeiten aktuell an unserer App und sind in Kürze wieder da. Bitte versuche es in Kürze erneut.';
              }
              console.warn('appcms error 2', response);
              reject(message || 'Ein unbekannter Fehler ist aufgetreten');
            }
          }
        );
      } catch(e) {
        reject(e.message);
      }
    });
  }

  postPluginData(plugin: string, method: string = 'create', data: any = {}) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getApiUrl() + '/' + plugin + '/' + method + '.json', data, {})
      .subscribe((response: any) => {
        resolve(response);
      });
    });
  }

  resetApiUrl() {
    this.apiUrl = this._apiUrl || this.getMainApiUrl();
  }

  setApiUrl(apiUrl: string) {
    if(!this._apiUrl) {
      this._apiUrl = '' + this.apiUrl;
    }
    this.apiUrl = apiUrl;
  }

  setFilter(key: string, value: any) {
    this.filters[key] = value;
    return this;
  }

  setRequestParam(key: string, value: any) {
    this.requestParams[key] = value;
    return this;
  }

  storeCurrentAuthDetails(user: user) {
    this.user = user;
    return this;
  }

}
