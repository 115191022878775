import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { CollectionsService } from 'src/app/services/pipeline/collections.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'app-custom-collection-viewer',
  templateUrl: './custom-collection-viewer.page.html',
  styleUrls: ['./custom-collection-viewer.page.scss'],
})
export class CustomCollectionViewerPage implements OnInit {

  collection: collectionsResponseItem;

  view: any = {

  };

  user: user;

  constructor(
    private collections: CollectionsService,
    private events: EventsService,
    private modalCtrl: ModalService,
    private navParams: NavParams,
    public userService: UserService,
  ) {
    this.collection = this.navParams.get('collection');
    this.user = this.userService.getUser();

    if(this.collection && this.collection.items && this.collection.items.length) {
      this.collection.items.forEach((collectionItem: any, index: number) => {
        this.collection.items[index] = this.collections.parseCollection(collectionItem);
      });
    }
  }

  async dismiss(data: any = null) {
    this.modalCtrl.dismiss(data);
  }

  doRefresh(event: any = null) {
    this.load(true)
    .then(() => {
      if(event) {
        event.target.complete();
      }
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser();

    this.view.admin = (
      (this.user.uid === this.collection.user)
    );

  }

  load(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.collections.getByUid(this.collection.uid, blForceRefresh)
      .then((collection: collectionsResponseItem) => {
        this.collection = this.collections.parseCollection(collection);
        resolve(this.collection);
      })
      .catch((error: any) => {
        this.dismiss();
        
        if(error && error.message) {
          this.events.publish('error', error.message);
        } else
        if(typeof error === 'string') {
          this.events.publish('error', error);
        }
      });
    });
  }

  ngOnInit() {
  }

  onColClick(col: col, type: string|null = null) {
    col.type = col.type || type;

    switch(col.type) {
      case 'custom':
        return this.onCustomFolderClick(col as collectionsResponseItem);
      case "feed_post":
        return this.onPostClick(col as post);
      case 'person':
        return this.onPersonClick(col as user);
      case 'post':
        return this.onPostClick(col as post);
      case "product":
        return this.onProductClick(col);
      case 'simple':
        return this.onProductClick(col);
      case 'variable':
        return this.onProductClick(col);
      default:
        this.events.publish('error', 'Unbekannter Typ ' + col.type); 
        break;
    }
  }

  async onCustomFolderClick(collectionsResponseItem: collectionsResponseItem) {
    this.events.publish('view:collection', collectionsResponseItem);
  }

  async onPersonClick(user: user) {
    this.events.publish('view:profile', user);
  }

  async onPostClick(post: post) {
    this.events.publish('view:post', post);
  }

  async onProductClick(product: any) {
    this.events.publish('view:product', product);
  }

  settings() {
    if(this.view.admin) {
      this.collections.configureCollection(this.collection)
      .then((response: any) => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }
  }

}
