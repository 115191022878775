import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

import { AccountsService } from "src/app/services/core/accounts.service";
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { BackgroundService } from "src/app/services/core/background.service";
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { FeedService } from "src/app/services/pipeline/feed.service";
import { TeamsService } from "src/app/services/core/teams.service";
import { TrackingService } from "src/app/services/utils/tracking.service";
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: "root",
})
export class PipelineService {

  homeData: homeData[] = [];

  view: any = {
    categoriesLoaded: false,
  };

  constructor(
    private accounts: AccountsService,
    private AppCMS: AppcmsService,
    private background: BackgroundService,
    public configService: ConfigService,
    private events: EventsService,
    private feed: FeedService,
    private loading: LoadingController,
    private teams: TeamsService,
    private tracking: TrackingService,
    public userService: UserService
  ) {
    this.init();
  }

  init() {
    this.events.subscribe('appcms:user:updated', () => {
      let user = this.userService.getUser();

      if (user && user.uid) {
        this.background.init();
      }

    });
  }

  login(userData: user) {
    return new Promise(async (resolve, reject) => {
      let filterUserByEmail: string = this.configService.filterUserByEmail();

      if (!userData.email || !userData.email.length) {
        reject('error_missing_email');
      } else
        if (!userData.password || !userData.password.length) {
          reject('error_missing_password');
        } else
          if (!!filterUserByEmail && (userData.email.indexOf(filterUserByEmail) === -1)) {
            reject('error_email_filterUserByEmail_403');
          } else {

            const loading = await this.loading.create({
              backdropDismiss: false,
              spinner: 'circular',
            });
            loading.present();

            this.AppCMS.postPluginData("pipeline", "login", {
              user: userData,
              uid: this.userService.getUid(),
              expanded: false,
              //language: this.userService.getLanguage(),
              limit: 1,
              categories: false,
              includeRatings: false,
              includeReactions: false,
              includeRelatedArticles: false,
              people: false,
              stories: false,
            })
              .then(async (response: any) => {
                loading.dismiss();

                if (response.success && !!response.user) {

                  this.validateTeamsPermissions(response.user)
                    .then((validateResponse: any) => {
                      if(!!validateResponse && !!validateResponse.success) {
                        this.feed.setFeedData('home', JSON.parse(JSON.stringify(response)));

                        response.user.password = userData.password;
                        this.events.publish("appcms:user:loggedin");
  
                        this.accounts.add(response.user);
                        this.userService.setUser(response.user);
  
                        if (this.userService.shouldConnectWithSIWA()) {
                          setTimeout(() => {
                            this.userService.connectWithSIWA({}, 2);
                          }, 1000);
                        }
  
                        try {
                          this.tracking.askTrackingPermission();
                        } catch (e) {
                          console.warn('> tracking prompt error', e);
                        }
  
                        resolve(response);
                      } else {
                        reject('error_failed_validating_user');
                      }
                    })
                    .catch(reject);

                } else {
                  reject(
                    response.message || "Ein unbekannter Fehler ist aufgetreten"
                  );
                }
              })
              .catch((error) => {
                loading.dismiss();
                reject(error);
              });
          }
    });
  }

  move(postId: number) {
    return new Promise((resolve, reject) => {
      //console.log("move", postId);
    });
  }

  validateTeamsPermissions(user: user) {
    return new Promise((resolve, reject) => {
      let filterUserByTeamUids: boolean = this.configService.filterUserByTeamUids();

      if (!!filterUserByTeamUids) {
        let appTeamUids: number[] = this.configService.allowedTeamUids();
        
        console.log('> user data', {userId: user.uid, user: {email: user.email }});

        this.teams.getAvailable({user: user.uid}, true, {userId: user.uid, user: {email: user.email }})
        .then((userTeams: team[]) => {

          if(!userTeams || !userTeams.length) {
            reject('error_no_available_teams');
          } else {

            let userTeamsUids: number[] = (userTeams || []).map((team: team) => {
              return team.uid;
            });
  
            let blFound: boolean = userTeamsUids.some(r=> appTeamUids.includes(r));
  
            if(!blFound) {
              reject('error_user_app_team_mismatch');
            } else {
              resolve({success: true});
            }
          }
        })
        .catch(reject);
      }
    });
  }

}