import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';

import { AppLauncher, CanOpenURLResult } from '@capacitor/app-launcher';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private events: EventsService,
    private platform: Platform,
    public userService: UserService,
  ) {
  }

  acceptPost(post: post) {
    return new Promise((resolve, reject) => {
      resolve(post);
    });
  }

  createPost(post: post = null) {
    return new Promise((resolve, reject) => {
      resolve(post);
    });
  }

  editPost(post: post) {
    return new Promise((resolve, reject) => {
      resolve(post);
    });
  }

  init() {
    if (!this.userService.isType('Admin')) {
      return false;
    }
  }

  navigateTo(target: string) {
    try {
      const options: any = {};

      if (this.platform.is('ios')) {
        options.url = 'pipeline-admin://';
      } else {
        options.url = 'page.pipeline.admin';
      }

      AppLauncher.canOpenUrl(options)
        .then((canLaunch: CanOpenURLResult) => {
          AppLauncher.openUrl(options);
        })
        .catch((error: any) => {
          console.error('App is not available', error);
          this.events.publish('error', error);
        });
    } catch (e) {
      console.warn('navigate error', e);
      this.events.publish('error', e);
    }

  }

}