import { Injectable } from "@angular/core";
import { EventsService } from 'src/app/services/core/events.service';
import { SettingsService } from "src/app/services/core/settings.service";
import { UserService } from 'src/app/services/core/user.service';

import { StatusBar, Style } from '@capacitor/status-bar';

@Injectable({
  providedIn: "root",
})
export class ThemesService {

  constructor(
    private events: EventsService,
    private settings: SettingsService,
    public userService: UserService
  ) {
    this.initEvents();
  }

  getPrefersMode(mode: string = "dark") {
    return window.matchMedia("(prefers-color-scheme: " + mode + ")");
  }

  getUserPreferedTheme(user: user|null = null) {
    return this.settings.getSetting("colorScheme");
  }

  async init() {
    let userId = this.userService.getUid(),
        userPreferedTheme = (!!userId ? await this.getUserPreferedTheme() : null),
        prefersDark = this.getPrefersMode();

    if (!!userPreferedTheme) {
      this.set(userPreferedTheme);
    } else {
      this.toggleDarkTheme(
        userPreferedTheme === "dark" || (prefersDark && !!prefersDark.matches)
      );
    }

    prefersDark.addListener(async (mediaQuery) => {
      let userPreferedTheme = (!!userId ? await this.getUserPreferedTheme() : null);
      if(!userPreferedTheme || (userPreferedTheme === 'system')) {
        this.toggleDarkTheme(mediaQuery.matches);
      }
    });
  }

  initEvents() {
    this.events.subscribe('theme:colors:updated', (colors: any) => {
      let keys = Object.keys(colors);

      if(keys && keys.length) {
        keys.forEach((key: string) => {
          var r: any = document.querySelector(':root');
          r.style.setProperty(`${key}`, colors[key]);
        });
      }
      
    });
  }

  set(scheme: any) {

    if(scheme === 'system') {
      let prefersDark = this.getPrefersMode();
      return this.toggleDarkTheme((prefersDark && !!prefersDark.matches));
    }

    if (scheme === "light" || scheme === "dark") {
      return this.toggleDarkTheme(scheme === "dark");
    }

    if (scheme.indexOf("dark") === -1) {
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.add("light");

      try {
        StatusBar.setStyle({ style: Style.Light });
      } catch(e) {
        console.warn('> statusBar error', e);
      }
    } else if (scheme.indexOf("light") === -1) {
      document.documentElement.classList.remove("light");
      document.documentElement.classList.add("dark");

      try {
        StatusBar.setStyle({ style: Style.Dark });
      } catch(e) {
        console.warn('> statusBar error', e);
      }
    }

    document.documentElement.classList.add(scheme);
  }

  toggleDarkTheme(shouldAdd: boolean) {
    document.documentElement.classList.remove("dark_pipeline");
    document.documentElement.classList.remove("light_pipeline");

    if (shouldAdd) {
      document.documentElement.classList.add("dark");
      document.documentElement.classList.remove("light");
    } else {
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.add("light");
    }
  }
  
}
