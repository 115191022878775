import { Injectable } from '@angular/core';
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private config: ConfigService,
    private posts: PostsService,
    public userService: UserService,
  ) {

  }

  addSource(source: any = null) {
    return new Promise(async (resolve, reject) => {

    });
  }

  deleteSource(sourceId: number) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['shop', 'sources', sourceId, 'delete']);
  }

  getByUid(sourceId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['shop', 'sources', sourceId],
      {},
      blForceRefresh
    );
  }

  getCategories(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['shop', 'categories'],
      {},
      blForceRefresh
    );
  }

  getCategoryByUid(productId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['shop', 'categories', productId],
      {},
      blForceRefresh
    );
  }

  getFullProducts(products: any) {
    products.forEach((product: any, index: number) => {
      product.id = product.id || (product.original_uid || product.uid);
      product.thumbnail = product.thumbnail || product.image;
      product.images = product.images || [{
        src: product.image,
      }];

      if(!!product.price && (typeof product.price === 'number')) {
        product.price = product.price.toFixed(2);
        product.price_html = product.price_html || (product.price + ' €');
      }
      
      product = this.posts.getFullPost(product);
      product.image = product.thumbnail;
      product.type = product.type !== 'post' ? product.type : 'product';

      products[index] = product;
    });

    products = products.filter((product: product) => {
      return product && product.image && product.image.length;
    });

    return products;
  }

  getProductByUid(productId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['shop', 'products', productId],
      {},
      blForceRefresh
    );
  }

  getProductsByCategory(categoryId: number, blForceRefresh: boolean = false, options: any = {}) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'pipeline',
        options,
        ['shop', 'categories', categoryId, 'products'],
        {},
        blForceRefresh
      )
      .then((products: product[]) => {
        this.parseProducts(products)
        .then(resolve)
        .catch(() => {
          resolve(products);
        });
      })
      .catch(reject);
    });
  }

  getCategoriesBySource(sourceId: number, filter: any = {}, blForceRefresh: boolean = false, options = {})
  {
    return new Promise(async (resolve, reject) => {
      let key = 'categories_bySource_' + sourceId + '_' + JSON.stringify(filter) + JSON.stringify(options),
          fromCache: cacheItem = await this.cache.get(key, 60 * 60); 

      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
          filter: filter,
          user: this.userService.getUid(),
        }), ['shop', 'sources', sourceId, 'categories'])
        .then((response: any) => {
          if(response.items) {
            this.cache.set(key, response.items)
            .then(() => {
              resolve(response.items);
            })
            .catch(() => {
              resolve(response.items);
            });
          } else {
            reject(response);
          }
        })
        .catch(reject);
      }
    });
  }

  getProducts(filter: any = {}, blForceRefresh: boolean = false, options = {})
  {
    return new Promise(async (resolve, reject) => {
      let key = 'products_' + JSON.stringify(filter) + JSON.stringify(options),
          fromCache: cacheItem = await this.cache.get(key, 60 * 60); 
      
      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
          filter: filter,
          user: this.userService.getUid(),
        }), ['products'])
        .then((products: product[]) => {
          this.parseProducts(products)
          .then((products: product[]) => {
            this.cache.set(key, products)
            .then(() => {
              resolve(products);
            })
            .catch(() => {
              resolve(products);
            });
          })
          .catch(() => {
            resolve(products);
          });
        })
        .catch(reject);
      }
    });
  }

  getProductsBySource(sourceId: number, filter: any = {}, blForceRefresh: boolean = false, options = {})
  {
    return new Promise(async (resolve, reject) => {
      let key = 'products_bySource_' + sourceId + '_' + JSON.stringify(filter) + JSON.stringify(options),
          fromCache: cacheItem = await this.cache.get(key, 60 * 60); 
      
      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
          filter: filter,
          user: this.userService.getUid(),
        }), ['shop', 'sources', sourceId, 'products'])
        .then((response: any) => {
          if(response.items) {
            this.cache.set(key, response.items)
            .then(() => {
              resolve(response.items);
            })
            .catch(() => {
              resolve(response.items);
            });
          } else {
            reject(response);
          }
        })
        .catch(reject);
      }
    });
  }

  getShop(blForceRefresh: boolean = false, options: any = {}) {
    return new Promise((resolve, reject) => {

      if(this.config.isWhitelabel() && !!this.config.getContentHostUrl()) {
        options.filter = options.filter || {};
        options.filter.host = this.config.getContentHostUrl();
        options.like = this.config.getContentHostUrlLike();
      }

      this.AppCMS.loadPluginData(
        'pipeline',
        Object.assign(options, {}),
        ['shop'],
        {},
        blForceRefresh
      )
      .then((shopData: shopData) => {

        // parse sources
        if(shopData && shopData.sources && shopData.sources.length) {
          shopData.sources.forEach((source: source) => {

            if(source.items && source.items.length) {
              source.items = this.posts.getFullPosts(source.items);
            }

            source.thumbnail = source.thumbnail || (source.items && source.items[0] ? (source.items[0].thumbnail || (source.items[0] as any).image) : null);
          });
        }

        // parse products
        if(shopData && shopData.products) {
          try {
            let keys: string[] = Object.keys(shopData.products);
            keys.forEach((key: string) => {
              let productsByKey = shopData.products[key];
              
              if(productsByKey && productsByKey.length) {
                productsByKey.forEach((product: product) => {
                  product.price_html = product.price_html || `${parseFloat(product.price as any).toFixed(2)} €`;
                  product.price = (!!product.price_html && !!product.price ? product.price : parseFloat(product.price_html));
                });
              }
            })
          } catch(e) {
            console.warn('> parse shop products failed', e);
          }
        }

        resolve(shopData);
      })
      .catch(reject);
    });
  }

  getSources(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      Object.assign(options, {}),
      ['shop', 'sources'],
      {},
      blForceRefresh
    );
  }

  getStatisticsBySource(sourceId: number, filter: any = {}, blForceRefresh: boolean = false, options = {}) {
    return new Promise(async (resolve, reject) => {
      let key = 'categories_bySource_' + sourceId + '_' + JSON.stringify(filter) + JSON.stringify(options),
          fromCache: cacheItem = await this.cache.get(key, 60 * 60); 
      
      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
          filter: filter,
          user: this.userService.getUid(),
        }), ['shop', 'sources', sourceId, 'statistics'])
        .then((statistics: any) => {
          this.cache.set(key, statistics)
          .then(() => {
            resolve(statistics);
          })
          .catch(() => {
            resolve(statistics);
          });
        })
        .catch(reject);
      }
    });
  }

  getVariationByProduct(product: product) {
    return new Promise((resolve, reject) => {
    });
  }

  isConfigured(shop: shopSource) {
    return !!(shop && !!shop.credentials && !!shop.credentials.consumer_key && !!shop.credentials.consumer_secret);
  }
  
  parseCategories(categories: category[]) {
    if(categories && categories.length) {

      categories.forEach((category: any) => {
        category.thumbnail = category.thumbnail || category.image;
      });

      let filteredCategories = categories.filter((category: category) => {
        return category && !!category.thumbnail;
      });

      if(filteredCategories && filteredCategories.length) {
        categories = filteredCategories;
      }
    }
    return categories;
  }

  parseProducts(products: product[]) {
    return new Promise((resolve, reject) => {
      resolve(products);
    });
  }

}
