import { Injectable } from '@angular/core';
import { AccountsService } from 'src/app/services/core/accounts.service';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { SettingsService } from 'src/app/services/core/settings.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  active: tab[];

  checked: tab[];

  config: pipelineAppConfig;

  key: string = 'woo_tabs';

  defaultTabs: tab[] = [
    {
      checked: true,
      uid: 'home',
      route: 'tabs/home',
      icon: 'home-outline',
      name: 'home',
    },
    {
      checked: true,
      uid: 'discover',
      route: 'tabs/discover',
      icon: 'library-outline',
      name: 'discover',
    },
    {
      checked: true,
      uid: 'eventsList',
      route: 'tabs/events-list',
      icon: 'calendar-outline',
      name: 'events_list',
    },
    {
      checked: true,
      uid: 'jobPosts',
      route: 'tabs/job-posts',
      icon: 'business-outline',
      name: 'job_posts',
    },
    {
      checked: true,
      uid: 'people',
      route: 'tabs/people',
      icon: 'people-outline',
      name: 'follow',
    },
    {
      checked: true,
      uid: 'local',
      route: 'tabs/local',
      icon: 'location-outline',
      name: 'local',
    },
    {
      checked: true,
      uid: 'profile',
      route: 'tabs/profile/me',
      icon: 'person-outline',
      name: 'profile',
    },
    {
      checked: true,
      uid: 'showroom',
      route: 'tabs/showroom',
      icon: 'easel-outline',
      name: 'showroom',
    },
    {
      checked: true,
      uid: 'shop',
      route: 'tabs/shop',
      icon: 'storefront-outline',
      name: 'shop',
    },
    /*
    {
      checked: false,
      uid: 'settings',
      route: 'tabs/settings',
      icon: 'menu-outline',
      name: 'settings',
    },
    */
    {
      checked: true,
      uid: 'inbox',
      route: 'tabs/inbox',
      icon: 'file-tray-outline',
      name: 'inbox',
    },
  ];

  handlers: any = {};

  constructor(
    private accounts: AccountsService,
    private configService: ConfigService,
    private events: EventsService,
    private settings: SettingsService,
    private tools: ToolsService,
    private user: UserService,
  ) {
    this.config = this.configService.getConfig();

    this.initHandlers();
  }

  getActive(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if(this.config.tabs && this.config.tabs.length) {
        resolve(this.config.tabs);
      } else
      if(this.active && !blForceRefresh) {
        resolve(this.active.filter((tab: tab) => {
          return this.isEnabledInConfig(tab.uid);
        }));
      } else
      if(this.user.getUid()) {
        this.settings.getSetting(this.key, blForceRefresh)
        .then((serverSideTabs: tab[]) => {

          let defaultTabs = this.getDefaultTabs(),
              tabs = (serverSideTabs || []),
              routesByTabs: any = {};

          defaultTabs.forEach((defaultTab: tab) => {
            routesByTabs[defaultTab.uid] = defaultTab.route;

            let foundTab = (tabs || []).filter((tab: tab) => {
              return tab.uid === defaultTab.uid;
            });
            let blFound = !!(foundTab && foundTab[0]);

            if(!blFound) {
              tabs.push(defaultTab);
            }

          });

          if(serverSideTabs && serverSideTabs.length) {

            serverSideTabs.forEach((serverSideTab: tab) => {

              let defaultTabSelect = defaultTabs.filter((tab: tab) => {
                return tab.uid === serverSideTab.uid;
              }), defaultTab: tab = defaultTabSelect[0];

              if(defaultTab) {
                serverSideTab.icon = (defaultTab.icon || serverSideTab.icon);
                serverSideTab.route = defaultTab.route || serverSideTab.route;
              }

              serverSideTab.route = routesByTabs[serverSideTab.uid] || serverSideTab.route;
            });

          }

          this.active = this.parseTabs(
            tabs || this.getDefaultTabs()
          )
          .filter((tab: tab) => {
            return this.isEnabledInConfig(tab.uid);
          });

          resolve(this.active);
        })
        .catch((error: any) => {
          console.warn('tabs error', error);
          
          this.active = this.parseTabs(
            this.getDefaultTabs()
          )
          .filter((tab: tab) => {
            return this.isEnabledInConfig(tab.uid);
          });

          resolve(this.active);
        });
      } else {

        this.active = this.parseTabs(
          this.getDefaultTabs()
        )
        .filter((tab: tab) => {
          return this.isEnabledInConfig(tab.uid);
        });

        resolve(this.active);
      }
    });
  }

  async getChecked(blForceRefresh: boolean = false) {
    if(!this.checked || blForceRefresh) {
      let activeTabs: any = await this.getActive(blForceRefresh);

      if(activeTabs && activeTabs.length) {

        activeTabs.forEach((tab: tab) => {
          tab.route = this.configService.getRoute(tab.route) || tab.route;
        });
  
        this.checked = activeTabs.filter((tab: tab) => {
          return !!tab.checked;
        });
      }
    }
    return this.checked;
  }

  getDefaultTabs() {
    return JSON.parse(JSON.stringify(this.defaultTabs));
  }

  getHandler(tabId: string) {
    return this.handlers[tabId];
  }

  async getIndex(tabId: string, blForceActive: boolean = false) {
    let tabs = await this.getActive() as tab[], _index: boolean|number = null;
    tabs.forEach((tab: tab, index: number) => {
      if(tab && (tab.uid === tabId) && (!blForceActive || tab.checked)) {
        _index = index;
      }
    });
    return _index;
  }

  initHandlers() {

    this.handlers.requiresLoginHandler = (tab: tab, event: any) => {
      return new Promise((resolve, reject) => {

        if(!this.user.isLoggedIn()) {
          this.accounts.switch()
          .then(() => {       
            resolve(this.user.isLoggedIn());
          })
          .catch(reject);
        } else {
          resolve(true);
        }
      });
    };

    this.handlers.dating = this.handlers.requiresLoginHandler;
    this.handlers.groups = this.handlers.requiresLoginHandler;
    this.handlers.inbox = this.handlers.requiresLoginHandler;
    this.handlers.interests = this.handlers.requiresLoginHandler;
    this.handlers.people = this.handlers.requiresLoginHandler;
    this.handlers.profile = this.handlers.requiresLoginHandler;
    this.handlers.surveys = this.handlers.requiresLoginHandler;

    this.handlers.categories = async (tab: tab, event: any = null) => {
      this.events.publish('view:categories');
      return false;
    };

    this.handlers.search = async (tab: tab, event: any = null) => {
      this.events.publish('view:search');
      return false;
    };
  }

  async isActive(tabId: string) {
    let tabs = await this.getActive() as tab[];
    let blMatch = tabs.filter((tab: tab) => {
      return tab && (tab.uid === tabId);
    });
    return !!(blMatch && blMatch[0] ? (blMatch[0] as tab).checked : false);
  }

  isEnabledInConfig(tabId: string) {
    let __var = `use${this.tools.capitalize(tabId)}`;
    return !!(this.config && this.config.hasOwnProperty(__var) ? this.config[__var] : false);
  }

  parseTabs(tabs: tab[]) {
    if(tabs && tabs.length) {
      tabs.forEach((tab: tab) => {
        tab.checked = (tab.checked !== false) && this.isEnabledInConfig(tab.uid);

        if(tab.uid === 'categories') {
          tab.icon = 'grid-outline';
        }
      });

      tabs = tabs.filter((tab: tab) => {
        return tab.uid !== 'inbox' && tab.uid !== 'profile';
      });

    }
    return tabs;
  }

  set(tabs: any) {
    return new Promise((resolve, reject) => {
      this.settings.updateSetting(this.key, tabs)
      .then((response: any) => {
        this.events.publish('tabs:updated', tabs);
        resolve(response);
      })
      .catch(reject);
    });
  }

}