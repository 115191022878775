import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'app-invisible-screen',
  templateUrl: './invisible-screen.page.html',
  styleUrls: ['./invisible-screen.page.scss'],
})
export class InvisibleScreenPage implements OnInit {

  user: user;

  constructor(
    private events: EventsService,
    private modalCtrl: ModalController,
    public userService: UserService,
  ) {
    this.user = this.userService.getUser() || {};
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};
  }
  
  ngOnInit() {
  }

  setVisible(blVisible: boolean, blForce: boolean = false) {
    this.user.public = true;
    this.user.classifications.public = blVisible;
    this.user.classifications.forcePublicState = blForce;
    
    return this.update();
  }

  update() {
    this.userService.setUser(this.user, true)
    .then(() => {
      this.dismiss();
    })
    .catch((error: any) => {
      this.events.publish('eror', error);
    });
  }

}
