import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { EventsService } from 'src/app/services/core/events.service';
import { ReactionsService } from 'src/app/services/social/reactions.service';
import { StoriesService } from 'src/app/services/social/stories.service';
import { UserService } from 'src/app/services/core/user.service';

import * as $ from 'jquery';

import { ToolsService } from 'src/app/services/utils/tools.service';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
  selector: 'app-story',
  templateUrl: './story.page.html',
  styleUrls: ['./story.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoryPage implements OnInit {
  
  avatarUrl: string = './assets/img/avatars/1.jpg';

  config: pipelineAppConfig;

  private slider: any;

  sliderOptions: any = {
    autoplay: {
      delay: 10000,
    },
  };

  story: story;

  user: user;

  view: any = {
    activeIndex: 0,
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private platform: Platform,
    public userService: UserService,
    private zone: NgZone,
    private sanitizer: DomSanitizer,
    private stories: StoriesService,
    private tools: ToolsService,
    private reactions: ReactionsService,
  ) {
    this.avatarUrl = this.userService.getAvatarUrl();
    this.config = this.configService.getConfig();

    if(!!this.config.useReactions) {
      this.view.emojis = this.reactions.getEmojis();
    }

    this.view.index = parseInt(this.navParams.get('index'));
    this.story = this.stories.parseStory(this.navParams.get('story'));
    
    this.loaded();

    this.user = this.userService.getUser();
  }

  delete(post: post) {
    this.stories.delete({
      uid: this.story.uid,
      item: post.uid,
      user: this.userService.getUid(),
    })
    .then((response: any) => {
      this.events.publish('people:refresh');
      this.dismiss();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  async dismiss(event = null) {
    if(event && event.target) {
      event.target.complete();
    }
    this.modalCtrl.dismiss();
  }

  ionSlideDidChange() {
    this.slider.getActiveIndex()
    .then((activeIndex: number) => {
      this.view.activeIndex = activeIndex;
      this.loaded();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser();
    
    this.platform.ready().then(() => {
      this.updateSlider();
    });
  }

  ngOnInit() {
  }

  loaded() {
    if(this.story.posts && this.story.posts[this.view.activeIndex]) {
      this.story.timestamp = this.story.posts[this.view.activeIndex].timestamp;
      
      if(this.story.timestamp) {
        this.story.timestamp_formatted = this.story.posts[this.view.activeIndex].timestamp_formatted;
      }
    
      if(this.story.posts[this.view.activeIndex].post_excerpt) {
        this.story.posts[this.view.activeIndex].post_excerpt_formatted = this.sanitizer.bypassSecurityTrustHtml(this.story.posts[this.view.activeIndex].post_excerpt);
      }
    
      if(!!this.story.posts[this.view.activeIndex].video) {
        this.playVideo();
      }
      
    }
  }

  onPostCardClick(post: post) {
    if(!post.video) {
      this.read(post);
    }
  }

  playVideo() {
    setTimeout(() => {
      this.zone.run(() => {
        let wrapper = $('.video-wrapper'),
            video = wrapper.eq(this.view.activeIndex).find('video').first();
        
        video.attr('poster', this.tools.getVideoPosterUrl());

        setTimeout(() => {
          if(video && video[0]) {
            video[0].play();
          }
        }, 500);

      });
    }, 50);
  }

  react(emoji: emoji, story: story) {
    
    let reaction: reaction = {
      emoji: emoji.name,
      item: story.uid,
      partner: story.user,
      type: 'story',
    };

    this.events.publish('reactions:stage:render', reaction);

    this.reactions.send(reaction)
    .then((response: any) => {
      //console.log('response', response);
    })
    .catch((error: any) => {
      console.warn('reactions error', error);
      this.events.publish('error', error);
    });
    
  }

  read(post: post) {

    if(!!post.video) {
      this.stopVideo();
    }

    switch(post.type) {
      case 'product':
        this.events.publish('view:product', post);
        break;
      case 'profile':
        this.events.publish('view:profile', post);
        break;
      default:
        this.events.publish('view:post', post);
        break;
    }
  }

  scrollToIndex() {
    try {
      if(!!this.view.index) {
        this.slider.slideTo(this.view.index);
      }
    } catch(e) {
      console.warn('scroll to index error', e);
    }
  }

  setSwiperInstance(event: any) {
    this.slider = event;
    this.scrollToIndex();
  }

  stopVideo() {
    let wrapper = $('.video-wrapper'),
        video = wrapper.eq(this.view.activeIndex).find('video').first();

    if(video && video[0]) {
      video[0].pause();
    }

  }

  thumbnailLoadingFailed(post: post) {
    
  }

  updateSlider() {
    this.zone.run(() => {
      setTimeout(() => {
        if(this.slider) {
          if(this.story && this.story.posts && this.story.posts.length > 1) {
            try {
              this.slider.startAutoplay();
            } catch(e) {
              console.warn('> update slider error', e);
            }
          }
          this.slider.update();
        }
      });
    });
  }

  viewProfile() {
    if(this.story && this.story.user && (this.story.user as user).uid) {
      this.events.publish('view:profile', (this.story.user as user).uid);
    }
  }

}
