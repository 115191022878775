import { Injectable } from '@angular/core';

import { Badge } from '@capawesome/capacitor-badge';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {

  constructor(

  ) {

  }

  increase() {
    try {
      Badge.increase();
    } catch(e) {
      console.warn('badge failed', e);
    }
  }

}