import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { IconsService } from 'src/app/services/utils/icons.service';
import { ModalService } from 'src/app/services/core/modal.service';

@Component({
  selector: 'app-configure-collection',
  templateUrl: './configure-collection.page.html',
  styleUrls: ['./configure-collection.page.scss'],
})
export class ConfigureCollectionPage implements OnInit {

  collection: collectionsResponseItem;

  collections: any;

  settings: any = {};

  constructor(
    private events: EventsService,
    private icons: IconsService,
    private navParams: NavParams,
    private modalCtrl: ModalService,
  ) {
    this.collection = this.navParams.get('collection');
    this.collections = this.navParams.get('collectionsService');
  }

  configureIcon() {
    this.icons.pick()
    .then((response: any) => {

      if(response.icon) {
        this.collection.icon = response.icon;
      }
      
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  deleteCollection() {
    this.collections.delete(this.collection.uid)
    .then(() => {
      this.dismiss();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  async dismiss(data: any = null) {
    (await this.modalCtrl).dismiss(data);
  }

  ngOnInit() {
  }

  updateCollection() {
    this.collections.update(this.collection, this.settings)
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

}
