import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { EventsService } from 'src/app/services/core/events.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertCtrl: AlertController,
    private events: EventsService,
  ) {
  }

  init() {
    this.initEvents();
  }

  initEvents() {
    this.initOnAlertEvent();
  }

  initOnAlertEvent() {

    this.events.subscribe("alert", async (alertConfig: alertConfig) => {
      let alertData = {
        header: alertConfig.header || "Fehler",
        message:
          alertConfig.message || "Ein unbekannter Fehler ist aufgetreten",
        buttons: alertConfig.buttons || [
          {
            text: "Okay",
          },
        ],
      };

      const alert = await this.alertCtrl.create(alertData);
      alert.present();
    });

  }

}
