import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { UserService } from 'src/app/services/core/user.service';
import { EventsService } from 'src/app/services/core/events.service';
import { CacheService } from 'src/app/services/core/cache.service';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  couponLines: any = null;

  editItemIndex: number;

  requestMinimumValue: number = 0;

  shippingLines: any;

  constructor(
    private cache: CacheService,
    private events: EventsService,
    public userService: UserService,
  ) {
  }

  add(item: any) {
    return new Promise(async (resolve, reject) => {
      this.getBasketStorage()
      .then((basketStorage: basketItem[]) => {
        item.id = item.id || item.uid;
        
        let storageSimilarItemSearch = basketStorage.filter((_item: any) => {
          return item.id === _item.id && (!_item.attributes || !_item.attributes.length);
        });
        let storageSimilarItem = storageSimilarItemSearch[0] || null;

        if(storageSimilarItem) {
          storageSimilarItem.amount++;
          item.amount = storageSimilarItem.amount;
        } else {
          item.amount = parseFloat(item.amount || 1);

          let basketItem = this.toBasketStorageItem(item);
          basketStorage.push(basketItem);
        }

        this.setBasket(basketStorage).then(resolve).catch(reject);
      })
      .catch(reject);
    });
  }

  addCouponLine(couponLine: any) {
    return new Promise((resolve, reject) => {
      this.couponLines = this.couponLines || [];

      let inCouponLines = this.couponLines.filter((couponLine: any) => {
        return couponLine.code == couponLine.code;
      });
  
      if(inCouponLines && inCouponLines.length) {
        reject('Der Gutschein-Code ist bereits genutzt');
      } else {
        this.couponLines.push({
          code: couponLine.code,
        });
        resolve(this.couponLines);
      }
    });
  }

  async calculateBasketInfo(basketStorage = null) {

    let shippingLines = this.getShippingLines() || [];
    
    let basketInfo: basketInfo = {
      items: 0,
      price_a: 0,
      hasRequestMinimumValue: false,
      coupon_lines: this.getCouponLines() || null,
    };

    let shippingLine = shippingLines[0] || null;
    let shippingMethodId = shippingLine ? shippingLine.method_id : null;

    if(shippingMethodId !== 'local_pickup') {
      basketInfo.requestMinimumValue = this.requestMinimumValue;
    }

    basketStorage = basketStorage || (await this.getBasket());
    basketStorage.forEach((basketItem: any) => {
      if(basketItem.price) {
        basketInfo.price_a += parseInt(basketItem.amount || 1) * parseFloat(basketItem.price);
        basketInfo.items += basketItem.amount || 1;
      }
    });

    let customerNote = '';
    let datetime = this.userService.getDeliveryKey('datetime');
    let date = datetime && datetime !== 'sofort' ? moment(datetime, 'DD.MM.YYYY HH:mm') : moment();

    if(datetime) {
      customerNote = date.format('HH:mm');
    } else {
      customerNote = 'sofort';
    }

    if(this.userService.getDeliveryKey('customer_note')) {
      customerNote += ' - ' + this.userService.getDeliveryKey('customer_note');
    }

    if(basketInfo.items) {

      if(basketInfo.requestMinimumValue) {
        basketInfo.hasRequestMinimumValue = basketInfo.price_a >= basketInfo.requestMinimumValue;
        if(!basketInfo.hasRequestMinimumValue) {
          basketInfo.difference = (basketInfo.requestMinimumValue - basketInfo.price_a).toFixed(2);
          basketInfo.price_a = basketInfo.requestMinimumValue;
        }
        basketInfo.requestMinimumValue = parseFloat(basketInfo.requestMinimumValue.toFixed(2));
      }
  
      basketInfo.price_a = parseFloat(basketInfo.price_a.toFixed(2));
      
      let metaData: any = [
        {
          key: '_orddd_lite_timestamp',
          value: date.format('DD MMMM, YYYY'),
        }
      ];

      basketInfo.meta_data = metaData;
    }
    
    basketInfo.customer_note = customerNote;
    basketInfo.billing_address = this.userService.getBillingInformation();

    shippingLines.total = basketInfo.difference || '0';
    basketInfo.shipping_lines = shippingLines;

    if(shippingMethodId === 'local_pickup') {
      basketInfo.billing_address.address_1 = basketInfo.billing_address.address_1 || shippingLine;
    }

    return basketInfo;
  }

  clear() {
    return this.setBasket([]);
  }
  
  getBasket() {
    return this.getBasketStorage();
  }

  getBasketStorage() {
    return new Promise((resolve, reject) => {
      this.cache.get('basket', 60 * 60)
      .then((fromCache: cacheItem) => {
        let storage: any = (fromCache && fromCache.data ? fromCache.data : []);

        if(storage && storage.length) {
          storage.forEach((storageItem: any) => {
            storageItem.id = storageItem.id || storageItem.uid;
          });
        }

        resolve(storage as basketStorage);
      })
      .catch(reject);
    });
  }

  getCouponLines() {
    return this.couponLines;
  }

  getEditItemIndex() {
    return this.editItemIndex;
  }
  
  getShippingLines() {
    return this.shippingLines;
  }

  parseBasketItemMetaData(metaData: any) {
    if(metaData) {
      metaData.forEach((metaDataItem: any) => {
        delete metaDataItem.id;
      });
  
      metaData = metaData.filter((metaDataItem: any) => {
        return metaDataItem.value && (metaDataItem.key.indexOf('_et_') === -1);
      });
  
      return metaData;
    }
  }

  parseItemMetaData(item: any) {
    item.meta_data = item.meta_data || [];
    
    if(item.metaData) {
      Object.keys(item.metaData).forEach((key: string) => {
        let value = item.metaData[key];
        let iInMetaData = null;
        let blInMetaDataSelect = item.meta_data.filter((metaDataItem: any, index: number) => {
          if(metaDataItem.key == key && value !== '') {
            iInMetaData = index;
            return true;
          }
        });
        let blInMetaData = blInMetaDataSelect ? !!blInMetaDataSelect[0] : false;
  
        if(key === 'cutted') {
          value = value ? 'Geschnitten' : 'Nicht geschnitten';
        }

        let metaItemEntry = {
          'key': key,
          'value': value,
        };

        if(!blInMetaData && value !== '') {
          item.meta_data.push(metaItemEntry);
        } else
        if(iInMetaData) {
          item.meta_data[iInMetaData] = metaItemEntry;
        }
        
      });
    }
    return item.meta_data;
  }

  async removeFromBasket(product: any) {
    return new Promise((resolve, reject) => {
      let blDeleted: boolean = false;
      this.getBasketStorage()
      .then((basketStorage: basketItem[]) => {
        basketStorage = basketStorage.filter((basketItem: any, index: number) => {
          if(basketItem.id === product.id) {
            if(basketStorage[index].amount > 1 && !blDeleted) {
              basketStorage[index].amount--;
              product.amount = basketStorage[index].amount;
            } else {
              product.amount = 0;
              blDeleted = true;
              return false;
            }
          }
          return true;
        });
        this.setBasket(basketStorage).then(resolve).catch(reject);
      })
      .catch(reject);
    });
  }

  setBasket(basket: basket) {
    return new Promise((resolve, reject) => {
      this.cache.set('basket', basket)
      .then(async () => {
        this.calculateBasketInfo()
        .then((basketInfo: basketInfo) => {
          this.events.publish('basket:updated', basket);
          this.events.publish('basketInfo:updated', basketInfo);
          resolve(basket);
        })
        .catch(reject);
      })
      .catch(reject);
    });
  }

  setEditItemIndex(index: number) {
    this.editItemIndex = index;
  }

  setShippingLines(shippingLines: any) {
    this.shippingLines = shippingLines;
  }

  toBasketItem(item: any) {
    let metaData: any = this.parseBasketItemMetaData(item.meta_data);

    let _item = {
      id: item.id,
      amount: parseFloat(item.amount || 1),
      name: item.name,
      meta_data: metaData,
      price: item.price,
      sale_price: item.sale_price,
      metaData: item.metaData,
    };

    return _item;
  }

  toBasketStorageItem(item: any) {

    if(item.metaData) {
      item.meta_data = this.parseItemMetaData(item);
    }

    return {
      amount: parseFloat(item.amount || 1),
      attributes: item.attributes,
      description: item.description,
      delivery_time: item.delivery_time,
      id: item.id,
      image: item.image,
      images: item.images,
      items: item.items,
      name: item.name,
      permalink: item.permalink,
      price: item.price,
      sale_price: item.sale_price,
      short_description: item.short_description,
      stock_status: item.stock_status,
      stock_quantity: item.stock_quantity,
      type: item.type,
    };
  }

  async update(oldProduct: product, newProduct: product) {
    return new Promise((resolve, reject) => {
      this.getBasket()
      .then((basketStorage: basketItem[]) => {
        basketStorage.forEach((basketItem: any) => {
          //console.log('match?', JSON.stringify(basketItem) === JSON.stringify(oldProduct));
        });
      })
      .catch(reject);
    });
  }

  updateIndex(index: number, item: basketItem) {
    return new Promise((resolve, reject) => {
      this.getBasket().then((basketStorage: basketItem[]) => {
        if(index) {
          basketStorage[index] = item;
        }
        this.setBasket(basketStorage).then(resolve).catch(reject);
      })
      .catch(reject);
    });
  }
}