import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';

import { IAPError, IAPProduct, IAPQueryCallback, InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';

import { UserService } from 'src/app/services/core/user.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { BasketService } from 'src/app/services/ecommerce/basket.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { TranslationService } from 'src/app/services/core/translation.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  basketProductPrices: any = {
    'de.tie.vip.basic': 1.09,
    'de.app.tie.vip': 1.09,
    'de.app.tie.basic': 4.49,
    'de.app.tie.premium': 9.99,
  };

  productList: any[];

  constructor(
    private basket: BasketService,
    private events: EventsService,
    private platform: Platform,
    private store: InAppPurchase2,
    private tools: ToolsService,
    private loading: LoadingController,
    private modalCtrl: ModalService,
    private translations: TranslationService,
    public userService: UserService,
  ) {

  }
  
  getBasketProduct(productName: string) {
    let productList: any = this.getBasketProductList();
    let select = productList.filter((productListItem: any) => {
      return productListItem.id === productName;
    });
    return (select && select[0] ? select[0] : null);
  }

  getBasketProductList() {
    let productList = this.getProductList();

    if(productList && productList.length) {
      productList.forEach((basketProduct: any) => {
        basketProduct.price = this.basketProductPrices[basketProduct.id];
      });
    }

    return productList;
  }

  getProductList() {
    if(!this.productList) {
      this.productList = [
        {
          id: "de.tie.vip.basic",       // VIP-TICKET (50% off) for BASIC users
          type: this.store.CONSUMABLE,
        },
        {
          id: "de.app.tie.vip",         // NORMAL VIP TICKET
          type: this.store.CONSUMABLE,
        },
        {
          id: "de.app.tie.basic",       // BASIC ABO
          type: this.store.PAID_SUBSCRIPTION,
        },
        {
          id: "de.app.tie.premium",     // PREMIUM ABO
          type: this.store.PAID_SUBSCRIPTION,
        },
      ];
    }
    return this.productList;
  }

  init() {
    let productList = this.getProductList();

    // After platform ready
    this.store.verbosity = this.store.DEBUG;
    this.store.register(productList);

    if(productList && productList.length) {
      productList.forEach((product: any) => {
        this.initProduct(product);
      });
    }

    this.store.error( (err) => {
      console.error('Store Error ' + JSON.stringify(err));
    });

    this.store.ready(() =>  {
      //console.log('purchase: store', 'ready');
    });

    this.store.validator = (product: IAPProduct, callback) => {
      this.validator(product, callback);
    };

    this.store.refresh();
  }

  initProduct(product: any) {
    
    this.store.when(product.id).registered( (product: IAPProduct) => {
      //console.log(product.id,'Registered: ' + JSON.stringify(product));
    });

    this.store.when(product.id).approved(this.onProductApproved);
    this.store.when(product.id).updated(this.onProductUpdated);
    this.store.when(product.id).cancelled(this.onProductCancelled);
    this.store.when(product.id).error(this.onProductError);
  }

  onProductApproved: IAPQueryCallback = (product: IAPProduct) => {
    //console.log('onProductApproved', product);
    this.events.publish('purchase:approved', product);
  }

  onProductCancelled: IAPQueryCallback = (product: IAPProduct) => {
    console.warn('onProductCancelled', product);
    this.events.publish('purchase:cancelled', product);
  }

  onProductError: IAPQueryCallback = (error: IAPError) => {
    console.warn('onProductError', error);
    this.events.publish('purchase:error', error);
  }

  onProductUpdated: IAPQueryCallback = (product: IAPProduct) => {
    //console.log('onProductUpdated', product);
    this.events.publish('purchase:updated', product);
    //this.refreshProduct(product);
  }

  purchase(productName: any = null) {
    return new Promise((resolve, reject) => {
      productName = productName || 'de.app.tie.vip';

      if(this.platform.is('ios')) {
        this.purchaseUsingApple(productName).then(resolve).catch(reject);
      } else
      if(this.platform.is('android')) {
        this.purchaseUsingGoogle(productName).then(resolve).catch(reject);
      } else {
        reject('error_purchase_not_supported');
      }
    });
  }

  purchaseUsingApple(productName: string) {

    if(this.tools.isWeb()) {
      return this.purchaseUsingWebPayments(productName);
    }

    return new Promise(async (resolve, reject) => {
      const loading = await this.loading.create();
      loading.present();

      this.store.order(productName)
      .then(
        (response: any) => {
          loading.dismiss();
          resolve(response);
        },
        (error: any) => {
          loading.dismiss();
          console.warn('in app purchase error', error);
          reject(error.errorMessage || error);
        }
      );
    });
  }

  purchaseUsingGoogle(productName: string) {

    if(this.tools.isWeb()) {
      return this.purchaseUsingWebPayments(productName);
    }

    return new Promise((resolve, reject) => {
      this.store.order(productName)
      .then(
        (response: any) => {
          resolve(response);
        },
        (error: any) => {
          console.warn('in app purchase error', error);
          reject(error.errorMessage || error);
        }
      );
    });
  }
  
  purchaseUsingWebPayments(productName: string)
  {
    return new Promise((resolve, reject) => {
      let product = this.getBasketProduct(productName);
  
      this.translations.get([
        productName
      ])
      .subscribe((translations: any) => {
        this.basket.add({
          uid: product.id,
          name: translations[productName],
          price: product.price,
        })
        .then(() => {
          this.modalCtrl.dismiss();
          this.events.publish('view:basket');
        })
        .catch(reject);
      });
      
    });
  }

  validator(product: IAPProduct, callback) {
    
    //Verify the transaction data 
    const transactionType: string = this.platform.is('ios') ? 'ios' : 'android';
    const transactionReceipt: string = (this.platform.is('ios') ?
      (product.transaction as any).appStoreReceipt :
      (product.transaction as any).receipt);
    
    callback(true, product.transaction);
    //this.store.dispatch(new RefreshUserAction());
    
    //this.showSuccessPurchaseMessage();
    product.finish();

    //console.log('inAppPurchase.validator', JSON.stringify(product));
  }

}
