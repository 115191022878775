import { Component, Input, NgZone, OnInit } from '@angular/core';
import { IonContent } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { RatingsService } from 'src/app/services/social/ratings.service';
import { ReactionsService } from 'src/app/services/social/reactions.service';
import { SharingService } from 'src/app/services/sharing/sharing.service';
import { UserService } from 'src/app/services/core/user.service';
import { WebService } from 'src/app/services/core/web.service';

import * as $ from 'jquery';

@Component({
  selector: 'pipeline-post-footer-video',
  templateUrl: './post-footer-video.component.html',
  styleUrls: ['./post-footer-video.component.scss'],
})
export class PostFooterVideoComponent implements OnInit {
  @Input() content: IonContent;
  @Input() post: any;
  @Input() view: any;

  user: user;

  constructor(
    private events: EventsService,
    private ratings: RatingsService,
    private reactions: ReactionsService,
    private sharing: SharingService,
    public userService: UserService,
    private zone: NgZone,
    private webService: WebService,
  ) {
    this.user = this.userService.getUser() || {};
    this.view = this.view || {};
  
    if(!!(this.user && this.user.uid)) {
      this.view.emojis = this.reactions.getEmojis();
    }

  }

  detectChanges() {
    this.events.publish('post:reader:update', this.post);
  }

  fullscreen() {

  }

  ngOnInit() {
    this.view = this.view || {};

    this.events.subscribe('post:view:updated', (view: any) => {
      this.view = view;
    });
  }

  async rate() {
    
    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    // show rate modal
    this.ratings.rate(this.post)
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  share() {

    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    this.sharing.sharePost(this.post);
  }

  tagFriend() {

    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    this.sharing.internal(this.post)
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  togglePlayVideo() {
    let video = $('.post-page video').first();

    this.zone.run(() => {
      this.view.paused = !video[0].paused;
      if(!video[0].paused) {
        video[0].pause();
      } else {
        video[0].play();
      }

      this.detectChanges();
    });
  }

  toggleVolume() {
    let video = $('.post-page video').first();

    this.zone.run(() => {
      this.view.muted = !this.view.muted;
      if(!video[0].muted) {
        video[0].muted = 'muted';
      } else {
        video[0].muted = false;
      }

      this.detectChanges();
    });
  }

}
