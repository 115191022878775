<ion-header class="ion-no-border" translucent="true">
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="'pick_icon'|translate"></ion-title>
    <ion-buttons slot="end">
      <ion-button
        (click)="toggleSearch()"
        [hidden]="search.visible && search.query && search.query.length"
      >
        <ion-icon
          [name]="search.visible ? 'search' : 'search-outline'"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="ion-no-border" [hidden]="!search.visible">
    <ion-searchbar
      autocorrect="on"
      [(ngModel)]="search.query"
      #searchInput
      id="searchInput"
      [placeholder]="'search_something'|translate"
      (ionInput)="runSearch()"
    ></ion-searchbar>
  </ion-toolbar>
  <ion-toolbar *ngIf="view.iconTypesNames" class="ion-no-border">
    <ion-segment [(ngModel)]="view.iconType" (ionChange)="iconTypeChanged()">
      <ion-segment-button *ngFor="let iconType of view.iconTypesNames; let name = index;" [value]="iconType" icon-only>
        <ion-label [innerHTML]="('icon_style_' + iconType)|translate"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
  <ion-card [hidden]="!view.icon" id="viewIconPreview">
    <ion-card-content>
      <ion-icon [name]="view.icon" *ngIf="view.icon" color="dark"></ion-icon>
    </ion-card-content>
  </ion-card>
</ion-header>

<ion-content fullscreen="true">

  <ion-grid *ngIf="view.ionicons">
    <ion-row>

      <ion-col (click)="select(icon)" [size]="view.colSize" *ngFor="let icon of view.ionicons">
        <ion-icon [name]="icon + view.iconTypes[view.iconType]"></ion-icon>
      </ion-col>

    </ion-row>
  </ion-grid>

</ion-content>

<ion-footer class="ion-no-border" [hidden]="!view.icon">
  <ion-toolbar class="ion-no-border">
    <ion-button size="block" (click)="submit()" color="primary" shape="round">
      <ion-icon name="checkmark-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'choose'|translate"></ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>
