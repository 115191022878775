<ion-content>

  <ion-list lines="none">

    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button icon-only size="block" fill="clear" (click)="toggleSize(false)" color="dark">
            <ion-icon name="text-outline"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button icon-only size="block" fill="clear" (click)="toggleSize(true)" color="dark">
            <ion-icon name="text-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!--
    <ion-item button (click)="toggleLanguage()">
      <ion-icon name="language-outline"></ion-icon>
      <ion-label [innerHTML]="'language'|translate"></ion-label>
    </ion-item>
    -->

    <ion-item button (click)="toggleBionicReading(!view.enableBionicReading)" *ngIf="appConfig.useBionicReadingExtension">
      <ion-icon [name]="view.enableBionicReading ? 'color-wand' : 'color-wand-outline'" slot="start"></ion-icon>
      <ion-label [innerHTML]="'bionic_reading'|translate"></ion-label>
    </ion-item>

    <ion-item button (click)="toggledDarkMode(!view.darkMode)">
      <ion-icon [name]="view.darkMode ? 'moon' : 'moon-outline'" slot="start"></ion-icon>
      <ion-label [innerHTML]="'dark_mode'|translate"></ion-label>
    </ion-item>

    <ion-item>
      <ion-icon name="sunny-outline" slot="start"></ion-icon>
      <ion-range [(ngModel)]="view.brightness" min="1" max="100" step="1" (ionChange)="onBrightnessChanged()"></ion-range>
      <ion-icon name="sunny-outline" slot="end"></ion-icon>
    </ion-item>

  </ion-list>
</ion-content>
