import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/services/core/events.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  blIsModal: boolean = false;

  _modals: any = {};

  constructor(
    private events: EventsService,
    private modalCtrl: ModalController
  ) {
    this.initEvents();
  }

  close(modalKey: string|null = null) {
    let modalKeys = Object.keys(this._modals);
    let lastKey = modalKeys[modalKeys.length-1];

    modalKey = modalKey || lastKey;

    try {
      if(this._modals && this._modals[modalKey]) {
        this._modals[modalKey].dismiss();
      }
    } catch(e) {
      console.warn('modalCtrl: close issue', e);
    }
  }

  async closeAll() {
    try {
      let modalKeys = Object.keys(this._modals);

      modalKeys.forEach(async (modalKey: any, index: number) => {
        await this._modals[modalKey].dismiss();
        delete this._modals[modalKey];
      });

      return true;
    } catch(e) {
      console.warn('modals error', e);
      return false;
    }
  }

  async create(modalOptions: any) {
    modalOptions.componentProps = modalOptions.componentProps || {};
    modalOptions.componentProps.modalCtrl = this;
    
    const modal = await this.modalCtrl.create(modalOptions);

    if(modal.cssClass) {
      this._modals[modal.cssClass as string] = modal;
    }

    return modal;
  }

  dismiss(data: any = null) {
    try {
      return this.modalCtrl.dismiss(data);
    } catch(e) {
      console.warn('> modal dismiss: error', e);
    }
  }

  async getController() {
    return this.modalCtrl;
  }

  async getTop() {
    let top = await this.modalCtrl.getTop();
    return top;
  }

  initEvents() {

    this.events.subscribe('modalCtrl:dismiss', () => {
      this.dismiss();
    });

  }

  isModal() {
    return this.blIsModal;
  }

  setIsModal(bl: boolean) {
    this.blIsModal = bl;
  }

}
