import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { UserService } from 'src/app/services/core/user.service';
import { EventsService } from 'src/app/services/core/events.service';
import { SharingService } from 'src/app/services/sharing/sharing.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { ReportService } from 'src/app/services/extensions/report.service';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.page.html',
  styleUrls: ['./profile-settings.page.scss'],
})
export class ProfileSettingsPage implements OnInit {

  config: pipelineAppConfig;

  profile: any = {};

  user: user;

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private navParams: NavParams,
    private reportService: ReportService,
    private sharing: SharingService,
    public userService: UserService,
  ) {
    this.config = this.configService.getConfig();
    this.user = this.userService.getUser();

    this.profile = this.navParams.get('profile');
    this.profile.me = (this.profile.uid === this.user.uid);
  }

  blockProfile(profile: any = null) {
    profile = profile || this.profile;
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser();
  }
  
  ngOnInit() {
    this.profile.me = (this.profile.uid === this.user.uid);
  }

  requestHelp(profile: any = null) {
    profile = JSON.parse(JSON.stringify(profile || this.profile));
    profile.type = 'profile';

    this.reportService.create({
      item: profile,
      type: 'profile',
    })
    .then(() => {

    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  shareProfile(profile: any = null) {
    profile = profile || this.profile;
    this.sharing.shareProfile(profile);
  }

  updateProfile() {
    if(this.profile.uid === this.user.uid) {
      this.userService.setUser(this.profile, true)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }
  }

  updatePublic() {
    this.updateProfile();
  }

}
