<ion-header class="ion-no-border" translucent="true">
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
          <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="'configure_collection'|translate"></ion-title>
    <ion-buttons slot="end">

      <ion-button (click)="updateCollection()" icon-only>
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
      </ion-button>

    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen="true">

  <ion-header collapse="condense" class="ion-no-border">
    <ion-toolbar class="ion-no-border">
      <ion-title size="large" [innerHTML]="'configure_collection'|translate"></ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-card>
    <ion-list lines="none">
      <ion-item>
        <ion-checkbox slot="start" [(ngModel)]="settings.public"></ion-checkbox>
        <ion-label [innerHTML]="'public_visible'|translate"></ion-label>
      </ion-item>
  
      <ion-item button (click)="configureIcon()">
        <ion-icon slot="start" [name]="collection.icon"></ion-icon>
        <ion-label [innerHTML]="'configure_icon'|translate"></ion-label>
      </ion-item>
  
    </ion-list>
  </ion-card>

  <ion-button size="block" fill="clear" color="danger" (click)="deleteCollection()">
    <ion-icon slot="start" name="trash-outline"></ion-icon>
    <ion-label [innerHTML]="'delete_collection'|translate"></ion-label>
  </ion-button>

</ion-content>
