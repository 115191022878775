<ion-header>
  <ion-toolbar class="container">

    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <ion-toolbar class="headerItem container">
    <h2 [class.large]="!view.canBack" [innerHTML]="survey.title|translate"></h2>
  </ion-toolbar>

</ion-header>

<ion-content>

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>
  
  <swiper-container *ngIf="!!(survey.slides && survey.slides.length)" [navigation]="true" [pagination]="true" [config]="surveySliderOptions">

    <swiper-slide *ngIf="!!survey.description && !!survey.description.length">
      <div class="container">

        <ion-card>
          <ion-card-content>
            <p [innerHTML]="survey.description"></p>
          </ion-card-content>
        </ion-card>
      
      </div>
    </swiper-slide>

    <swiper-slide *ngFor="let slide of survey.slides">

      <div class="container">

        <ion-card *ngFor="let question of slide.questions">
          <ion-item lines="none">
            <ion-checkbox [(ngModel)]="question.answer" slot="start" *ngIf="question.value === 'checkbox'" (ionChange)="validateUI()"></ion-checkbox>
            <ion-radio [(ngModel)]="question.answer" slot="start" *ngIf="question.value === 'radio'" (ionChange)="validateUI()"></ion-radio>
    
            <ion-label [innerHTML]="question.title" class="ion-text-wrap" position="floating" *ngIf="question.value === 'email' || question.value === 'text' || question.value === 'textarea' || question.type === 'input'"></ion-label>
            <ion-label [innerHTML]="question.title" class="ion-text-wrap" *ngIf="question.value !== 'email' && question.value !== 'text' && question.value !== 'textarea' && question.type !== 'input'"></ion-label>
    
            <ion-input [type]="question.value" [placeholder]="question.title" [(ngModel)]="question.answer" *ngIf="question.value === 'email' || question.value === 'text'" (ionChange)="validateUI()"></ion-input>
            <ion-textarea type="text" [placeholder]="question.title" [(ngModel)]="question.answer" *ngIf="question.type === 'input' || question.value === 'textarea'" (ionChange)="validateUI()"></ion-textarea>
          </ion-item>
        </ion-card>

      </div>
      
    </swiper-slide>

    <div class="swiper-pagination"></div>
  </swiper-container>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">
    <ion-grid>
      <ion-row>
        <ion-col [hidden]="!view.canBack">
          <ion-button (click)="prev()" size="block" fill="clear" color="dark">
            <ion-icon name="arrow-back" slot="start"></ion-icon>
            <ion-label [innerHTML]="'back'|translate"></ion-label>
          </ion-button>
        </ion-col>
        <ion-col [hidden]="view.canSubmit">
          <ion-button (click)="next()" size="block" color="primary" [disabled]="!view.canNext">
            <ion-label [innerHTML]="'forward'|translate"></ion-label>
            <ion-icon name="arrow-forward" slot="end"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col [hidden]="!view.canSubmit">
          <ion-button (click)="submit()" size="block" color="primary">
            <ion-icon name="checkmark-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'submit'|translate"></ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>