import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ModalService } from 'src/app/services/core/modal.service';

import { CouponDetailsPage } from 'src/app/pages/coupons/coupon-details/coupon-details.page';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {

  constructor(
    private AppCMS: AppcmsService,
    private modalService: ModalService,
  ) {
  }

  getActive(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.get('coupons', options, blForceRefresh);
    //return this.AppCMS.loadPluginData('coupons', options, null, null, blForceRefresh);
  }

  getAll(options: any = {}, blForceRefresh: boolean = false) {
    return this.getActive(options, blForceRefresh);  
  }

  getByUid(uid: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('coupons', {}, [uid], null, blForceRefresh);
  }

  getSuggestions(options: any = {}, blForceRefresh: boolean = false) {
    return this.getAll(options, blForceRefresh);
  }

  parseCoupon(coupon: coupon) {
    coupon.end_date_formatted = coupon.end_date_formatted || moment(coupon.end_date).format('DD.MM.Y HH:mm');
    coupon.start_date_formatted = coupon.start_date_formatted || moment(coupon.start_date).format('DD.MM.Y HH:mm');

    return coupon;
  }

  async view(coupon: coupon) {

    const modal = await this.modalService.create({
      component: CouponDetailsPage,
      componentProps: {
        coupon: coupon,
        couponsService: this,
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: 'pageModal'
    });

    await modal.present();

  }

}
