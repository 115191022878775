import { Injectable } from '@angular/core';
import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  get(filter: any = {}, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('stores', Object.assign(options, {
      filter: filter,
    }), [], null, blForceRefresh);
  }

  getByUid(storeId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('stores', {}, [storeId], null, blForceRefresh);
  }

  search(query: string, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('stores', {
      query: query,
    }, ['search'], null, blForceRefresh);
  }

}
