<ion-header translucent="true" class="ion-no-border">
  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="'pick_story'|translate"></ion-title>
  </ion-toolbar>
  <ion-toolbar class="container">
    <ion-segment scrollable *ngIf="view.collectionKeys" [(ngModel)]="view.collection" (ionChange)="onCollectionChanged">
      <ion-segment-button *ngFor="let collectionKey of view.collectionKeys" [value]="collectionKey">
        <ion-label [innerHTML]="collectionKey|translate"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>

  <ion-toolbar class="ion-no-border" [hidden]="view.collection === 'camera'">
    <ion-searchbar #pickStorySearchQuery [placeholder]="'search_something'|translate" (ionInput)="runSearch()" [(ngModel)]="search.query"></ion-searchbar>
  </ion-toolbar>

</ion-header>

<ion-content>

  <pipeline-camera [hidden]="view.collection !== 'camera'"></pipeline-camera>

  <ion-grid [hidden]="view.collection === 'camera'">

    <ion-row *ngIf="view.collections[this.view.collection] && view.collections[this.view.collection].length">
      <ion-col>
        <ion-spinner name="circular" *ngIf="!view.collections[this.view.collection]"></ion-spinner>

        <pipeline-no-entries-card *ngIf="(view.collections[this.view.collection] && !view.collections[this.view.collection].length) || (view.collections[this.view.collection] && view.collections[this.view.collection].length === 1 && (!view.collections[this.view.collection][0].col || (view.collections[this.view.collection][0].cols && !view.collections[this.view.collection][0].cols.length)))"></pipeline-no-entries-card>
        
      </ion-col>
    </ion-row>

    <ion-row>

      <ion-col [size]="col.size || 12" class="col-size-{{col.size || 12}}"
        *ngFor="let col of view.collections[this.view.collection]" [hidden]="col.hidden">

        <ion-card [class.person]="col.type === 'person'">

          <ion-item lines="none" (click)="onColClick(col, $event)">

            <ion-avatar slot="start" *ngIf="!!col.avatar">
              <ion-img [src]="col.avatar" *ngIf="!!col.avatar"></ion-img>
              <ion-skeleton-text [hidden]="!!col.avatar" class="image" [animated]="!!col.name"></ion-skeleton-text>
            </ion-avatar>

            <ion-thumbnail slot="start" *ngIf="!col.avatar">
              <ion-img [src]="col.photo" *ngIf="!!col.photo"></ion-img>
              <ion-skeleton-text [hidden]="!!col.photo" class="image" [animated]="!!col.name"></ion-skeleton-text>
            </ion-thumbnail>

            <ion-label class="ion-text-wrap">
              <h2 *ngIf="col.name || col.host" [innerHTML]="col.name || col.host"></h2>
              <h4 *ngIf="col.subTitle" [innerHTML]="col.subTitle"></h4>
              <p *ngIf="col.excerpt && col.excerpt.length > 20" [innerHTML]="col.excerpt + '...'"></p>

              <ion-skeleton-text [hidden]="!!col.name" style="width: 30%; float: left; margin-right: 10px;" animated></ion-skeleton-text>
              <ion-skeleton-text [hidden]="!!col.name" style="width: 50%; float: left;" animated></ion-skeleton-text>
            </ion-label>

          </ion-item>

          <ion-fab class="vipBadge" horizontal="end" *ngIf="col.vip" [vertical]="col.host ? 'top' : 'bottom'"
            [edge]="col.host">
            <ion-fab-button color="warning" size="small">
              <ion-icon name="lock-closed"></ion-icon>
            </ion-fab-button>
          </ion-fab>

        </ion-card>

      </ion-col>

    </ion-row>

  </ion-grid>

</ion-content>