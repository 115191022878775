import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { AccountsService } from 'src/app/services/core/accounts.service';
import { BrowserService } from 'src/app/services/utils/browser.service';
import { ClipboardService } from 'src/app/services/utils/clipboard.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { UserService } from 'src/app/services/core/user.service';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
  selector: 'app-coupon-details',
  templateUrl: './coupon-details.page.html',
  styleUrls: ['./coupon-details.page.scss'],
})
export class CouponDetailsPage implements OnInit {

  config: pipelineAppConfig;

  coupon: coupon = {};

  couponsService: any;
  
  googleMapConfig: googleMapConfig = {
    id: 'eventMap',
    classes: 'eventMap map',
    data: [],
    size: {
      height: (window.innerWidth * 0.5),
      width: window.innerWidth,
    },
    sources: ['calendar'],
  };

  viewData: any = {
    external: true,
    placeholders: [{},{},{},{},{},{},{},{}],
    segment: 'details',
  };

  constructor(
    private accounts: AccountsService,
    private browser: BrowserService,
    private clipboard: ClipboardService,
    private configService: ConfigService,
    private events: EventsService,
    private modalService: ModalService,
    private navParams: NavParams,
    private userService: UserService,
  ) {
    this.config = this.configService.getConfig();
    
    this.couponsService = this.navParams.get('couponsService');
    this.coupon = this.couponsService.parseCoupon(this.navParams.get('coupon'));
  }

  copyCode() {
    this.clipboard.copyText(this.coupon.indent)
    .then(() => {
      this.viewData.copiedCode = true;
    })
    .catch((error: any) => {
      this.viewData.copiedCode = false;

      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  dismiss() {
    this.modalService.dismiss();
  }

  initEvents() {
    this.events.subscribe('appcms:user:updated', () => {
      this.viewData.isLoggedIn = this.userService.isLoggedIn();
    });
  }

  ngOnInit() {
    this.viewData.isLoggedIn = this.userService.isLoggedIn();

    this.initEvents();
  }

  showCouponCode() {
    this.viewData.blShowCouponCode = true;
  }

  showLoginModal() {
    this.accounts.switch();
  }

  toggleCollection() {

  }

  viewCoupon() {
    if(!!this.coupon.link) {
      this.browser.create(this.coupon.link);
    }
  }

}
