import { Component, NgZone, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';

@Component({
  selector: 'app-collection-picker',
  templateUrl: './collection-picker.page.html',
  styleUrls: ['./collection-picker.page.scss'],
})
export class CollectionPickerPage implements OnInit {

  collections: any;

  collectionsData: any;

  icons: any = {
    people: 'people-outline',
    posts: 'newspaper-outline',
    products: 'shopping-bag-outline',
  };

  previewImages: any[] = [

  ];

  view: any = {
    canSave: false,
    colSize: 6,
    selectedCollectionName: '',
  };

  constructor(
    private events: EventsService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private zone: NgZone,
  ) {
    this.collections = this.navParams.get('collectionsService');
    this.view.type = this.navParams.get('type');

    this.loadCollections();
  }

  createCollection() {
    this.collections.showCreateFolderDialog()
    .then((response: any) => {
      this.doRefresh();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
      this.doRefresh();
    });
  }

  dismiss(data: any = null) {
    this.modalCtrl.dismiss(data);
  }

  doRefresh(event: any = null) {
    this.loadCollections(true)
    .then(() => {
      if(event) {
        event.target.complete();
      }
    })
    .catch((error: any) => {
      if(event) {
        event.target.complete();
      }
      this.events.publish('error', error);
    });
  }

  ionViewWillEnter() {
    //console.log('this.view', this.view);
  }

  loadCollections(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.collections.getCustomCollectionNames(null, blForceRefresh)
      .then((customCollectionItems: collectionItem[]) => {
        
        customCollectionItems = (
          [
            {
              name: this.view.type,
              type: this.view.type,
            },
          ] as collectionItem[]
        ).concat(customCollectionItems);

        this.collectionsData = customCollectionItems;

        this.loadCollectionPreviewImages();
        resolve(this.collectionsData);
      })
      .catch(reject);
    });
  }

  loadCollectionPreviewImages() {

  }

  ngOnInit() {
  }

  save() {
    this.dismiss({
      collection: this.view.selectedCollectionName,
    });
  }

  use(collection: collectionItem) {
    this.zone.run(() => {
      this.view.selectedCollectionName = collection.name;
      this.view.canSave = true;
    });
  }

}
