<ion-footer
  [style.bottom]="view.footer.bottom + 'px'"
  class="ion-no-border"
  translucent="true"
  [hidden]="(post && post.vip) || view.isPreviewMode || view.mode !== 'video'"
>

  <pipeline-reactions-toolbar [(post)]="post" type="post" [hidden]="!post || post.vip"></pipeline-reactions-toolbar>

  <ion-toolbar class="ion-no-border">
    <ion-buttons class="five-cols" slot="start">
      <ion-button
        icon-only
        fill="clear"
        (click)="togglePlayVideo()"
        [color]="post.playing ? 'danger' : 'dark'"
      >
        <ion-icon
          [name]="!view.paused ? 'pause-outline' : 'play-outline'"
        ></ion-icon>
      </ion-button>
      <ion-button
        icon-only
        fill="clear"
        (click)="toggleVolume()"
        [color]="post.playing ? 'danger' : 'dark'"
      >
        <ion-icon
          [name]="view.muted ? 'volume-mute-outline' : 'volume-high-outline'"
        ></ion-icon>
      </ion-button>
      <ion-button icon-only fill="clear" (click)="fullscreen()" color="dark">
        <ion-icon name="expand-outline"></ion-icon>
      </ion-button>
      <ion-button icon-only fill="clear" (click)="rate()" color="dark">
        <ion-icon name="flag-outline"></ion-icon>
      </ion-button>
      <ion-button icon-only fill="clear" (click)="tagFriend()" color="dark">
        <ion-icon name="share-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>