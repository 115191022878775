import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { BrowserService } from 'src/app/services/utils/browser.service';
import { TranslationService } from 'src/app/services/core/translation.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(
    private alertCtrl: AlertController,
    private browser: BrowserService,
    private translations: TranslationService,
  ) {

  }

  downloadAndroid() {
    this.browser.create('https://play.google.com/store/apps/details?id=de.app.tie');
  }

  downloadApp() {
    this.translations.get([
      'cancel',
      'download_app_header',
      'download_android',
      'download_ios',
    ])
    .subscribe(async (translations: any) => {
      const alert = await this.alertCtrl.create({
        header: translations.download_app_header || 'download_app_header',
        buttons: [
          {
            text: translations.download_android || 'download_android',
            handler: () => {
              this.downloadAndroid();
            },
          },
          {
            text: translations.download_ios || 'download_ios',
            handler: () => {
              this.downloadIos();
            },
          },
          {
            text: translations.cancel || 'cancel',
            role: 'cancel',
          },
        ]
      });
      alert.present();
    });
  }

  downloadIos() {
    this.browser.create('https://apps.apple.com/us/app/pipeline/id1525263609');
  }

}
