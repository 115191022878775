import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { MessagesService } from 'src/app/services/social/messages.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class ReactionsService {

  emojiList: emoji[] = [
    {
      name: 'heart',
      emoji: '💜',
    },
    {
      name: 'party',
      emoji: '🥳',
    },
    {
      name: 'love',
      emoji: '😍',
    },
    {
      name: 'laught',
      emoji: '😂',
    },
    {
      name: 'cool',
      emoji: '😎',
    },
    {
      name: 'rocks',
      emoji: '🤘',
    },
    {
      name: 'lie',
      emoji: '🤥',
    },
    {
      name: 'shock',
      emoji: '😨',
    },
  ];

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private messages: MessagesService,
    private tools: ToolsService,
    private translations: TranslationService,
    public userService: UserService,
  ) {

  }

  get(options: reactionOptions) {
    //console.log('reactions: options', options);
  }

  async getDislikedUids() {
    let fromCache: cacheItem = (await this.cache.get('pipeline_disliked_uids', -1));
    return fromCache && fromCache.data ? fromCache.data : [];
  }

  getEmojis() {
    return this.emojiList;
  }

  async getLikedUids() {
    let fromCache: cacheItem = (await this.cache.get('pipeline_liked_uids', -1));
    return fromCache && fromCache.data ? fromCache.data : [];
  }


  getStageHtml(reaction: reaction) {
    let stage = '', stageElements = [], i = 0;
    
    while(i <= 10) {
      i++;
      stageElements.push('<span id="item' + i + '">' + reaction.emoji + '</span>');
    }

    stageElements = this.tools.shuffle(stageElements);
    stageElements.forEach((el: string) => {
      stage += el;
    });
    
    return stage;
  }

  reactOnPost(reaction: reaction) {
    reaction = JSON.parse(JSON.stringify(reaction));

    return this.AppCMS.loadPluginData('pipeline', {
      reaction: reaction,
      user: this.userService.getUid(),
    }, ['reactions', 'reactOnPost']);
  }

  send(reaction: reaction) {
    reaction = JSON.parse(JSON.stringify(reaction));
    
    return new Promise((resolve, reject) => {
      this.translations.get([
        'user_likes_your_message',
      ])
      .subscribe((translations: any) => {
        let user = this.userService.getUser() || {};

        if(reaction.partner) {
          this.messages.sendPushToFriend(reaction.partner, {
            description: translations.user_likes_your_message.replace(/\$ /g, '$').replace('$user', (user.firstname || user.lastname ? (user.firstname + ' ' + user.lastname) : user.nickname)),
          })
          .catch((error: any) => {
            console.warn('push send error', error);
          });
        }

        delete reaction.partner;

        this.AppCMS.loadPluginData('pipeline', {
          reaction: reaction,
          user: this.userService.getUid(),
        }, ['reactions', 'send'])
        .then(resolve)
        .catch(reject);
      });
    });
  }

  setDislikedUids(uids: number[]) {
    return this.cache.set('pipeline_disliked_uids', uids);
  }

  setLikedUids(uids: number[]) {
    return this.cache.set('pipeline_liked_uids', uids);
  }

}
