import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Platform } from '@ionic/angular';

import { CameraPreview, CameraPreviewPictureOptions, CameraPreviewOptions } from '@awesome-cordova-plugins/camera-preview/ngx';

import { EventsService } from 'src/app/services/core/events.service';

@Component({
  selector: 'pipeline-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CameraComponent implements OnInit {

  cameraPreviewOpts: CameraPreviewOptions = {
    x: 0,
    y: 135,
    width: window.screen.width,
    height: window.screen.height * 0.725,
    camera: 'back',
    disableExifHeaderStripping: true,
    tapFocus: true,
    tapPhoto: false,
    previewDrag: false,
    storeToFile: false,
    toBack: true,
    alpha: 1
  };

  picture: string|null;

  pictureOpts: CameraPreviewPictureOptions = {
    //width: 1280,
    //height: 1280,
    
    //width: window.screen.width,
    //height: window.screen.height,

    width: window.screen.width,
    height: window.screen.height * 0.725,

    quality: 95,
  };

  view: any = {
    error: false,
    pictures: [],
    zoom: 1,
    zoomIndex: 0,
    zoomScales: [1,2,3],
  }

  constructor(
    private cameraPreview: CameraPreview,
    private events: EventsService,
    private platform: Platform,
  ) {

  }

  choosePicture() {
    this.view.pictures.push(this.picture);
    this.deletePicture();
  }

  deletePicture() {
    this.picture = null;
    this.showCamera();
  }

  deletePictureFromList(picture: string, index: number) {
    this.view.pictures = this.view.pictures.filter((_picture: string, _index: number) => {
      return index !== _index;
    });
  }

  hideCamera() {
    try {
      this.cameraPreview.hide().catch((e) => {
        console.warn('hide camera error (2)', e);
      });
    } catch(e) {
      console.warn('hide camera error (1)', e);
    }
  }

  initEvents() {
    this.events.subscribe('camera:hide', () => {
      this.hideCamera();
    });
    this.events.subscribe('camera:show', () => {
      this.showCamera();
    });
    this.events.subscribe('camera:start', () => {
      this.startCamera();
    });
    this.events.subscribe('camera:stop', () => {
      this.stopCamera();
    });
  }

  ngOnInit() {
    this.initEvents();

    this.platform.ready().then(() => {
      this.startCamera();
    });
  }

  onPictureGridImageClick(picture: string) {
    this.picture = picture;
  }

  setColorEffect(effect: string = 'negative') {
    try {
      this.cameraPreview.setColorEffect(effect).catch((e) => {
        console.warn('set color effect error (2)', e);
      });
    } catch(e) {
      console.warn('set color effect error (1)', e);
    }
  }

  setZoom(zoom: number) {
    try {
      this.cameraPreview.setZoom(zoom).catch((e) => {
        console.warn('set zoom error (2)', e);
      });
    } catch(e) {
      console.warn('set zoom error (1)', e);
    }
  }

  showCamera() {
    try {
      this.cameraPreview.show().catch((e) => {
        console.warn('show camera error (2)', e);
      });
    } catch(e) {
      console.warn('show camera error (1)', e);
    }
  }

  startCamera() {
    this.view.error = false;
    this.view.cameraActive = true;

    try {
      this.cameraPreview.startCamera(this.cameraPreviewOpts).then(
        (res) => {
          this.view.cameraActive = true;
          this.setZoom(this.view.zoom);
        },
        (err) => {
          this.view.error = 'error_failed_to_init_camera';
          this.view.cameraActive = false;
          console.log('e1', err);
        })
        .catch((err: any) => {
          this.view.error = 'error_failed_to_init_camera';
          this.view.cameraActive = false;
          console.log('e2', err);
        });
    } catch (e) {
      this.view.error = 'error_failed_to_init_camera';
      this.view.cameraActive = false;
      console.warn('start camera error', e);
    }

    /*
    try {
      this.cameraPreview.setOnPictureTakenHandler().subscribe((result) => {
        console.log(result);
        // do something with the result
      });
    } catch (e) {
      console.warn('setOnPictureTakenHandler error', e);
    }
    */

  }

  stopCamera() {
    this.view.cameraActive = false;

    try {
      this.cameraPreview.stopCamera()
      .catch((e) => {
        console.warn('stop camera error (2)', e);
      });
    } catch(e) {
      console.warn('stop camera error (1)', e);
    }
  }

  switchCamera() {
    this.cameraPreview.switchCamera();
  }

  takePicture() {
    this.cameraPreview.takePicture(this.pictureOpts).then((imageData) => {
      this.picture = 'data:image/jpeg;base64,' + imageData;
      this.hideCamera();
    }, (err) => {
      console.log(err);
      this.picture = 'assets/img/test.jpg';
    });
  }

  takeSnapshot() {
    this.cameraPreview.takeSnapshot(this.pictureOpts).then((imageData) => {
      this.picture = 'data:image/jpeg;base64,' + imageData;
      this.hideCamera();
    }, (err) => {
      console.log(err);
      this.picture = 'assets/img/test.jpg';
    });
  }

  toggleZoom() {
    this.view.zoomIndex = ((this.view.zoomIndex >= 2) ? 0 : this.view.zoomIndex+1);

    if(!!this.view.zoomScales[this.view.zoomIndex]) {
      this.view.zoom = this.view.zoomScales[this.view.zoomIndex];
      this.setZoom(this.view.zoom);
    }
  }

}
