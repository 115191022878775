export var hostUrl: string = 'https://api-services.dpolg.app';

export var pipelineAppConfig: pipelineAppConfig = {
    allowedTeamUids: [6],
    allowUserCreateChat: false,
    allowUserCreateChatGroup: false,
    allowUserEditPostSettings: false,
    allowUserForgotPassword: false,
    allowUserLogin: false,
    allowUserPostContent: false,
    allowUserPostContentAsCreator: false,
    allowUserPostContentAsGuest: false,
    allowUserPostContentAsUser: false,
    allowUserRegister: false,
    allowUserTranslatePost: false,
    allowUserWriteChatMessage: false,
    appDescription: 'DPolG',
    appId: 'de.jungepolizei.dpolg',
    appName: 'DPolG',
    appPackageName: 'dpolg-pipeline-app',
    appPages: [],
    appSlogan: 'DPolG',
    appVersionId: 101001,
    appVersionName: '10.10.1',
    defaultLanguage: 'de',
    environments: {
        api: {
            local: 'https://api-services.dpolg.local/api',
            production: 'https://api-services.dpolg.app/api',
            staging: 'https://staging.api-services.dpolg.app/api',
            testing: 'https://testing.api-services.dpolg.app/api',
        },
        app: {
            local: 'https://web.dpolg.local',
            production: 'https://web.dpolg.app',
            staging: 'https://staging.web.dpolg.app',
            testing: 'https://testing.web.dpolg.app',
        }
    },
    filterDiscoverPostsByContentHostUrl: false,
    filterHomePostsByContentHostUrl: true,
    filterLocalPostsByContentHostUrl: false,
    filterPeopleByContentHostUrl: true,
    filterSearchResultsByContentHostUrl: true,
    filterUserByTeamUids: true,
    footerBottomText: `<p>
        Erstellt mit <a href="https://dpolg.app" target="_blank">pipeline</a><br>
        &copy; ${new Date().getFullYear()} pipeline App GmbH<br><br>
        <a href="https://dpolg.app/de/agb" target="_blank">AGB</a><br>
        <a href="https://dpolg.app/de/datenschutz" target="_blank">Datenschutzerklärung</a>
    </p>`,
    forceUserApprovalOnCreateChatGroup: true,
    forceUserApprovalOnJoinGroup: true,
    forceUserApprovalOnPublishShiftplan: true,
    forceUserApprovalOnRegistration: true,
    headerSearchPosition: 'right',
    includeRatingsInFeed: false,
    includeReactionsInFeed: true,
    includeRelatedArticlesInFeed: false,
    isWhitelabel: true,
    launcherSettings: {
        links: [
            {
                name: 'EM Tippspiel',
                photo: './assets/img/dpolg/card_img/icon_em.jpeg',
                uid: 'em',
                url: 'https://www.dpolg.de/aktuelles/news/em-kicktipp-mit-top-preisen/',
                usePhotoAsBackground: true,
            },
            {
                name: 'catalog_of_facts',
                photo: './assets/img/dpolg/card_img/tatbestandkatalog_2x.jpg',
                uid: 'catalog_of_facts',
                url: '/tabs/dpolg/catalog_of_facts',
                usePhotoAsBackground: true,
            },
            {
                name: 'shop',
                photo: './assets/img/dpolg/card_img/shop_2x.jpg',
                uid: 'shop',
                url: '/tabs/shop',
                usePhotoAsBackground: true,
            },
            {
                name: 'hazardous_substances',
                photo: './assets/img/dpolg/card_img/gefahrstoffe_2x.jpg',
                uid: 'hazardous_substances',
                url: '/tabs/dpolg/hazardous_substances',
                usePhotoAsBackground: true,
            },
            {
                name: 'abbreviations',
                photo: './assets/img/dpolg/card_img/abkuerzungen_2x.jpg',
                uid: 'abbreviations',
                url: '/tabs/dpolg/abbreviations',
                usePhotoAsBackground: true,
            },
            {
                name: 'medical_care',
                photo: './assets/img/dpolg/card_img/heilfuersorge_2x.jpg',
                uid: 'medical_care',
                url: '/tabs/media/documents/folder/44',
                usePhotoAsBackground: true,
            },
            {
                name: 'weapons_overview',
                photo: './assets/img/dpolg/card_img/waffenuebersicht_2x.jpg',
                uid: 'weapons_overview',
                url: '/tabs/dpolg/weapons_overview',
                usePhotoAsBackground: true,
            },
            {
                name: 'narcotics',
                photo: './assets/img/dpolg/card_img/betaeubungsmittel_2x.jpg',
                uid: 'narcotics',
                url: '/tabs/dpolg/narcotics',
                usePhotoAsBackground: true,
            },
            {
                name: 'licence_plates',
                photo: './assets/img/dpolg/card_img/kefz_kennzeichen_2x.jpg',
                uid: 'licence_plates',
                url: '/tabs/dpolg/licence_plates',
                usePhotoAsBackground: true,
            },
            {
                name: 'traffic_signs',
                photo: './assets/img/dpolg/card_img/verkehrszeichen_2x.jpg',
                uid: 'traffic_signs',
                url: '/tabs/dpolg/traffic_signs',
                usePhotoAsBackground: true,
            },
            {
                name: 'youth_protection',
                photo: './assets/img/dpolg/card_img/jugendschutz_2x.jpg',
                uid: 'youth_protection',
                url: '/tabs/dpolg/youth_protection',
                usePhotoAsBackground: true,
            },
            {
                name: 'first_aid',
                photo: './assets/img/dpolg/card_img/erste_hilfe_2x.jpg',
                uid: 'first_aid',
                url: '/tabs/dpolg/first_aid',
                usePhotoAsBackground: true,
            },
            {
                name: 'dpolg_gift_shop',
                photo: './assets/img/dpolg/card_img/vorteilswelt_2x.jpg',
                uid: 'dpolg_gift_shop',
                url: 'https://www.dbb-vorteilswelt.de/',
                usePhotoAsBackground: true,
            },
            {
                name: 'job_exchange',
                photo: './assets/img/dpolg/card_img/stellentausch_2x.jpg',
                uid: 'job_exchange',
                url: 'http://dpolg-bpolg.de/tauschboerse/',
                usePhotoAsBackground: true,
            },
            /*
            {
                name: 'insurance_traffic_signs',
                photo: './assets/img/dpolg/card_img/versicherungskennzeichen.png',
                uid: 'insurance_traffic_signs',
                url: '/tabs/dpolg/insurance_traffic_signs',
            },
            */
            {
                name: 'driving_licence',
                photo: './assets/img/dpolg/card_img/fahrerlaubnis_2x.jpg',
                uid: 'driving_licence',
                url: '/tabs/dpolg/driving_licence',
                usePhotoAsBackground: true,
            },
            {
                name: 'deu_eng',
                photo: './assets/img/dpolg/card_img/uebersetzer_2x.jpg',
                uid: 'deu_eng',
                url: '/tabs/ai/translator',
                usePhotoAsBackground: true,
            },
            {
                name: 'main_inspection',
                photo: './assets/img/dpolg/card_img/hu_2x.jpg',
                uid: 'main_inspection',
                url: '/tabs/dpolg/main_inspection',
                usePhotoAsBackground: true,
            },
            /*
            {
                disabled: true,
                name: 'railroad_police',
                icon: 'train-outline',
                //photo: './assets/img/dpolg/card_img/bahnpolizei.png',
                uid: 'railroad_police',
                url: '/tabs/dpolg/railroad_police',
                usePhotoAsBackground: true,
            },
            {
                disabled: true,
                name: 'residence_calculator',
                icon: 'calculator-outline',
                //photo: './assets/img/dpolg/card_img/heilfuersorge.png',
                uid: 'residence_calculator',
                url: '',
                usePhotoAsBackground: true,
            },
            {
                disabled: true,
                name: 'staff_council_elections',
                icon: 'document-text-outline',
                //photo: './assets/img/dpolg/card_img/heilfuersorge.png',
                uid: 'staff_council_elections',
                url: '/tabs/media/documents/folder/45',
                usePhotoAsBackground: true,
            },
            {
                disabled: true,
                name: 'air_security',
                icon: 'airplane-outline',
                //photo: './assets/img/dpolg/card_img/luftsicherheit.png',
                uid: 'air_security',
                url: '/tabs/dpolg/air_security',
                usePhotoAsBackground: true,
            },
            */
            {
                name: 'become_a_member',
                photo: './assets/img/dpolg/card_img/mitglied_werden_2x.jpg',
                //photo: './assets/img/dpolg/card_img/heilfuersorge.png',
                uid: 'become_a_member',
                url: 'https://www.dpolg-bundespolizei.de/mitgliedschaft/online-beitritt/',
                usePhotoAsBackground: true,
            },
            {
                name: 'favourites',
                photo: './assets/img/dpolg/card_img/favoriten_2x.jpg',
                uid: 'favourites',
                url: '/tabs/dpolg/favourites',
                usePhotoAsBackground: true,
            },
            /*
            {
                name: 'imprint',
                photo: './assets/img/dpolg/card_img/impressum.png',
                uid: 'imprint',
                url: 'https://www.dpolg-markt-select.de/l/contact',
                usePhotoAsBackground: true,
            },
            */
            {
                name: 'feedback',
                photo: './assets/img/dpolg/card_img/feedback_2x.jpg',
                uid: 'feedback',
                url: 'https://forms.office.com/e/Px7MT72TWX',
                usePhotoAsBackground: true,
            },
            /*
            {
                disabled: true,
                name: 'settings',
                photo: './assets/img/dpolg/card_img/einstellungen.png',
                uid: 'settings',
                url: '/tabs/settings',
                usePhotoAsBackground: true,
            },
            */
        ]
    },
    links: {
        imprint: 'https://www.dpolg-markt-select.de/l/contact',
        privacy_policy: 'https://www.dpolg-markt-select.de/l/privacy',
        terms_of_use: 'https://www.dpolg-markt-select.de/l/tac',
    },
    loginHeadlineText: 'Willkommen in der DPolG App',
    maxPostAge: 365,
    menuPages: [
        /*
        {
            uid: 'groups',
            icon: 'people-outline',
            key: 'groups',
            name: 'groups',
            route: '/tabs/groups',
            url: '/tabs/groups',
        },
        */
    ],
    menuSideDesktop: 'start',
    menuSideMobile: 'start',
    multiLanguageSupport: true,
    multiUser: true,
    orderBy: {
        discover: 'post_date_gmt desc',
        home: 'post_date_gmt desc',
        local: 'post_date_gmt desc',
        people: 'RAND()',
        shop: 'name',
        showroom: 'post_date_gmt desc',
    },
    postsVipFactor: 0,
    pushAppId: 'ac05c119-5059-4f81-b614-7ea965c62afa',
    routes: {
        discover: '/tabs/feed/31',
        //groups: '/tabs/groups',
        home: '/tabs/launcher',
        local: '/tabs/local',
        people: '/tabs/people',
        shop: '/tabs/shop',
    },
    showAccountBecomeCreator: false,
    showAccountClearCaches: false,
    showAccountSecurity: true,
    showAppearanceColorSchemes: true,
    showAppearanceFontsSelector: false,
    showAppearanceFontSizeSelector: false,
    showAppearancePreferedImageSize: false,
    showAppearanceTabs: false,
    showCheckoutPage: false,
    showCompaniesOnSearchPage: false,
    showEventsOnSearchPage: false,
    showFeedBottomShadow: false,
    showFooterAppVersion: false,
    showFooterBottomText: true,
    showGroupsOnSearchPage: true,
    showHeaderLargeTitle: true,
    showHeaderMenuButton: true,
    showHeaderToolbarLogo: true,
    showHeaderToolbarLogoInWeb: true,
    showHeaderToolbarLogoInApp: true,
    showHeaderToolbarTitle: false,
    showJobPostsInFeeds: false,
    showJobPostsInSearchResults: false,
    showJobPostsOnSearchPage: false,
    showLocalOnSearchPage: false,
    showLocalSourcesSuggestions: false,
    showLoginCredentialsSkipAccount: false,
    showLoginPage: false,
    showLoginPageLeftImg: true,
    showLoginTopLogo: true,
    showMenuGlobalSearch: true,
    showMenuHeaderLogo: true,
    showMenuHeaderTitle: false,
    showMenuIfLoggedOut: false,
    showMenuPagesInApp: true,
    showMenuPagesInWeb: true,
    showMoviesSuggestionsOnDiscoverPage: false,
    showMoviesSuggestionsOnFeedPage: false,
    showMoviesSuggestionsOnHomePage: false,
    showPeopleOnSearchPage: false,
    showPodcastsSuggestions: false,
    showPodcastsSuggestionsInFeed: false,
    showPostAuthorCard: false,
    showPostCardAdminFab: false,
    showPostCardExcerpt: true,
    showPostCardHostLabel: false,
    showPostCardOptions: false,
    showPostCardVerifiedBadges: false,
    showPostPagination: false,
    showPostReadInAppCard: false,
    showPostRelatedArticles: true,
    showPostsOnSearchPage: true,
    showProfileDisplayName: true,
    showProfileEvents: false,
    showProfileFriends: false,
    showProfileGroups: true,
    showProfilePosts: false,
    showProfileProducts: false,
    showProfileSocialInformation: true,
    showProfileSocialInformationLabels: true,
    showProfileUserGroup: true,
    showProfileUserGroupAsPrimaryLabel: true,
    showProfileUsername: false,
    showRegisterProjectSettings: false,
    showRegisterVideos: false,
    showSalut: true,
    showSettingsLegalLinks: true,
    showSettingsLinks: false,
    showShopCategories: true,
    showShopNewestProducts: true,
    showShopProductsOnSearchPage: true,
    showShopRelatedProductsOnDetailPage: true,
    showShopSaleProducts: true,
    showShopSources: false,
    showShopSuggestedProducts: true,
    showShopTrendingProducts: true,
    showSuggestedProfilesOnFeedPage: false,
    showSuggestedProfilesOnHomePage: false,
    showSuggestedProfilesOnLocalPage: false,
    showTabBar: true,
    showTabBarAsDock: false,
    showTabBarInMenuOnApp: true,
    showTabBarInMenuOnWeb: true,
    showTabBarLabels: true,
    showTabMenu: false,
    tabBarActiveStyle: 'solid',
    tabs: [
        {
            uid: 'launcher',
            checked: true,
            icon: 'home-outline',
            indent: 'launcher',
            index: 0,
            name: 'home',
            route: '/tabs/launcher',
            url: '/tabs/launcher',
        },
        {
            uid: 'feed_home',
            checked: true,
            icon: 'newspaper-outline',
            indent: 'feed_home',
            index: 0,
            name: 'news',
            route: '/tabs/feed/32',
            url: '/tabs/feed/32',
        },
        {
            uid: 'events_list',
            checked: true,
            icon: 'calendar-number-outline',
            indent: 'events_list',
            index: 1,
            name: 'calendar',
            route: '/tabs/dpolg/calendar',
            url: '/tabs/dpolg/calendar',
        },
        {
            uid: 'shop',
            checked: true,
            icon: 'storefront-outline',
            indent: 'shop',
            index: 3,
            name: 'shop',
            route: '/tabs/shop',
            url: '/tabs/shop',
        },
        /*
        {
            uid: 'groups',
            checked: true,
            icon: 'people-outline',
            indent: 'groups',
            index: 4,
            name: 'groups',
            route: '/tabs/groups',
            url: '/tabs/groups',
        },
        */
    ],
    translucentFooters: false,
    translucentHeaders: false,
    useAbonnements: false,
    useAdsExtension: false,
    useAiExtension: true,
    useAppearance: true,
    useAppleWatchExtension: false,
    useArchive: false,
    useAssetsExtension: true,
    useAuthExtension: false,
    useAvatars: false,
    useBionicReadingExtension: false,
    useBlogsExtension: true,
    useCalendar: true,
    useCategories: false,
    useCategoriesOnDiscoverPage: false,
    useCategoriesOnFeedPages: false,
    useCategoriesOnHomePage: false,
    useCategoriesOnLocalPage: false,
    useCategoriesOnSearchPage: false,
    useChatExtension: false,
    useCollectionsExtension: false,
    useCompaniesExtension: false,
    useCommentsExtension: false,
    useComplexityExtension: false,
    useCookiesAlertInApp: false,
    useCookiesAlertInWebApp: false,
    useCouponsExtension: false,
    useCreativesExtension: true,
    useDatingExtension: false,
    useDeathAnnouncementsExtension: false,
    useDiscover: false,
    useDocumentsExtension: false,
    useEventsList: true,
    useExperiments: false,
    useFeedback: false,
    useFeeds: false,
    useFiltersExtension: false,
    useFollow: false,
    useGoogleMapsExtension: true,
    useGroups: false,
    useHeadlineProfileAvatar: false,
    useHelpCenterExtension: false,
    useHome: false,
    useInbox: false,
    useInterests: false,
    useIntro: false,
    useIntroCards: true,
    useIntroPeople: false,
    useIntroReadingSettings: false,
    useInviteFriends: false,
    useJobPosts: false,
    useJobTitlesExtension: true,
    useLocal: false,
    useLoomaExtension: false,
    useMarketplaceExtension: false,
    useMediaExtension: true,
    useMoviesExtension: false,
    useOrderByFilters: false,
    usePayPalExtension: false,
    usePeople: false,
    usePointsSystem: false,
    usePostPaywallExtension: false,
    usePostsAdmin: false,
    useProfile: false,
    useProfileDisplayNames: true,
    useProfileTitleExtension: true,
    useProfileUsernames: false,
    useProjectsExtension: false,
    usePushNotifications: true,
    usePushNotificationsChannels: false,
    useQuizExtension: false,
    useReactions: false,
    useRegionSearch: false,
    useRegionSearchSuggestions: false,
    useReportingExtension: true,
    userPostContentSettings: {
        active: true,
        external: false,
        category: 'feed',
        language: 'de',
        local: false,
        type: 'post',
        verified: true,
    },
    useSearch: false,
    useSettings: false,
    useSharingExtension: true,
    useShop: true,
    useShopAccount: false,
    useShopCategories: false,
    useShopInternalPaymentProcess: false,
    useShortcuts: false,
    useShowroom: false,
    useSignInWithApple: false,
    useSignInWithFacebook: false,
    useSignInWithGoogle: false,
    useSimplyLocalExtension: false,
    useStatisticsExtension: true,
    useStories: false,
    useStoriesCameraExtension: false,
    useStoriesOnDiscoverPage: false,
    useStoriesOnHomePage: false,
    useStoriesOnLocalPage: false,
    useStoriesOnPeoplePage: false,
    useSurveyExtension: true,
    useTeamsExtension: true,
    useTesting: false,
    useThemes: true,
    useThreeDeeTouch: false,
    useTransparencyExtension: false,
    useTvExtension: false,
    useVideosExtension: false,
    useWallet: false,
    useWebsitesExtension: false,
    useWeclappExtension: false,
    useWeloveWalletExtension: false,
};