import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, NavParams } from '@ionic/angular';

import { ModalService } from 'src/app/services/core/modal.service';

@Component({
  selector: 'app-icon-picker',
  templateUrl: './icon-picker.page.html',
  styleUrls: ['./icon-picker.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconPickerPage implements OnInit {
  @ViewChild('searchInput') searchInput: IonSearchbar;

  icons: any;
  
  search: searchOptions = {

  };

  view: any = {
    colSize: 2,
    iconTypes: {
      outline: '-outline',
      filled: '',
      sharp: '-sharp'
    },
    iconType: 'outline',
  };

  user: user;

  userService: any;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private modalCtrl: ModalService,
    private navParams: NavParams,
    private zone: NgZone,
  ) {
    this.icons = this.navParams.get('iconsService');

    this.view.iconTypesNames = Object.keys(this.view.iconTypes);
    this.view.ionicons = this.icons.getIonicons();
  }

  detectChanges() {
    this.zone.run(() => {
      this.changeDetector.detectChanges();
    });
  }

  async dismiss(data: any = null) {
    this.modalCtrl.dismiss(data);
  }

  iconTypeChanged() {
    this.detectChanges();
  }

  ionViewWillEnter() {
  }

  ngOnInit() {
  }

  runSearch(event: any = null) {
    this.detectChanges();
  }

  select(icon: string) {
    this.view.icon = (icon + this.view.iconTypes[this.view.iconType]);
    this.detectChanges();
  }

  submit() {
    this.dismiss({
      icon: this.view.icon
    });
  }
  
  toggleSearch() {
    this.search.visible = !this.search.visible;
    if(this.searchInput) {
      this.searchInput.setFocus();
    }
    this.detectChanges();
  }

}
