import { Component, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { WoocommerceextendService } from 'src/app/services/payments/woocommerceextend.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.page.html',
  styleUrls: ['./coupon.page.scss'],
})
export class CouponPage implements OnInit {

  data: any = {
    code: '',
  };

  constructor(
    private events: EventsService,
    private modalCtrl: ModalService,
    private woocommerce: WoocommerceextendService,
  ) {
  }

  dismiss() {
    this.data.use = false;
    this.modalCtrl.dismiss(this.data);
  }

  ngOnInit() {
  }

  use() {
    this.woocommerce.getCouponByCode(this.data.code)
    .then((response: any) => {
      if(response.coupon) {
        this.woocommerce.useCoupon(response.coupon)
        .then(() => {
          this.data.use = true;
          this.data.coupon = response.coupon;
          this.modalCtrl.dismiss(this.data);
        })
        .catch(error => {
          this.events.publish('error', error);
        });
      }
    })
    .catch(error => {
      this.events.publish('error', error);
    });
  }

}
