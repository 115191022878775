import { Injectable } from '@angular/core';

import { Stripe } from '@capacitor-community/stripe';
import { ConfigService } from 'src/app/services/core/config.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(
    private config: ConfigService,
  ) {
    this.init();
  }

  init() {
    let publishableKey: string|null = this.config.getConfigParam('stripePublishableKey');

    if(!publishableKey) {
      console.warn('missing stripe publishableKey');
      return false;
    }

    Stripe.initialize({
      publishableKey: `${publishableKey}`,
    });
  }

  async pay() {
    console.log('stripe: pay');
  }

}
