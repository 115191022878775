import { Component, NgZone, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { PostsService } from 'src/app/services/posts/posts.service';
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'app-post-information',
  templateUrl: './post-information.page.html',
  styleUrls: ['./post-information.page.scss'],
})
export class PostInformationPage implements OnInit {

  post: post;

  constructor(
    private navParams: NavParams,
    private posts: PostsService,
    public userService: UserService,
    private zone: NgZone,
  ) {
    this.post = this.navParams.get('post');
  }

  ionViewWillEnter() {
    this.zone.run(() => {
      this.post = this.posts.getFullPost(this.post);
    });
  }
  
  ngOnInit() {
  }

}
