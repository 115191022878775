<ion-header class="ion-no-border" [translucent]="!!appConfig.translucentHeaders">

  <ion-toolbar class="ion-no-border container profile-top-toolbar">

    <ion-buttons slot="start">

      <!-- Dismiss button -->
      <ion-button *ngIf="view.isModal" (click)="dismiss()" [hidden]="view.editMode" color="white" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>

      <!-- Dismiss edit mode button -->
      <ion-button color="white" *ngIf="profile.me && view.editMode" (click)="editProfile(false)" icon-only fill="clear">
        <ion-icon name="close"></ion-icon>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Edit profile -->
      <ion-button slot="end" id="editProfileBtn" color="white" *ngIf="profile.me && !view.editMode" (click)="editProfile()" icon-only fill="clear">
        <ion-icon name="create-outline"></ion-icon>
      </ion-button>

      <!-- Options -->
      <ion-button slot="end" shape="round" color="white" icon-only (click)="profileSettings($event)" [hidden]="!profile.uid || profile.me" class="profileSettingsBtn">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Save button -->
      <ion-button *ngIf="profile.me" color="white" [hidden]="!view.editMode" [disabled]="!view.canSave" icon-only (click)="saveProfile()">
        <ion-icon name="checkmark"></ion-icon>
      </ion-button>

    </ion-buttons>
  </ion-toolbar>

  <div class="container">

    <!-- Cover photo -->
    <div class="cover" [style.margin-top]="view.headerOffset" [style.opacity]="view.headerOpacity" [class.editMode]="view.editMode" [class.hasCover]="profile && profile.classifications && profile.classifications.coverImage">
    
      <div class="cover-helper" (click)="onCoverClick()"></div>
      
      <ion-thumbnail class="coverThumbnail" (click)="onCoverClick()" *ngIf="!!profile && !!profile.classifications && !!profile.classifications.coverImage">
        <ion-img [src]="profile.classifications.coverImage" (ionError)="coverLoadingFailed()"></ion-img>
      </ion-thumbnail>

      <ion-item class="profileLabelItem" lines="none" [hidden]="view.editMode || (profile && profile.private)">
        
        <ion-thumbnail class="profileThumbnail" *ngIf="profile && profile.uid" slot="start" (click)="chooseAvatar()">
          <ion-img [src]="profile && profile.uid ? (profile.photo || avatarUrl) : avatarUrl" (ionError)="profileThumbnailLoadingFailed()"></ion-img>
        </ion-thumbnail>
        
        <ion-label class="ion-text-wrap">
          <h2 [innerHTML]="profile.classifications && profile.classifications.displayName ? profile.classifications.displayName : (profile.firstname && profile.lastname ? ((profile.firstname || '') + ' ' + (profile.lastname || '')) : (profile.nickname || '...'))"></h2>
                  
          <h4 [hidden]="view.editMode">
            <span [innerHTML]="view.primaryLabel|translate"></span>
            <pipeline-verified-badge [hidden]="view.editMode" [(profile)]="profile"></pipeline-verified-badge>
          </h4>
  
        </ion-label>
  
        <div class="profileActionsItem" lines="none" [hidden]="view.editMode || (profile && profile.private)">
              
          <ion-button (click)="chat()" *ngIf="!!appConfig.allowUserCreateChat" class="ion-text-wrap" shape="round" slot="end" [hidden]="!profile.uid || profile.me" class="chatBtn">
            <ion-icon name="chatbubble-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'send_profile_message'|translate"></ion-label>
          </ion-button>
  
          <ion-button slot="end" shape="round" [fill]="profile.isFriend ? 'outline' : 'solid'" [color]="profile.isFriend ? 'danger' : 'primary'" (click)="toggleFollow()" [hidden]="!profile.uid || profile.me" class="followBtn">
            <ion-icon [name]="profile.isFriend ? 'bookmark' : 'bookmark-outline'" slot="start"></ion-icon>
            <ion-label [innerHTML]="(profile.isFriend ? 'unfollow' : 'follow')|translate"></ion-label>
          </ion-button>
          
        </div>
  
      </ion-item>
    </div>

  </div>
</ion-header>

<ion-content [class.editMode]="view.editMode" (ionScroll)="onScroll($event)">

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <ion-card class="container" id="profileCard">

    <ion-card-content>
      
      <!-- Profile header list -->
      <ion-list class="profileHeaderList">
    
        <ion-item *ngIf="!view.editMode && profile.classifications && profile.classifications.description" lines="none" #descriptionText class="descriptionText">
          <p [innerHTML]="profile.classifications.description"></p>
        </ion-item>

        <ion-item lines="none" *ngIf="profile.me" class="editProfileDescriptionItem" [hidden]="!view.editMode">
          <ion-textarea [(ngModel)]="profile.classifications.description" [placeholder]="'brief_description'|translate"></ion-textarea>
        </ion-item>

        <!-- Social grid -->
        <ion-grid class="socialGrid" #socialGrid *ngIf="profile.public || profile.isFriend || (profile.uid == user.uid)">
          <ion-row class="scroll-row" *ngIf="profile.classifications && profile.classifications.social">
            
            <ion-col *ngFor="let social of profile.classifications.social">
              <ion-button [class.hasLabel]="!!appConfig.showProfileSocialInformationLabels && !view.editMode" size="small" fill="clear" color="dark" (click)="viewSocial(social)">
                <ion-icon [name]="social.icon" slot="start"></ion-icon>
                <ion-label [innerHTML]="social.name|translate" *ngIf="!!appConfig.showProfileSocialInformationLabels && !view.editMode"></ion-label>
                <ion-button size="small" *ngIf="profile.me" [hidden]="!view.editMode" class="removeSocialBtn" color="danger" (click)="removeSocial(social)">
                  <ion-icon name="close"></ion-icon>
                </ion-button>
              </ion-button>
            </ion-col>

            <ion-col *ngIf="profile.me" [hidden]="!view.editMode">
              <ion-button class="addSocialButton hasLabel" shape="round" color="light" fill="clear" icon-only color="dark" (click)="addSocial()">
                <ion-icon name="add" slot="start"></ion-icon>
                <ion-label [innerHTML]="'add_social_information'|translate"></ion-label>
              </ion-button>
            </ion-col>

            <ion-col *ngIf="profile && profile.classifications && profile.classifications.website" [hidden]="view.editMode || !profile.classifications.website">
              <ion-button [class.hasLabel]="!!appConfig.showProfileSocialInformationLabels" fill="clear" size="small" color="dark" (click)="openWebsite(profile.classifications.website)">
                <ion-icon name="link-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="profile.classifications.websiteCleaned|translate" *ngIf="!!appConfig.showProfileSocialInformationLabels"></ion-label>
              </ion-button>
            </ion-col>

          </ion-row>
        </ion-grid>
        
        <ion-item *ngIf="profile.me && appConfig.useProfileDisplayNames" [hidden]="!view.editMode">
          <ion-icon name="eye-outline" slot="start"></ion-icon>
          <ion-label position="stacked" [innerHTML]="'display_name'|translate"></ion-label>
          <ion-input *ngIf="profile.me" class="displayNameInput" [hidden]="!view.editMode" [(ngModel)]="profile.classifications.displayName" [placeholder]="'your_display_name'|translate"></ion-input>
        </ion-item>

        <ion-item *ngIf="profile.me && appConfig.useProfileTitleExtension && !!view.userTitles" [hidden]="!view.editMode">
          <ion-icon name="chatbox-outline" slot="start"></ion-icon>
          <ion-label position="stacked" [innerHTML]="'user_title'|translate"></ion-label>
          <ion-select interface="action-sheet" [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [(ngModel)]="profile.title" [placeholder]="'select'|translate">
            <ion-select-option *ngFor="let title of view.userTitles" [value]="title" [innerHTML]="title|translate"></ion-select-option>
          </ion-select>
        </ion-item>
        
        <ion-item *ngIf="profile.me" [hidden]="!view.editMode">
          <ion-icon name="person-outline" slot="start"></ion-icon>
          
          <ion-label position="stacked" [innerHTML]="'firstname'|translate"></ion-label>
          <ion-input *ngIf="profile.me" class="firstnameInput" [hidden]="!view.editMode" [(ngModel)]="profile.firstname" [placeholder]="'your_firstname'|translate"></ion-input>
          
          <ion-label position="stacked" [innerHTML]="'lastname'|translate"></ion-label>
          <ion-input *ngIf="profile.me" class="lastnameInput" [hidden]="!view.editMode" [(ngModel)]="profile.lastname" [placeholder]="'your_lastname'|translate"></ion-input>

        </ion-item>

        <ion-item *ngIf="profile.me && appConfig.useProfileUsernames" [hidden]="!view.editMode">
          <ion-icon name="at-outline" slot="start"></ion-icon>
          <ion-label position="stacked" [innerHTML]="'nickname'|translate"></ion-label>
          <ion-input *ngIf="profile.me" class="usernameInput" [hidden]="!view.editMode" [(ngModel)]="profile.nickname" (ionChange)="onUsernameChanged()" autocorrect="off" pattern="^[a-zA-Z0-9]+$" placeholder="@username"></ion-input>
        </ion-item>

        <ion-item class="usernameError" [hidden]="!view.editMode" *ngIf="view.usernameError" color="danger">
          <ion-icon name="warning-outline" slot="start"></ion-icon>
          <p [innerHTML]="view.usernameError|translate"></p>
        </ion-item>

        <ion-item [class.small-user-info]="!view.editMode" [lines]="!!view.editMode ? true : 'none'" *ngIf="appConfig.useJobTitlesExtension" [hidden]="!profile || (!view.editMode && (!profile.classifications || !profile.classifications.jobTitleLabel))" (click)="setupJobTitle()">
          <ion-icon name="school-outline" slot="start"></ion-icon>
          <ion-label position="stacked" *ngIf="!!view.editMode">
            <h3 [innerHTML]="'jobtitle'|translate"></h3>
            <p [innerHTML]="(profile && profile.classifications && profile.classifications.jobTitleLabel ? profile.classifications.jobTitleLabel : 'search_jobtitle')|translate"></p>
          </ion-label>
          <ion-label class="ion-text-wrap" *ngIf="!view.editMode && (profile && profile.classifications && profile.classifications.jobTitleLabel)" [innerHTML]="profile.classifications.jobTitleLabel|translate"></ion-label>
        </ion-item>

        <ion-item [class.small-user-info]="!view.editMode" [lines]="!!view.editMode ? true : 'none'" *ngIf="appConfig.useCompaniesExtension" [hidden]="!profile || (!view.editMode && (!profile.classifications || !profile.classifications.companyLabel))" (click)="setupCompany()">
          <ion-icon name="business-outline" slot="start"></ion-icon>
          <ion-label position="stacked" *ngIf="!!view.editMode">
            <h3 [innerHTML]="'company'|translate"></h3>
            <p [innerHTML]="(profile && profile.classifications && profile.classifications.companyLabel ? profile.classifications.companyLabel : 'search_company')|translate"></p>
          </ion-label>
          <ion-label class="ion-text-wrap" *ngIf="!view.editMode && (profile && profile.classifications && profile.classifications.companyLabel)" [innerHTML]="profile.classifications.companyLabel|translate"></ion-label>
        </ion-item>

        <ion-item *ngIf="profile.me" [hidden]="!view.editMode">
          <ion-icon name="link-outline" slot="start"></ion-icon>
          <ion-label position="stacked" [innerHTML]="'website'|translate"></ion-label>
          <ion-input *ngIf="profile.me" class="websiteInput" [hidden]="!view.editMode" [(ngModel)]="profile.classifications.website" [placeholder]="'website_link'|translate"></ion-input>
        </ion-item>
        
      </ion-list>
        
      <ion-grid id="profileStoriesGrid" class="storiesGrid" *ngIf="view.stories && view.stories.length">
        <ion-row class="scroll-row">
          <ion-col
            size="2"
            *ngFor="let story of view.stories; let i = index;"
            (click)="viewStory(story, i)"
            [hidden]="!(story.src || story.thumbnail)"
          >
            <ion-icon *ngIf="story.icon" [name]="story.icon"></ion-icon>
            <ion-avatar *ngIf="story.src || story.thumbnail">
              <ion-img [src]="story.src || story.thumbnail" (ionError)="storyFailed(story)"></ion-img>
            </ion-avatar>
          </ion-col>
        </ion-row>
      </ion-grid>
      
      <!-- Segment bar -->
      <ion-segment mode="md" *ngIf="view.hasCustomCollections || appConfig.useShop || appConfig.showProfileEvents || appConfig.showProfileFriends" [hidden]="view.editMode || !profile.uid || profile.private" [(ngModel)]="view.segment" (ionChange)="segmentChanged()">
        
        <!-- Custom collections -->
        <ion-segment-button value="custom" *ngIf="view.hasCustomCollections && appConfig.showProfileCollections">
          <ion-label [innerHTML]="'custom_segment_label'|translate"></ion-label>
        </ion-segment-button>

        <!-- Posts -->
        <ion-segment-button value="post" *ngIf="appConfig.showProfilePosts">
          <ion-label [innerHTML]="'posts'|translate"></ion-label>
        </ion-segment-button>

        <!-- Products -->
        <ion-segment-button value="product" *ngIf="appConfig.useShop && appConfig.showProfileProducts">
          <ion-label [innerHTML]="'products'|translate"></ion-label>
        </ion-segment-button>

        <!-- Events -->
        <ion-segment-button value="events" *ngIf="appConfig.showProfileEvents">
          <ion-label [innerHTML]="'events'|translate"></ion-label>
        </ion-segment-button>

        <!-- Groups -->
        <ion-segment-button value="group" *ngIf="appConfig.showProfileGroups">
          <ion-label [innerHTML]="'groups'|translate"></ion-label>
        </ion-segment-button>

        <!-- Friends -->
        <ion-segment-button value="people" *ngIf="profile.me || appConfig.showProfileFriends">
          <ion-label [innerHTML]="'people'|translate"></ion-label>
        </ion-segment-button>

      </ion-segment>
  
      <ion-grid [hidden]="view.editMode" *ngIf="collections && collections[view.segment] && collections[view.segment].items && collections[view.segment].items.length">
        
        <ion-row *ngIf="view.segment !== 'people'">
          <ion-col *ngFor="let col of collections[view.segment].items" [hidden]="col.indent && (!col.name || !col.style)" class="col-size-{{col.size || 12}}" [size]="col.size||12">
            <ion-card class="profileCard" [class.folder]="col.items && col.items.length" [color]="col.color||'light'" (click)="onColClick(col)">
              <ion-fab class="vipBadge" horizontal="end" *ngIf="col.vip" vertical="bottom">
                <ion-fab-button color="warning" size="small">
                  <ion-icon name="lock-closed"></ion-icon>
                </ion-fab-button>
              </ion-fab>
              <ion-fab class="verifiedBadge" horizontal="end" *ngIf="col.verified && !col.vip" vertical="bottom">
                <ion-fab-button color="primary" size="small">
                  <ion-icon name="shield-checkmark-outline"></ion-icon>
                </ion-fab-button>
              </ion-fab>
              <ion-card-header>
                <ion-card-title *ngIf="col.name" [innerHTML]="col.name"></ion-card-title>
                <ion-skeleton-text [hidden]="col.name" style="width:90%;" animated></ion-skeleton-text>
                <ion-skeleton-text [hidden]="col.name" style="width:50%;" animated></ion-skeleton-text>

                <ion-card-subtitle *ngIf="col.subTitle" [innerHTML]="col.subTitle"></ion-card-subtitle>
              </ion-card-header>
            </ion-card>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="view.segment === 'people'">
          <ion-col class="peopleCol col-size-12" size="12">
            <ion-list lines="none">
              <ion-item *ngFor="let col of collections[view.segment].items">
                <ion-thumbnail slot="start" (click)="onColClick(col, 'person')">
                  <img *ngIf="col.uid" [src]="col.photo || avatarUrl" (error)="col.photo=avatarUrl">
                  <ion-skeleton-text [hidden]="col.uid" class="image" animated></ion-skeleton-text>
                </ion-thumbnail>
                <ion-label class="ion-text-wrap" (click)="onColClick(col, 'person')">
                  <h3 [innerHTML]="col.nickname"></h3>
                  <ion-skeleton-text [hidden]="col.uid" style="width:80%;" animated></ion-skeleton-text>
                  <p [innerHTML]="col.classifications && col.classifications.displayName ? col.classifications.displayName : (col.firstname || col.lastname ? col.firstname + ' ' + col.lastname : '')"></p>
                  <ion-skeleton-text [hidden]="col.uid" style="width:60%;" animated></ion-skeleton-text>
                </ion-label>
                <ion-button [hidden]="!col.uid" slot="end" color="primary" shape="round" (click)="follow(col)" *ngIf="!col.isFriend">
                  <ion-icon name="bookmark-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'follow'|translate"></ion-label>
                </ion-button>
                <ion-button [hidden]="!col.uid" slot="end" (click)="unfollow(col)" shape="round" color="danger" fill="outline" *ngIf="col.isFriend">
                  <ion-icon name="bookmark" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'unfollow'|translate"></ion-label>
                </ion-button>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-card-header class="noEntriesCardContent" *ngIf="!view.editMode && profile && !profile.private && collections && (!collections[view.segment] || !collections[view.segment].items || !collections[view.segment].items.length)">
        <ion-card-title *ngIf="!view.segment || view.segment == 'post' || view.segment == 'custom'" [innerHTML]="'no_entries'|translate"></ion-card-title>
        <ion-card-title *ngIf="view.segment == 'product'" [innerHTML]="'no_products'|translate"></ion-card-title>
        <ion-card-title *ngIf="view.segment == 'events'" [innerHTML]="'no_events'|translate"></ion-card-title>
        <ion-card-title *ngIf="view.segment == 'people'" [innerHTML]="'no_friends'|translate"></ion-card-title>
      </ion-card-header>

      <ion-card-header class="noEntriesCardContent" *ngIf="!view.editMode && profile && !profile.private && collections && (!collections[view.segment] || !collections[view.segment].items || !collections[view.segment].items.length)">
        <ion-card-subtitle *ngIf="profile.me" [innerHTML]="'no_entries_subtitle_1'|translate"></ion-card-subtitle>
        <ion-card-subtitle *ngIf="!profile.me">{{profile.firstname}} <span [innerHTML]="'no_entries_subtitle_2'|translate"></span></ion-card-subtitle>
      </ion-card-header>
  
      <ion-card-header *ngIf="profile && profile.private">
        <ion-card-title>{{'user_not_visible'|translate}}</ion-card-title>
        <ion-card-subtitle [hidden]="user.uid === profile.uid">{{'user_not_visible_subtitle'|translate}}</ion-card-subtitle>
        <ion-card-subtitle [hidden]="user.uid !== profile.uid">{{'user_not_visible_subtitle_self'|translate}}</ion-card-subtitle>
      </ion-card-header>
      
      <pipeline-post-suggestions-card *ngIf="!!view.hasSuggestions && profile && !!profile.me && !view.loading" [hidden]="!(!view.editMode && collections && (view.segment === 'post') && (!collections[view.segment] || !collections[view.segment].items || !collections[view.segment].items.length))"></pipeline-post-suggestions-card>

    </ion-card-content>

  </ion-card>

  <ion-infinite-scroll threshold="100px" *ngIf="collections && collections[view.segment] && collections[view.segment].items && collections[view.segment].items.length" (ionInfinite)="loadNext($event)">
    <ion-infinite-scroll-content loadingSpinner="circular">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
  
</ion-content>