import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from 'src/app/services/extensions/pages.service';
import { ModalController } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';

@Component({
  selector: 'app-dynamic',
  templateUrl: './dynamic.page.html',
  styleUrls: ['./dynamic.page.scss'],
})
export class DynamicPage implements OnInit {

  isModal: boolean;

  page: page;

  pageId: number;

  view: any = {};
  
  constructor(
    private events: EventsService,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private PagesService: PagesService,
  ) {
    this.isModal = this.PagesService.isModal();

    this.page = this.PagesService.getCurrent();
    this.pageId = parseInt(this.route.snapshot.paramMap.get('pageId'));

    if(!this.pageId && !!this.page && !!this.page.uid) {
      this.pageId = this.page.uid;
    }
  }

  async dismiss() {
    this.modalCtrl.dismiss();
  }
  
  doRefresh(event: any) {
    this.loadPage(this.pageId, true)
    .then(() => {
      event.target.complete();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  ngOnInit() {
    if(!this.page && this.pageId) {
      this.loadPage(this.pageId)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }
  }

  loadPage(pageId: number = null, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.PagesService
      .getByUid(pageId || this.pageId, blForceRefresh)
      .then(page => {
        this.page = page;
        resolve(this.page);
      })
      .catch(reject);
    });
  }

}
