import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController } from '@ionic/angular';
import { UserService } from 'src/app/services/core/user.service';
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { TranslationService } from 'src/app/services/core/translation.service';

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  key: string = 'social_information';
  
  constructor(
    private actionSheetCtrl: ActionSheetController,
    private alertCtrl: AlertController,
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private loading: LoadingController,
    private translations: TranslationService,
    public userService: UserService,
  ) {

  }

  addSocial(network: any) {
    return new Promise((resolve, reject) => {
      let userId = this.userService.getUid();
      
      if(!userId) {
        reject('error_missing_user_uid');
      }

      let item = {
        type: this.key,
        indent: 'social_' + userId + '_network_' + network.value,
        parent: userId,
        value: network,
      };

      this.AppCMS.loadPluginData('pipeline', {
        item: item
      }, ['users', userId, 'social', 'add'])
      .then((response: any) => {
        this.cache.remove('social_information_' + userId);
        resolve(response);
      }).catch(reject);
    });
  }

  get(profileId: number = null, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      profileId = profileId || this.userService.getUid();
      let key = 'social_information_' + profileId, fromCache: cacheItem = await this.cache.get(key, 30 * 60);
      if(!profileId) {
        reject('error_missing_profileId');
      } else {
        if((fromCache && fromCache.data) && !blForceRefresh) {
          resolve(fromCache.data);
        } else { 
          this.AppCMS.loadPluginData('pipeline', {}, ['users', profileId, 'social'])
          .then((socialItems: any) => {
            this.cache.set(key, socialItems);
            resolve(socialItems);
          })
          .catch(reject); 
        }
      }
    });
  }

  remove(network: any) {
    return new Promise(async (resolve, reject) => {
      let userId = this.userService.getUid();
      const loading = await this.loading.create({
        spinner: 'circular',
      });
      loading.present();

      if(!userId) {
        loading.dismiss();
        reject('error_missing_user_uid');
      }

      let item = {
        type: this.key,
        indent: 'social_' + userId + '_network_' + network.value,
        parent: userId,
      };

      this.AppCMS.loadPluginData('pipeline', {
        item: item
      }, ['users', userId, 'social', 'delete'])
      .then((response) => {
        this.cache.remove('social_information_' + userId);
        loading.dismiss();
        resolve(response);
      }).catch((error: any) => {
        loading.dismiss();
        reject(error);
      });
    });
  }

  async requestInputData(network: any) {
    return new Promise((resolve, reject) => {
      let placeholder: string, prefix: string = '', headline: string = ('add_social_information_' + network.value);


      switch(network.value) {
        case 'email':
          placeholder = 'enter_email_address';
          break;
        case 'instagram':
          placeholder = 'enter_username';
          prefix = 'https://instagr.am/';
          break;
        default:
          placeholder = 'enter_profile_url';
          break;
      }

      this.translations.get([
        'cancel',
        headline,
        'okay',
        placeholder,
      ])
      .subscribe(async (translations: any) => {
        const alert = await this.alertCtrl.create({
          header: translations[headline] || headline,
          inputs: [
            {
              type: 'text',
              name: 'url',
              placeholder: translations[placeholder] || placeholder,
            }
          ],
          buttons: [
            {
              text: translations.okay || 'Okay',
              handler: (data: any) => {
                if(data) {
                  network = Object.assign(network, data);
                }
                network.url = prefix && network.url ? prefix + network.url : network.url;
                if(!network.url || !network.url.length) {
                  reject('error_missing_url');
                } else {
                  this.addSocial(network).then(resolve).catch(reject);
                }
              }
            },
            {
              text: translations.cancel || 'Cancel',
              role: 'cancel',
            }
          ],
        });
        alert.onWillDismiss().then((response: any) => {
          if(response.data.values) {
            network = Object.assign(network, response.data.values);
          }
        });
        await alert.present();

      });
    });
  }

  showAddSocial(options: any = {}) {
    return new Promise(async (resolve, reject) => {
      this.translations.get([
        'add_social_information',
        'email',
        'facebook',
        'instagram',
        'linkedin',
        'pinterest',
        'tiktok',
        'xing',
        'youtube',
      ])
      .subscribe(async (translations: any) => {

        let buttons = [], networks = [
          {
            name: translations.email || 'E-Mail',
            value: 'email'
          },
          {
            name: translations.facebook || 'Facebook',
            value: 'facebook'
          },
          {
            name: translations.instagram || 'Instagram',
            value: 'instagram'
          },
          {
            name: translations.linkedin || 'LinkedIn',
            value: 'linkedin'
          },
          {
            name: translations.pinterest || 'Pinterest',
            value: 'pinterest'
          },
          {
            name: translations.tiktok || 'TikTok',
            value: 'tiktok'
          },
          {
            name: translations.xing || 'XING',
            value: 'xing'
          },
          {
            name: translations.youtube || 'YouTube',
            value: 'youtube'
          },
        ];

        networks.forEach((network: any) => {
          let icon = 'logo-' + network.value;
          switch(network.value) {
            case 'email': icon = 'mail-outline'; break;
          }
          network.icon = icon;

          buttons.push({
            text: network.name,
            value: network.value,
            icon: icon,
            handler: () => {
              this.requestInputData(network).then(resolve).catch(reject);
            },
          });

        });

        let actionSheet = await this.actionSheetCtrl.create({
          header: translations.add_social_information || 'add_social_information',
          cssClass: 'left-align-buttons',
          buttons: buttons,
        });

        actionSheet.onWillDismiss().then((data: any) => {
          //console.log('dismiss addSocial', data);
        });

        await actionSheet.present();

      });
    });
  }
  
}