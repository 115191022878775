import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { MessagesService } from 'src/app/services/social/messages.service';
import { ClipboardService } from 'src/app/services/utils/clipboard.service';
import { EventsService } from 'src/app/services/core/events.service';
import { FriendsService } from 'src/app/services/social/friends.service';
import { StoriesService } from 'src/app/services/social/stories.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';
import { LoginService } from 'src/app/services/core/login.service';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
  selector: 'app-share-internal',
  templateUrl: './share-internal.page.html',
  styleUrls: ['./share-internal.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShareInternalPage implements OnInit {

  avatarUrl: string = './assets/img/avatars/1.jpg';

  config: pipelineAppConfig;

  data: any;

  post: post;

  search: searchOptions = {
    query: '',
  };

  sharing: any;

  private slider: any;
  
  sliderOptions: any = {
    loop: false,
    slidesPerView: (window.innerWidth > 768 ? 5 : 4),
    spaceBetween: 10,
  };

  view: any = {
    placeholders: [{},{},{},{},{}],
  };

  constructor(
    private clipboard: ClipboardService,
    private configService: ConfigService,
    private events: EventsService,
    public friends: FriendsService,
    private loginService: LoginService,
    private messages: MessagesService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private stories: StoriesService,
    private translations: TranslationService,
    public userService: UserService,
    private zone: NgZone,
  ) {
    this.avatarUrl = this.userService.getAvatarUrl();
    this.config = this.configService.getConfig();
    
    this.post = this.navParams.get('post');
    this.sharing = this.navParams.get('sharingService');
  }

  copyLink() {
    this.clipboard.copyText(`${this.configService.getOpenerURL()}${this.post.uid}`);
    this.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss(this.data);
  }

  doRefresh(event: any) {
    this.loadSuggestions()
    .then(() => {
      event.target.complete();
    })
    .catch((error: any) => {
      this.search.suggestions = [];
      this.events.publish('error', error);
    });
  }

  ionViewWillEnter() {
    if(!!this.config.usePeople && !this.search.suggestions) {
      this.loadSuggestions()
      .catch((error: any) => {
        this.search.suggestions = [];
        this.events.publish('error', error);
      });
    }
  }

  loadSuggestions() {
    return new Promise((resolve, reject) => {
      this.search.suggestions = JSON.parse(JSON.stringify(this.view.placeholders));

      let uid: number = this.userService.getUid();

      if(!!uid) {
        this.friends.getAll()
        .then((friends: user[]) => {
          this.search.suggestions = friends;
          resolve(friends);
        })
        .catch((error: any) => {
          this.search.suggestions = [];
          reject(error);
        });
      } else {
        this.search.suggestions = [];
        resolve(this.search.suggestions);
      }
    });
  }

  ngOnInit() {
  }

  postInStory() {
    let uid: number = this.userService.getUid();

    if(!uid || (uid === -1)) {
      this.loginService.show(true)
      .then(() => {
        this.submitPostInStory();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    } else {
      this.submitPostInStory();
    }
  }

  runSearch(event: any = null) {
    this.zone.run(() => {
      this.search.results = null;

      this.friends.search(this.search.query)
      .then((results: user[]) => {
        this.search.results = results;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    });
  }

  setSwiperInstance(event: any) {
    this.slider = event;
  }

  shareExternally() {
    this.dismiss();
    this.sharing.sharePost(this.post);
  }

  submitPostInStory() {
    let uid: number = this.userService.getUid();

    if(!!uid && (uid !== -1)) {
      this.stories.publish({
        item: this.post.uid,
      })
      .then(() => {
        this.translations.get(['posted_in_story'])
        .subscribe((translations: any) => {
          this.events.publish('toast', {
            message: translations.posted_in_story || 'In deiner Story gepostet',
            color: 'primary',
          });
        });
        this.dismiss();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }
  }

  use(friend: user) {
    this.messages.sendFriendTagNotification(friend.uid, this.post)
    .then((response: any) => {
      this.translations.get(['message_sent'])
      .subscribe((translations: any) => {
        this.events.publish('toast', {
          message: translations.message_sent || 'Nachricht wurde verschickt',
          color: 'primary',
        });
      });
      this.dismiss();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

}
