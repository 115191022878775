import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { SettingsService } from 'src/app/services/core/settings.service';

@Injectable({
  providedIn: 'root'
})
export class FontsService {

  readerFont: font = window.localStorage.getItem('readerFont') ? JSON.parse(window.localStorage.getItem('readerFont')) : null;

  constructor(
    private AppCMS: AppcmsService,
    private settings: SettingsService,
  ) {
  }

  getAll() {
    return this.AppCMS.loadUrl('./assets/fonts.json');
  }

  getReaderFont() {
    return this.settings.getSetting('font');
  }

  setReaderFont(font: font) {
    return this.settings.updateSetting('font', font);
  }

}
