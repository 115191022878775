import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController, NavParams, PopoverController } from '@ionic/angular';

import { CategoriesService } from 'src/app/services/pipeline/categories.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { FriendsService } from 'src/app/services/social/friends.service';
import { StateService } from 'src/app/services/core/state.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { UserService } from 'src/app/services/core/user.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { ShopService } from 'src/app/services/ecommerce/shop.service';

import { CardOptionsPage } from '../legacy/card-options/card-options.page';

@Component({
  selector: 'app-search',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPage implements OnInit {
  @ViewChild('searchInput') searchInput: IonSearchbar;

  config: pipelineAppConfig;

  search: any = {
    query: "",
    visible: false,
  };

  searchService: any;

  state: any = {};

  view: any = {
    placeholders: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
  };
  
  constructor(
    private categories: CategoriesService,
    private changeDetectorRef: ChangeDetectorRef,
    public configService: ConfigService,
    private events: EventsService,
    public friends: FriendsService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private posts: PostsService,
    private shop: ShopService,
    private stateService: StateService,
    private tools: ToolsService,
    public userService: UserService,
    private zone: NgZone,
  ) {
    this.config = this.configService.getConfig();
    
    this.searchService = this.navParams.get('searchService');
    this.state.searchTab = this.navParams.get('tab') || 'everything';
    
    this.view.admin = this.navParams.get('admin') || this.userService.isType('Admin');

    this.events.subscribe('home:search:filters:updated', (filters: homeDataFilterResponse) => {
      this.filterSearchResults(filters);
    });
  }

  clearSearch() {
    this.zone.run(() => {
      this.search.query = "";
      delete this.search.results;

      this.detectChanges();
    });
    return false;
  }

  deleteSearchHistoryItem(query: string) {
    this.searchService.deleteHistoryItem(query)
    .then(() => {
      this.loadSearchHistory();
    });
  }

  detectChanges() {
    setTimeout(() => {
      this.zone.run(() => {
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();  
  }

  doRefresh(event: any) {
    let call: any;

    if(!!this.search.query && !!this.search.query.length) {
      call = this.runSearch(null, true);
    } else {
      call = this.loadCategories(true);
    }

    call.then(() => {
      event.target.complete();
    })
    .catch((error: any) => {
      this.search.results = [];
      this.events.publish('error', error);
    });
  }

  filterBySearchTab(tab: string|null = null) {
    tab = tab || this.state.searchTab;

    let blIsEverything = tab === 'everything';

    if(this.search.results && this.search.results.length) {
      this.search.results.forEach((result: any) => {
        let blIsLocal = !!((tab === 'local') && (result.region && result.region.length && result.region !== 'global')),
            blIsPerson = (tab === 'people') && (result.type === 'person'),
            blIsProduct = (tab === 'products') && (result.type === 'product' || result.type === 'single' || result.type === 'variable'),
            blIsPost = (tab === 'posts') && (result.type === 'post');
        result.hidden = !blIsEverything && !!(!blIsLocal && !blIsPerson && !blIsProduct && !blIsPost);
      });
    }

    this.detectChanges();
  }

  filterSearchResults(filters: homeDataFilterResponse) {
    this.search.results.forEach((result: any) => {
      result.hidden = !!(
        (!filters.people && result.type === 'person') ||
        (!filters.posts && result.type === 'post') ||
        (!filters.products && result.type === 'product')
      );
    });
    this.detectChanges();
  }

  focus() {
    this.zone.run(() => {
      if(this.searchInput) {
        //console.log('focus');
        this.searchInput.setFocus();
      }
    });
  }

  ionViewWillEnter() {
    this.state.searchTab = this.state.searchTab || 'everything';
    
    this.view.hasLocal = !!this.userService.getCity();
    this.view.os = this.tools.getOS();

    this.loadCategories();
    this.loadSearchHistory();
    this.detectChanges();

    setTimeout(() => {
      this.focus();
    }, 1000);
  }

  async loadCategories(blForceRefresh: boolean = false) {
    let categories: any = await this.categories.getSelectedCategories(blForceRefresh);
    
    if(this.config.useCategories && this.config.useCategoriesOnSearchPage && categories && categories.length) {
      this.view.categories = this.tools.shuffle(categories);
      this.detectChanges();
    }
  }

  async loadSearchHistory() {
    try {
      this.search.history = await this.searchService.getHistory();
      this.detectChanges();
    } catch(e) {
      console.warn('loading search history failed: ', e);
    }
  }

  ngOnInit() {
  }

  onCategoryClick(category: category) {

    if(!category.term_id) {
      console.warn('no category id found');
      return false;
    }

    this.search.results = JSON.parse(JSON.stringify(this.view.placeholders));

    this.posts.getPostsByCategory(category.term_id)
    .then((posts: post[]) => {
      this.search.results = posts;
      this.detectChanges();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  onColClick(col: col, event: any = null) {
    
    try {
      if(this.search.query && this.search.query.length) {
        this.searchService.addToHistory(this.search.query)
        .then(() => {
          this.loadSearchHistory();
        });
      }
    } catch(e) {
      console.warn('add to search history failed', e);
    }

    if(event && event.target && event.target.classList.contains('optionsButton')) {
      event.preventDefault();
      return false;
    }

    switch (col.type) {
      case "person":
        return this.onPersonClick(col);
      case "post":
        return this.onPostClick(col as post);
      case "product":
        return this.onProductClick(col);
      case "simple":
        return this.onProductClick(col);
      case 'variable':
        return this.onProductClick(col);
      default:
        this.events.publish("error", "Unbekannter Typ " + col.type);
        break;
    }
  }
  
  async onPersonClick(user: user) {
    this.events.publish('view:profile', user);
  }

  async onPostClick(post: post) {
    this.events.publish('view:post', post);
  }

  async onProductClick(product: any) {
    this.events.publish('view:product', product);
  }

  openProfile(col: col) {
    //console.log('col', col);
  }

  async options(col: col, event: any = null) {
    event.preventDefault();
    let optionsPopover = await this.popoverCtrl.create({
      animated: true,
      component: CardOptionsPage,
      componentProps: {
        col: col,
        event: event,
      },
      cssClass: 'cardOptionsPopover',
      event: event,
      translucent: true
    });
    await optionsPopover.present();
    return false;
  }

  onSearchHistoryItemClick(query: string) {
    this.runSearch(query)
    .catch((error: any) => {
      this.search.results = [];
      this.state.loading = false;
      this.detectChanges();

      if(error !== 'error_query_too_short') {
        this.events.publish('error', error);
      }
    });
  }

  onSearchQueryChanged() {
    this.runSearch()
    .catch((error: any) => {
      this.search.results = [];
      this.state.loading = false;
      this.detectChanges();

      if(error !== 'error_query_too_short') {
        this.events.publish('error', error);
      }
    });
  }

  runSearch(query: string|null = null, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      query = query || this.search.query;

      if (!query || !query.length) {
        return this.clearSearch();
      }
  
      this.search.results = JSON.parse(JSON.stringify(this.view.placeholders));
      this.state.loading = true;
      
      this.searchService.run({
        query: query,
        filter: {
          active: true,
        }
      }, {}, blForceRefresh)
      .then((results: searchResult) => {
        this.state.loading = false;
        this.search.resultsByType = results;
  
        this.friends.asCols(null, results.people)
        .then((friendsCols: col[]) => {
          
          if(results && results.posts && results.posts.length) {
            results.posts.forEach((post: post) => {
              delete post.avatar;
            });
          }
  
          let products: any[] = (this.config.useShop ? this.shop.getFullProducts(results.products) || [] : []);
          
          this.search.results = (friendsCols || []).concat(
            (this.posts.getFullPosts(results.posts) || []).concat(products)
          );
  
          this.search.results.forEach((result: any) => {
            result.allowed = this.view.admin || this.stateService.isPostAllowed(result);
          });

          this.filterBySearchTab();
        })
        .catch(reject);
      })
      .catch(reject);
    });
  }

  selectSearchSegment(segment: string) {}

  setSearchTab(tab: string) {
    this.filterBySearchTab(tab);
  }

  thumbnailLoadingFailed(col: col) {
    col.hidden = true;
    this.detectChanges();
  }

}
