import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/core/events.service';

import { NavController } from '@ionic/angular';
 
@Component({
  selector: 'pipeline-post-view-short-register-card',
  templateUrl: './post-view-short-register-card.component.html',
  styleUrls: ['./post-view-short-register-card.component.scss'],
})
export class PostViewShortRegisterCardComponent implements OnInit {

  constructor(
    private events: EventsService,
    private navCtrl: NavController,
  ) {

  }

  createAccount() {
    this.events.publish('post:reader:dismiss');
    this.navCtrl.navigateRoot('/login');
  }

  ngOnInit() {

  }

}
