<ion-app [class.loggedin]="!!(user && user.uid)" [class.has-sidebar]="!!(user && user.uid) || !!appConfig.showMenuIfLoggedOut">
  <ion-split-pane contentId="main-content">
    <ion-menu [hidden]="!menuData.visible" [side]="view.menu.side" contentId="main-content" [class.hasHeader]="!!appConfig.allowUserLogin" type="reveal">

      <ion-header class="ion-no-border">

        <ion-toolbar *ngIf="appConfig.showMenuHeaderLogo || appConfig.showMenuHeaderTitle">
          <img *ngIf="appConfig.showMenuHeaderLogo" src="./assets/img/logo.webp" class="pipeline-logo light" />
          <img *ngIf="appConfig.showMenuHeaderLogo" src="./assets/img/logo-dark.webp" class="pipeline-logo dark" />
        
          <ion-title *ngIf="appConfig.showMenuHeaderTitle" [innerHTML]="appConfig.appName"></ion-title>
        
        </ion-toolbar>

        <ion-toolbar [hidden]="!appConfig.allowUserLogin">
          <ion-item class="userMenuItem" lines="none">
            <ion-thumbnail slot="start" (click)="me()">
              <ion-img [src]="user.photo" (error)="user.photo=avatarUrl"></ion-img>
            </ion-thumbnail>
            <ion-label>
              <h3 (click)="me()" *ngIf="user.classifications && user.classifications.displayName && user.classifications.displayName.length" [innerHTML]="user.classifications.displayName"></h3>
              <h3 (click)="me()" *ngIf="user.firstname && user.lastname && (!user.classifications || !user.classifications.displayName || !user.classifications.displayName.length)" [innerHTML]="user.firstname + ' ' + user.lastname"></h3>
              <h3 (click)="me()" *ngIf="!user.firstname && !user.lastname && (!user.classifications || !user.classifications.displayName || !user.classifications.displayName.length)" [innerHTML]="user.nickname"></h3>
              <h3 (click)="me()" *ngIf="user && user.uid && user.uid === -1" [innerHTML]="'user_not_logged_in'|translate"></h3>
              
              <p *ngIf="!!appConfig.useWallet && (user.uid && (user.uid !== -1) && user.score)" (click)="wallet()" [innerHTML]="user.score + ' ' + ('points'|translate)"></p>
              <p *ngIf="(user.uid && (user.uid !== -1) && !user.score)" (click)="me()" [innerHTML]="(appConfig.usePointsSystem ? 'beginner_label' : 'view_my_profile')|translate"></p>
              <p *ngIf="user && user.uid && user.uid === -1" (click)="me()" [innerHTML]="'user_click_to_login'|translate"></p>

            </ion-label>
            <ion-button slot="end" (click)="changeAccount()" *ngIf="appConfig.multiUser" fill="clear">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-toolbar>

        <ion-toolbar *ngIf="!!appConfig.showMenuGlobalSearch">
          <ion-searchbar #globalSearchInput [placeholder]="'search_global_placeholder'|translate" [(ngModel)]="search.query" (ionChange)="runSearch($event)"></ion-searchbar>
        </ion-toolbar>

      </ion-header>

      <ion-content fullscreen="true">

        <ion-list lines="none" *ngIf="((view.isDesktop && appConfig.showTabBarInMenuOnWeb) || (!view.isDesktop && appConfig.showTabBarInMenuOnApp)) && view.webPages && view.webPages.length">
          
          <ion-menu-toggle auto-hide="false" *ngFor="let p of view.webPages; let i = index" (click)="onMenuToggleClick(p)" [hidden]="p.uid == 'profile'">
            <ion-item button (click)="onWebPageClick(p, i, $event)" routerDirection="none" [routerLink]="[p.url || p.route]" lines="none" detail="false">
              <ion-icon slot="start" [name]="p.icon" color="dark"></ion-icon>
              <ion-label class="ion-text-wrap" [innerHTML]="p.name|translate"></ion-label>
              <ion-badge *ngIf="p.badge != null" shape="round" [innerHTML]="p.badge" color="primary"></ion-badge>
            </ion-item>
          </ion-menu-toggle>
          
        </ion-list>

        <ion-list lines="none">
          <div *ngFor="let p of appPages; let i = index" (click)="onMenuToggleClick(p)" [hidden]="(p.hideIfLoggedOut && (!user.uid || (user.uid === -1))) || (p.hideIfLoggedIn && !!user.uid && (user.uid !== -1)) || (p.allowedUserTypes && (!user || !user.classifications || (user.classifications && !user.classifications.type) || (user.classifications && user.classifications.type && p.allowedUserTypes.indexOf(user.classifications.type) === -1)))">
            
            <!-- primary item-->
            <ion-item button (click)="onAppPageClick(p, i, $event)" [disabled]="p.disabled" routerDirection="none" [routerLink]="[p.url || p.route]" lines="none" detail="false">
              <ion-icon slot="start" [name]="p.icon" color="dark"></ion-icon>
              <ion-label class="ion-text-wrap" [innerHTML]="p.key|translate"></ion-label>
              <ion-badge *ngIf="p.badge != null" shape="round" [innerHTML]="p.badge" color="primary"></ion-badge>
              <ion-icon *ngIf="!!p.children && p.children.length" slot="end" [name]="p.active ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
            </ion-item>

            <!-- sub items -->
            <div *ngIf="!!p.children && p.children.length" [hidden]="!p.active">
              <ion-item *ngFor="let child of p.children; let j = index;" [disabled]="p.disabled" (click)="onAppPageClick(child, j, $event)" routerDirection="none" [routerLink]="[child.url || child.route]" lines="none" detail="false">
                <ion-icon slot="start" [name]="child.icon" color="dark"></ion-icon>
                <ion-label class="ion-text-wrap" [innerHTML]="child.key|translate"></ion-label>
                <ion-badge *ngIf="child.badge != null" shape="round" [innerHTML]="child.badge" color="primary"></ion-badge>
                <ion-icon *ngIf="!!child.children && child.children.length" slot="end" [name]="child.active ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
              </ion-item>
            </div>

          </div>
        </ion-list>

        <ion-list lines="none">

          <ion-item button (click)="interests()" *ngIf="appConfig.useInterests">
            <ion-icon name="heart-outline" slot="start" color="dark"></ion-icon>
            <ion-label [innerHTML]="'interests'|translate"></ion-label>
          </ion-item>

          <ion-item button (click)="postsAdmin()" *ngIf="appConfig.usePostsAdmin && userService.isType('Admin')">
            <ion-icon name="checkmark-done-outline" slot="start" color="dark"></ion-icon>
            <ion-label [innerHTML]="'posts_admin'|translate"></ion-label>
          </ion-item>

          <ion-item button (click)="settings()" *ngIf="appConfig.useSettings">
            <ion-icon name="settings-outline" slot="start" color="dark"></ion-icon>
            <ion-label [innerHTML]="'settings'|translate"></ion-label>
          </ion-item>

          <ion-item button (click)="feedback()" *ngIf="appConfig.useFeedback">
            <ion-icon name="flag-outline" slot="start" color="dark"></ion-icon>
            <ion-label [innerHTML]="'feedback'|translate"></ion-label>
          </ion-item>

          <ion-list-header>
            <ion-label [innerHTML]="'legal'|translate"></ion-label>
          </ion-list-header>

          <ion-item button (click)="agb()">
            <ion-icon slot="start" name="document-text-outline" color="dark"></ion-icon>
            <ion-label class="ion-text-wrap">
              <p [innerHTML]="'agb'|translate"></p>
            </ion-label>
          </ion-item>

          <ion-item button (click)="privacyPolicy()">
            <ion-icon slot="start" name="document-text-outline" color="dark"></ion-icon>
            <ion-label class="ion-text-wrap">
              <p [innerHTML]="'privacy_policy'|translate"></p>
            </ion-label>
          </ion-item>

          <ion-item button (click)="imprint()">
            <ion-icon slot="start" name="document-text-outline" color="dark"></ion-icon>
            <ion-label class="ion-text-wrap">
              <p [innerHTML]="'imprint'|translate"></p>
            </ion-label>
          </ion-item>

        </ion-list>

      </ion-content>
      
      <ion-footer class="ion-no-border" color="light">

        <ion-toolbar class="ion-no-border">
          <span class="version" *ngIf="appConfig.showFooterBottomText">
            <b *ngIf="appConfig.showFooterAppVersion && view.appVersion" [innerHTML]="('version'|translate) + ' ' + view.appVersion"></b>
            <br>
            <small [innerHTML]="(appConfig.footerBottomText || 'footer_love')|translate"></small>
          </span>
        </ion-toolbar>

        <ion-toolbar color="light" *ngIf="appConfig.useShop" [hidden]="!basketInfo || !basketInfo.items">
          <ion-list color="light">
            <ion-menu-toggle *ngIf="user && user.uid" auto-hide="false">
              <ion-item lines="none" (click)="basket()" color="light">
                <ion-label>
                  <h3 [innerHTML]="'basket'|translate"></h3>
                  <p>
                    <small [innerHTML]="(basketInfo.items || ('no_2'|translate)) + ' ' + (basketInfo.items === 1 ? ('product'|translate) : ('products'|translate))"></small>
                  </p>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-toolbar>
      </ion-footer>

    </ion-menu>
    
    <ion-router-outlet id="main-content" swipeGesture="false">
      <div id="ion-router-outlet-content"></div>
    </ion-router-outlet>

  </ion-split-pane>
</ion-app>
