import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private events: EventsService,
    public menu: MenuController,
  ) {
    
  }

  async close(blForce: boolean = false) {
    if(blForce || (window.innerWidth <= 768)) {
      try {
        if(await this.menu.isOpen()) {
          this.menu.close();
        }
      } catch(e) {
        console.warn('> menu: close error', e);
      }
    }
  }

  enable() {
    this.events.publish('menu:show');

    //if(window.innerWidth > 768) {
      //this.open();
    //}
  }

  hide() {
    this.events.publish('menu:hide');
  }

  isOpen() {
    return this.menu.isOpen();
  }

  open() {
    return this.menu.open();
  }
  
  toggle() {
    return this.menu.toggle();
  }
  
}
