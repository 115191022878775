import { Injectable } from '@angular/core';
import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from 'src/app/services/core/events.service';
import { SettingsService } from 'src/app/services/core/settings.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class TestingService {

  assignToRandomGroupBlocked: boolean = false;

  group: testingGroup;

  groups: testingGroup[] = [
    {
      uid: 1,
      indent: 'group1',
      config: {
        post_web_template: 'preview',
        show_download_card: true,
        show_tab_menu: true,
      },
    },
    {
      uid: 2,
      indent: 'group2',
      config: {
        post_web_template: 'download_wall',
        show_download_card: false,
        show_tab_menu: true,
      },
    },
    {
      uid: 3,
      indent: 'group3',
      config: {
        post_web_template: 'preview_register',
        show_download_card: false,
        show_tab_menu: true,
      },
    },
  ];

  key: string = 'testing_group';

  constructor(
    private cache: CacheService,
    private events: EventsService,
    private settings: SettingsService,
    public userService: UserService,
  ) {

  }

  assignToRandomGroup() {
    let randomGroup = this.getRandomGroup();

    if(this.assignToRandomGroupBlocked) {
      return false;
    }

    this.assignToRandomGroupBlocked = true;
    this.setGroup(randomGroup);

    setTimeout(async () => {
      this.assignToRandomGroupBlocked = false;
    }, 2000);
  }

  getAllGroups() {
    return this.groups;
  }

  getGroupByIndent(groupIndent: string) {
    let filter = this.groups.filter((group: testingGroup) => {
      return group.indent === groupIndent;
    });
    return filter && filter[0] ? filter[0] : null;
  }

  async getCurrentGroup(blFallback: boolean = true) {
    let fromCache: cacheItem = await this.cache.get(this.key, 60 * 60);
    
    if(!this.group && fromCache && fromCache.data) {
      this.group = JSON.parse(fromCache.data);
    }

    if(!this.group && blFallback) {
      return this.getRandomGroup();
    }

    return this.group;
  }

  async getCurrentGroupConfig(blFallback: boolean = true) {
    let group = await this.getCurrentGroup();

    if(!group && blFallback) {
      group = this.getRandomGroup();
    }

    return group.config;
  }

  async getCurrentGroupIndent(blFallback: boolean = true) {
    let group = await this.getCurrentGroup();

    if(!group && blFallback) {
      group = this.getRandomGroup();
    }

    return group.indent;
  }

  getRandomGroup() {
    let groups = this.getAllGroups();
    return groups[Math.floor(Math.random() * groups.length)];
  }

  init() {
    let user: user = this.userService.getUser();

    if(user) {
      this.onUserUpdated(user);
    } else
    if(!this.group) {
      this.assignToRandomGroup();
    }

    this.events.subscribe('appcms:user:updated', (user: user) => {
      this.onUserUpdated(user);
    });
  }

  isBetaTester() {
    return !!this.userService.getClassification('isBetaTester');
  }

  async onUserUpdated(user: user) {
    if(user && user.uid) {
      let serverSideGroup: string = (await this.settings.getSetting(this.key) as string);

      if(serverSideGroup) {
        let groupByIndent = this.getGroupByIndent(serverSideGroup);
  
        if(groupByIndent) {
          this.group = groupByIndent;
        } else {
          this.assignToRandomGroup();
        }
  
      } else {
        this.assignToRandomGroup();
      }
    }
  }

  setGroup(group: testingGroup) {
    this.group = group;
    this.cache.set(this.key, this.group);
    this.events.publish('testing:group:updated', this.getCurrentGroup());

    return this.syncUserTestingGroup();
  }

  async syncUserTestingGroup() {
    let user = this.userService.getUser();

    if(user && user.uid && (user.uid !== -1)) {
      let settings = await this.settings.getSettings();
      settings[this.key] = this.group.indent;

      return this.settings.updateSettings(settings);
    }

  }

}
