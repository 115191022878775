import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import OneSignal from 'onesignal-cordova-plugin';
import * as moment from 'moment';

import { BadgeService } from 'src/app/services/utils/badge.service';
import { CategoriesService } from 'src/app/services/pipeline/categories.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { EventsService } from 'src/app/services/core/events.service';
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class PushnotificationsService {

  deviceState: any = {};

  key: string = 'notificationList';

  notificationsSettings: notificationsSettings;

  pushAppId: string = 'cd36525a-889e-4aad-89d5-0d9d127b7df5';

  pushFirebaseKey: string = '567674698014';

  pushInited: boolean = false;

  constructor(
    private AppCMS: AppcmsService,
    private badge: BadgeService,
    private categories: CategoriesService,
    private cache: CacheService,
    private configService: ConfigService,
    private events: EventsService,
    private navCtrl: NavController,
    private posts: PostsService,
    private tools: ToolsService,
    public userService: UserService,
  ) {

    this.events.subscribe('appcms:user:updated', (user: user) => {
      this.validateAndUpdatePushSettings(user);
    });

  }

  addNotification(notification: any) {
    return new Promise((resolve, reject) => {
      this.badge.increase();

      this.getAll()
        .then((list: notification[]) => {
          list = list || [];
          list.push(notification);
          this.setAllNotifications(list);
          resolve(list);
        })
        .catch(reject);
    });
  }

  createInboxMessage(notification: any) {
    this.events.publish('message:send', {
      description: notification.contents.en,
      partner: notification.data.parent,
    });
  }

  createNotification(notification: notification) {
    return this.AppCMS.loadPluginData('pipeline', {
      notification: notification
    }, ['notifications', 'create']);
  }

  // @todo
  // This method should only return the local notifications the device received instead of looking up for remote messages
  getAll(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
    });
  }

  getCachedNotifications() {
    return this.cache.get(this.key);
  }

  getDeviceState() {
    return this.deviceState;
  }

  getFullNotification(notification: notification) {
    return new Promise((resolve, reject) => {
      notification.uid = parseInt(notification.uid + '');

      if (notification && notification.value && typeof notification.value === 'string') {
        notification = Object.assign(notification, JSON.parse(notification.value));
      }

      if (notification && notification.payload && notification.payload.rawPayload) {
        notification = notification.payload.rawPayload;
      }

      if ((notification.custom && notification.custom.a) && notification.aps && notification.aps.alert) {
        notification.aps.alert = Object.assign((notification.aps.alert || {}), (notification.custom.a || {}));
      }

      if (notification.aps && notification.aps.alert) {
        notification = notification.aps.alert;
      }

      if (notification.timestamp) {
        let timestamp = typeof notification.timestamp === 'number' ? moment.unix(notification.timestamp) : moment(notification.timestamp, 'YYYY-MM-DD HH:mm'),
          timestampFormatted = timestamp.format('DD.MM.') != moment().format('DD.MM.') ? timestamp.format('DD.MM.') : timestamp.format('HH:mm');
        notification.timestamp_formatted = timestampFormatted;
      }

      if (notification.action && notification.action === 'viewPost' && notification.item && typeof notification.item === 'number') {
        this.posts.getPostByUid(notification.item)
          .then((post: post) => {
            notification.item = post;
            resolve(notification);
          })
          .catch((error: any) => {
            console.warn('get post error', error);
            resolve(notification);
          });
      } else {
        resolve(notification);
      }

    });
  }

  getNotificationsSettings() {
    return new Promise(async (resolve, reject) => {
      let fromCache: cacheItem = await this.cache.get('notificationsSettings', -1);

      if (fromCache && fromCache.data) {
        resolve(fromCache.data as notificationsSettings);
      } else {
        resolve({} as notificationsSettings);
      }
    });
  }

  getPushAppId() {
    let configAppId: string = this.configService.getPushAppId();
    return (configAppId || this.pushAppId);
  }

  getPushFirebaseKey() {
    return this.pushFirebaseKey;
  }

  getPushTargetGroups(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['notifications', 'target_groups'], null, blForceRefresh);
  }

  init() {

    this.events.subscribe('push:send', (config: any) => {
      this.send(config)
        .catch((error: any) => {
          console.warn('push: send event error (2)', error);
        });
    });

    try {
      let pushAppId: string = this.getPushAppId();

      if (!pushAppId) {
        console.warn('[PUSH] No App ID found!');
        return false;
      }

      if (this.tools.isWeb()) {
        console.warn('push in web not supported');
        return false;
      }

      //OneSignal.setLogLevel(6, 0);
      
      //OneSignal.setAppId(pushAppId);
      //OneSignal.init(pushAppId);

      let myClickListener = async function(event) {
        let notificationData = JSON.stringify(event);
        console.log('> notificationData', notificationData);
      };
      
      OneSignal.Notifications.addEventListener("click", myClickListener);    

      OneSignal.Notifications.requestPermission(true).then((accepted: boolean) => {
        if (!!accepted) {
          this.initPushToken();
        }
      });

      /*
      OneSignal.setNotificationOpenedHandler((event: any) => {
        this.onNotificationOpened(event);
      });
      */

    } catch (e) {
      console.warn('OneSignal init error', e);
    }

  }

  initPushToken() {
    try {
      (window as any).plugins.OneSignal.getDeviceState((stateChanges: any) => {
        console.log('> push: stateChanges', stateChanges);
        this.updatePushDeviceState(stateChanges);
      });
    } catch(e) {
      console.warn('init push token error', e);
    }
  }

  onNotificationClick(notification: notification) {
    if (notification.action) {
      switch (notification.action) {
        case 'route':
          if (notification.item) {
            this.navCtrl.navigateForward(notification.item);
          }
          break;
        case 'viewPost':
          if (notification.item) {
            this.events.publish('view:post', notification.item);
          }
          break;
        case 'viewProfile':
          if (notification.user) {
            this.events.publish('view:profile', notification.user);
          }
          break;
        default:
          //console.log('unknown action', notification.action);
          break;
      }
    }
  }

  onNotificationOpened(event: any) {
    try {
      event.notification = this.getFullNotification(event.notification);

      this.addNotification(event.notification)
        .catch((error) => {
          console.warn('add notification error', error);
        });

      return this.onNotificationClick(event.notification);
    } catch (e) {
      console.warn('onNotificationOpened error', e);
    }
  }

  send(config: any) {
    return new Promise(async (resolve, reject) => {
      try {

        let notification: any = {
          "app_id": this.getPushAppId(),
          "include_player_ids": (config.include_player_ids || (config.recipients || [])),
          "data": config.data || {},
          "headings": config.headings || { "en": config.title },
          "contents": config.contents || { "en": this.tools.stripHtml(config.description) }
        };

        let cacheKey: string = 'notification_' + JSON.stringify(notification),
          fromCache: cacheItem = await this.cache.get(cacheKey, 15 * 60);

        if (fromCache && fromCache.data) {
          reject('error_already_send');
        } else {

          let user = this.userService.getUser() || {};

          if (config.data && config.data.user && (config.data.user === user.uid) && (user.photo && user.photo.length)) {
            notification.large_icon = user.photo;
            notification.small_icon = user.photo;
          }

          /*
          OneSignal.postNotification(
          notification,
            (response: any) => {
              this.cache.set(cacheKey, true);
              resolve(response);
            },
            (r: any) => {
              console.warn(r);
              reject(r.errors ? r.errors[0] : ((r.error || (r.errorMessage || r.message)) || 'Ein unbekannter Fehler ist aufgetreten'));
            }
          );
          */
        }

      } catch (e) {
        reject(e);
      }
    });
  }

  sendTag(tag: string, value: any) {
    try {
      let tags = {};
      tags[tag] = value;

      //(window as any).plugins.OneSignal.sendTags(tags);
    } catch (e) {
      console.warn('sendTag error', e);
    }
  }

  sendTags(tags: any) {
    /*
    try {
      (window as any).plugins.OneSignal.sendTags(tags);
    } catch (e) {
      console.warn('sendTag error', e);
    }
    */
  }

  setAllNotifications(notifications: any[]) {
    this.cache.set(this.key, notifications);
  }

  setNotificationSettings(notificationsSettings: notificationsSettings, blSyncUser: boolean = true) {
    this.notificationsSettings = notificationsSettings;
    this.cache.set('notificationsSettings', this.notificationsSettings);

    if (!!blSyncUser) {
      try {
        let user: user = this.userService.getUser();

        if(!!user && !!user.uid && (user.uid !== -1)) {
          user.classifications = user.classifications || {};
          user.classifications.pushSettings = user.classifications.pushSettings || {};
          user.classifications.pushSettings = Object.assign(user.classifications.pushSettings, notificationsSettings);
  
          this.userService.setUser(user, true);
        }
        
      } catch(e) {
        console.warn('syncing user failed', e);
      }
    }
  }

  setSubscription(bl: boolean) {
    try {
      //OneSignal.disablePush(!bl);
    } catch (e) {
      console.warn('setSubscription error', e);
    }
  }

  toggle() {
    return new Promise((resolve, reject) => {
      let user = this.userService.getUser() || {};

      if (!user || !user.uid) {
        reject('error_missing_user');
      }

      user.classifications = user.classifications || {};
      user.classifications.push = !user.classifications.push;

      if (!!user.classifications.push) {
        this.init();
        this.initPushToken();
      } else {
        this.setSubscription(false);
      }

      this.userService.setUser(user, true).then(resolve).catch(reject);
    });
  }

  updatePushDeviceState(deviceState: any) {
    try {
      this.deviceState = deviceState;
      console.log('> push: deviceState', deviceState);

      if (!!deviceState.userId) {
        this.updatePushUserId(deviceState.userId);
      }

      //console.log('OneSignal getDeviceState: ' + JSON.stringify(deviceState));
    } catch (e) {
      console.warn('updatePushDeviceState error', e);
    }
  }

  updatePushUserId(userId: string) {
    let user = this.userService.getUser() || {};

    if (user && !!user.uid && (user.uid !== -1)) {
      user.classifications = user.classifications ? user.classifications : {};
      if (userId != user.classifications.pushId) {
        let appPackageName: string = this.configService.getAppPackageName();
        user.classifications.appPackageName = appPackageName;

        user.classifications.push = (user.classifications.hasOwnProperty('push') ? !!user.classifications.push : true);
        user.classifications.pushId = userId;

        user.classifications.pushIds = user.classifications.pushIds || {};
        user.classifications.pushIds[appPackageName] = userId;

        console.log('> push: update user', user);

        this.userService.setUser(user, true)
          .catch((error: any) => {
            console.warn('error', error);
          });
      }
    }
  }

  /**
   * @todo Also remove tags from OneSignal
   * @param user 
   */
  async validateAndUpdatePushSettings(user: user) {
    try {
      let categoryNames: any = (await this.categories.getSelectedCategoriesNames());
      let tags: any = {};

      if (categoryNames && categoryNames.length) {
        categoryNames.forEach((categoryName: string) => {
          tags[categoryName] = 1;
        });
        this.sendTags(tags);
      }

    } catch (e) {
      console.warn('validate push error', e);
    }

  }

}