<ion-content>
  <ion-list lines="none">

    <ion-item *ngIf="profile.me" [hidden]="!profile.me">
      <ion-label class="ion-text-wrap" [innerHTML]="'public_visible'|translate"></ion-label>
      <ion-checkbox slot="end" [(ngModel)]="profile.public" (ionChange)="updatePublic()"></ion-checkbox>
    </ion-item>

    <ion-item button (click)="shareProfile(profile)" *ngIf="config.useSharingExtension">
      <ion-icon name="share-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'share_account'|translate"></ion-label>
    </ion-item>

    <ion-item [hidden]="profile.me" (click)="blockProfile(profile)">
      <ion-icon name="hand-left-outline" slot="start"></ion-icon>
      <ion-label color="warning" [innerHTML]="'block_account'|translate"></ion-label>
    </ion-item>

    <ion-item [hidden]="profile.me" (click)="requestHelp(profile)" *ngIf="config.useReportingExtension">
      <ion-icon name="warning-outline" slot="start"></ion-icon>
      <ion-label color="danger" [innerHTML]="'request_help'|translate"></ion-label>
    </ion-item>

  </ion-list>
</ion-content>
