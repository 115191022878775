import { Injectable } from '@angular/core';
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  blIsModal: boolean;
  
  current: page;

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
  ) {
  }

  create(page: page) {
    return this.AppCMS.loadPluginData(
      'pages',
      {
        page: page
      },
      ['create'],
    );
  }

  getAll(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let key = 'pages', fromCache: cacheItem = await this.cache.get(key, 60 * 60);
      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('pages', {})
        .then((pages: page[]) => {
          this.cache.set(key, pages);
          resolve(pages);
        })
        .catch(reject);
      }
    });
  }

  getByUid(uid: number, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let key = 'pages_' + uid, fromCache: cacheItem = await this.cache.get(key, 60 * 60);
      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('pages', {}, [uid])
        .then((page: page) => {
          this.cache.set(key, page);
          resolve(page);
        })
        .catch(reject);
      }
    });
  }

  getByCategory(categoryId: number) {
    return this.AppCMS.loadPluginData('pages', { category: categoryId });
  }

  getCurrent() {
    return this.current;
  }

  isModal(bl: boolean|null = null) {
    if(bl !== null) {
      this.blIsModal = bl;
    }
    return this.blIsModal;
  }

  setCurrent(page: page) {
    this.current = page;
  }

}
