import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { StoresService } from 'src/app/services/extensions/stores.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private stores: StoresService,
  ) {

  }

  get(filter: any = {}, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('companies', Object.assign(options, {
      filter: filter,
    }), [], null, blForceRefresh);
  }

  getByUid(storeId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('companies', {}, [storeId], null, blForceRefresh);
  }

  pick() {
    return new Promise(async (resolve, reject) => {
      let chooseConfig: chooseConfig = {
        //data: await this.get(),
        labelKey: 'name',
        service: this,
        valueKey: 'uid',
      };
      this.chooser.choose(chooseConfig)
      .then((chooseResponse: chooseResponse) => {
        resolve(chooseResponse);
      })
      .catch(reject);
    });
  }

  search(query: string, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('companies', {
      query: query,
    }, ['search'], null, blForceRefresh);
  }

}
