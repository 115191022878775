import { Component, OnInit, ViewChild, NgZone, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { IonContent, NavParams, NavController, AlertController, PopoverController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

//import { StatusBar, Style } from '@capacitor/status-bar';

import { EventsService } from 'src/app/services/core/events.service';
import { ProfileService } from 'src/app/services/social/profile.service';
import { UserService } from 'src/app/services/core/user.service';
import { ExternalService } from 'src/app/services/core/external.service';
import { StateService } from 'src/app/services/core/state.service';
import { FontsService } from 'src/app/services/extensions/fonts.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { BrowserService } from 'src/app/services/utils/browser.service';
import { ScoreService } from 'src/app/services/pipeline/score.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { CookiesService } from 'src/app/services/core/cookies.service';
import { ScrollspyService } from 'src/app/services/utils/scrollspy.service';
import { ModalService } from 'src/app/services/core/modal.service';

import { PostInformationPage } from '../post-information/post-information.page';
import { RatingsService } from 'src/app/services/social/ratings.service';
import { WebService } from 'src/app/services/core/web.service';
import { LanguageService } from 'src/app/services/core/language.service';
import { LogfileService } from 'src/app/services/utils/logfile.service';
import { LoginService } from 'src/app/services/core/login.service';
import { BlogAdminService } from 'src/app/services/core/blog-admin.service';
import { AdminService } from 'src/app/services/core/admin.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { BionicReadingService } from 'src/app/services/extensions/bionic-reading.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.page.html',
  styleUrls: ['./post.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostPage implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('headlineToolbar', {read: ElementRef}) headlineToolbar: ElementRef;
  @ViewChild('hostItem', {read: ElementRef}) hostItem: ElementRef;
  @ViewChild('post', {read: ElementRef}) postPage: ElementRef;

  allowWithoutLogin: boolean;

  appConfig: pipelineAppConfig;

  commentsConfig: postCommentsConfig = {
    showInputToolbar: true,
  };

  modal: any;

  post: post;

  posts: post[];
  postsByCategory: post[];

  user: user;

  view: any = {
    activeIndex: 0,
    canBack: false,
    canNext: false,
    footer: {
      bottom: 0,
    },
    header: {
    },
    headerOpacity: 0,
    showReactionsCard: false,
    size: 2,
  };

  constructor(
    private admin: AdminService,
    private alertCtrl: AlertController,
    private bionicReading: BionicReadingService,
    private blogAdmin: BlogAdminService,
    private browser: BrowserService,
    private changeDetectorRef: ChangeDetectorRef,
    public configService: ConfigService,
    private cookies: CookiesService,
    private events: EventsService,
    private external: ExternalService,
    private fonts: FontsService,
    private language: LanguageService,
    private log: LogfileService,
    public loginService: LoginService,
    private modalService: ModalService,
    private navParams: NavParams,
    private navCtrl: NavController,
    private profile: ProfileService,
    private popoverCtrl: PopoverController,
    private postsService: PostsService,
    private ratings: RatingsService,
    private score: ScoreService,
    private scrollspy: ScrollspyService,
    public state: StateService,
    private tools: ToolsService,
    private zone: NgZone,
    public userService: UserService,
    private _sanitizer: DomSanitizer,
    private webService: WebService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.allowWithoutLogin = (this.appConfig.showLoginPage === false) || this.navParams.get('allowWithoutLogin');

    this.user = this.userService.getUser() || {};

    this.view.isPreviewMode = !!(this.navParams.get('mode') === 'preview');
    this.view.admin = this.navParams.get('admin') || this.userService.isType('Admin');
    
    if(!!(this.user && this.user.uid)) {
      this.view.readerFont = this.fonts.getReaderFont();  
    }
    
    this.post = this.postsService.getFullPost(this.navParams.get('post'));
    this.post.allowed = this.view.admin || this.state.isPostAllowed(this.post);
    this.posts = [this.post];
  }

  accept() {
    this.blogAdmin.accept(this.post)
    .then((response: any) => {
      this.events.publish('toast', {
        message: 'Beitrag akzeptiert',
        color: 'primary',
      });
      this.dismiss();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  addToCollection() {
    this.post.added = true;
    this.profile.addToCollection(this.post, 'posts')
    .then((response: any) => {
      this.post.added = response.success;
      this.detectChanges();
    })
    .catch((error: any) => {
      this.post.added = false;
      this.detectChanges();

      if(error !== 'user_cancelled') {
        this.events.publish('error', error);
      }
    });
  }

  back() {
    this.view.activeIndex = this.view.activeIndex || 0;
    this.view.activeIndex--;

    if(this.posts[this.view.activeIndex]) {
      this.post = this.posts[this.view.activeIndex];
    }

    this.detectChanges();
  }

  calcViewVars() {
    this.zone.run(() => {
      setTimeout(() => {
        let headlineToolbarHeight = this.headlineToolbar && this.headlineToolbar.nativeElement ? this.headlineToolbar.nativeElement.clientHeight : 0;
        let hostItemHeight = this.hostItem && this.hostItem.nativeElement ? this.hostItem.nativeElement.clientHeight : 0;
        this.view.contentPaddingTop = (headlineToolbarHeight - (15 - hostItemHeight)) + 'px';
      });
    });
  }

  detectChanges(blFireEvent: boolean = true) {
    this.zone.run(() => {

      if(!!blFireEvent) {
        this.events.publish('post:view:updated', this.view);
      }

      this.changeDetectorRef.detectChanges();
    });
  }

  dismiss(event: any = null) {
    
    if(event && event.target) {
      event.target.complete();
    }

    this.modalService.dismiss();

    setTimeout(() => {
      let uid: number = this.userService.getUid();
  
      if((!uid || (uid === -1)) && !!this.appConfig.showLoginPage) {
        this.navCtrl.navigateRoot('/login');
      }
      
      this.stopTTS();
    }, 500);
  }
  
  async editPost() {

    if(!this.userService.isType('Admin')) {
      this.navCtrl.navigateForward('/create-contents-banner');
      return false;
    }

    this.admin.editPost(this.post);
  }
  
  hideUI() {
    this.view.footer.bottom = -100;
    this.view.header.top = -100;
    this.detectChanges();
  }

  async information(event: any = null) {
    let informationPopup = await this.popoverCtrl.create({
      animated: true,
      component: PostInformationPage,
      componentProps: {
        post: this.post,
      },
      cssClass: 'postInformationPopover',
      event: event,
    });
    informationPopup.present();
  }

  initEvents() {

    if(this.view.eventsInitialized) {
      console.log('> post page: events already initialized');
      return false;
    }

    this.view.eventsInitialized = true;

    this.events.subscribe('post:reader:update', (post: post) => {
      if(!!post) {
        this.post = post;
        this.loaded();
      }
    });

    this.events.subscribe('post:next', () => {
      this.next();
    });

    this.events.subscribe('post:back', () => {
      this.back();
    });

    this.events.subscribe('post:page:update', (post: post) => {
      this.post = post;
      this.loaded(false);
    });

    this.events.subscribe('post:preview:update', (post: post) => {
      this.post = post;
      this.loaded();
    });

    this.events.subscribe('post:reader:font:size:updated', (iSize: number) => {
      this.view.size = iSize;
      this.detectChanges();
    });
    
    this.events.subscribe('post:reader:dismiss', () => {
      this.dismiss();
    });

    this.events.subscribe('post:render', () => {
      this.detectChanges();
    })

    if(this.appConfig.useBionicReadingExtension) {
      this.events.subscribe('bionic-reading:toggled', (bl: boolean) => {
        this.toggleBionicReading(bl);
      });
    }
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};
    
    if(this.user && this.user.classifications && this.user.classifications.language) {
      this.view.language = this.user.classifications.language;
    }

    this.view.admin = this.navParams.get('admin') || this.userService.isType('Admin');
    this.view.isWeb = this.tools.isWeb();

    if(this.view.admin || this.userService.isType('Creator') || this.userService.isType('Moderator')) {
      this.view.short = false;
      this.detectChanges();
    } else
    if(this.view.isWeb && !this.view.isPreviewMode) {
      this.view.short = !!this.userService.getUid() && !!this.appConfig.showPostReadInAppCard;
      this.cookies.showCookieBanner();
      this.detectChanges();
    }

    if(!this.userService.getUid() && !this.allowWithoutLogin) {
      this.navCtrl.navigateRoot('/login', { animated: false, });
    } else
    if(this.user && this.user.uid && (this.user.uid !== -1)) {
      this.loadPostInfo();
    }

    setTimeout(() => {
      this.zone.run(() => {
        if(this.post) {
          this.loaded();

          try {
            if(!!this.post.user) {
              this.log.countProfileView(this.post.user);
            }
          } catch(e) {
            console.warn('log profile view error', e);
          }

        }
      });
    }, 50);

    this.calcViewVars();
    
    /*
    try {
      StatusBar.setStyle({ style: Style.Light });
    } catch(e) {
      console.warn('Statusbar error', e);
    }
    */

    this.detectChanges();
  }

  ionViewWillLeave() {
    /*
    try {
      StatusBar.setStyle({ style: Style.Default });
    } catch(e) {
      console.warn('Statusbar error', e);
    }
    */
    this.logClose();
  }

  ionViewDidEnter() {
    this.zone.run(async () => {
      let state = await this.state.get() as state;
      state.canBack = false;
      state.canNext = true;
      this.state.set(state);
    });
  }

  loaded(blFireEvent: boolean = true) {
    this.post.post_content_formatted = this._sanitizer.bypassSecurityTrustHtml(this.post.post_content);
    this.detectChanges(blFireEvent);
  }

  loadPost(postId: number = null) {
    this.postsService.getPostByUid((postId || this.post.uid), {
      language: this.view.language,
    })
    .then((post: post) => {
      this.post = post;
      this.posts = [this.post];
      this.loaded();

      this.events.publish('post:reader:updated', this.post);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  loadPostInfo() {
    if(this.user && this.user.uid && this.post && this.post.uid) {
      this.postsService.getPostInfo(this.post.uid)
      .then((postInfo: postInfo) => {
        this.post.added = postInfo.inCollection;
        this.detectChanges();
      })
      .catch((error: any) => {
        this.events.publish('toast', {
          message: error,
          color: 'primary',
        });
      });
    }
  }

  logClose() {
    /*
    this.log.add({
      class: 'post_close_' + this.post.uid + '_' + this.user.uid,
      group: 'post_close_byUser_' + this.user.uid,
      description: (this.user.nickname || this.user.firstname) + ' hat den Beitrag "' + this.post.name + '" geschlossen',
      inAppLink: this.post.uid,
      url: this.post.url,
    })
    .catch((error: any) => {
      console.warn('log error:', error);
    });
    */
  }

  async loadBionicReadingState() {
    this.view.enableBionicReading = await this.bionicReading.isEnabled();

    if(!!this.view.enableBionicReading) {
      this.toggleBionicReading(this.view.enableBionicReading);
    }
  }

  next() {
    this.view.activeIndex = this.view.activeIndex || 0;
    this.view.activeIndex++;

    if(this.posts[this.view.activeIndex]) {
      this.post = this.posts[this.view.activeIndex];
    }

    this.detectChanges();
  }

  ngOnInit() {
    this.initEvents();
    this.loadBionicReadingState();
  }

  onScroll(event: any) {
    this.zone.run(() => {
      this.view.scrollDirection = (event.detail.scrollTop > this.view.scrollTop) ? 1 : 0;
      this.view.scrollTop = event.detail.scrollTop;

      if(this.view.scrollDirection) {
        this.hideUI();
      } else {
        this.showUI();
      }

      let maxScroll = window.outerHeight * 0.25,
          scrollTop = event.detail.scrollTop,
          offset = scrollTop > maxScroll ? maxScroll : scrollTop;

      this.view.headerOpacity = (1 - ((100 - ((100 / maxScroll) * offset)) / 100)).toFixed(3);
      this.detectChanges();
    });
  }

  // log the scroll position to AI
  async onScrollEnd() {
    let postPage = document.getElementById('post');

    if(!!postPage) {

      try {

        let log: scrollspyData = await this.scrollspy.log('post', {
          uid: this.post.uid,
          container: postPage,
          scrollTop: this.view.scrollTop + (window.outerHeight * 0.25),
          textLength: this.post.post_content.length,
        });

        let blIsEnd = (log && log.percentage && (log.percentage > 90)),
          blShowReactionsCard = !!(this.userService.getUid() && blIsEnd);

        if(blIsEnd) {
          this.score.scoreReadArticle(this.post)
            .catch((error) => {
              console.warn('score error', error);
            });
        }

        if(blShowReactionsCard !== this.view.showReactionsCard) {
          this.view.showReactionsCard = blShowReactionsCard;
          this.detectChanges();
        }

      } catch(e) {
        console.warn('> onScrollEnd e', e);
      }

    }
  }

  // opens the content in browser
  openExternal() {
    this.stopTTS();

    if(!this.post.url) {
      return this.openProfile();
    }
    
    this.browser.create(this.post.url);
  }

  openProfile() {
    if(!!this.post.user) {
      this.events.publish('view:profile', this.post.user);
    }
  }

  async rate() {
    
    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    // show rate modal
    this.ratings.rate(this.post)
    .then((response: any) => {
      //console.log('rating response', response);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  reject() {
    this.external.reject(this.post)
    .then((response: any) => {
      this.events.publish('toast', {
        message: 'Beitrag abgelehnt',
        color: 'primary',
      });
      this.dismiss();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  removeFromCollection() {
    this.profile.removeFromCollection(this.post, 'posts')
    .then((response: any) => {
      if(response.success) {
        this.post.added = false;
        this.detectChanges();
      }
    })
    .catch((error: any) => {
      this.post.added = true;
      this.detectChanges();
      this.events.publish('error', error);
    });
  }

  showUI() {

    if(this.view.hideUITimeout) {
      clearTimeout(this.view.hideUITimeout);
    }

    this.view.footer.bottom = 0;
    this.view.header.top = 0;
    this.detectChanges();
  }

  stopTTS() {
    this.events.publish('tts:stop');
  }

  swipeEvent(event: any) {
    //console.log('swipeEvent', event);
  }

  thumbnailLoadingFailed(post: post) {
    console.log('> thumbnail loading failed for post', post);
  }

  toggleBionicReading(bl: boolean = false) {
    if(!!bl) {
      this.view.fallbackContent = `${this.post.post_content}`;

      this.post.loaded = false;
      this.detectChanges();

      this.bionicReading.convert(this.post.post_content)
      .then((response: any) => {
        if(!!response.output) {

          this.post.loaded = true;
          this.post.post_content = response.output;

          this.events.publish('post:content:update', this.post);
          this.events.publish('post:reader:update', this.post);
          this.loaded();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    } else
    if(!!this.view.fallbackContent) {
      this.post.post_content = this.view.fallbackContent;

      this.events.publish('post:content:update', this.post);
      this.events.publish('post:reader:update', this.post);
      this.loaded();
    }
  }

  toggleCollection() {
    let uid: number = this.userService.getUid();

    if(!uid || (uid === -1)) {
      this.loginService.show(true)
      .then(() => {
        this.user = this.userService.getUser();

        if(this.user && this.user.uid && (this.user.uid !== -1)) {
          if(this.post.added) {
            this.removeFromCollection();
          } else {
            this.addToCollection();
          }
        }
      })
      .catch((error: any) => {
        console.warn('> post: login error', error);
      });
    } else {
      if(this.post.added) {
        this.removeFromCollection();
      } else {
        this.addToCollection();
      }
    }

    this.detectChanges();
  }

  async toggleLanguage() {
    
    let languages: any = await this.language.getLanguages();
    
    let inputs = [];

    languages.forEach((language: language) => {
      inputs.push({
        type: 'radio',
        name: 'language',
        value: language.indent,
        label: language.name,
      });
    });

    let languageAlert = await this.alertCtrl.create({
      header: 'Sprache wählen',
      inputs: inputs,
      buttons: [
        {
          text: 'Okay',
          handler: (language: string) => {
            this.view.language = language;
            this.loadPost();
          },
        },
        {
          role: 'cancel',
          text: 'Abbrechen',
        }
      ]
    });
    await languageAlert.present();
  }

  toggleSponsoredInfoView() {
    this.view.showSponsoredInfo = !this.view.showSponsoredInfo;
  }

}
