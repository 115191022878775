<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-button (click)="runBackAction()" *ngIf="!!view.backAction">
        <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'back'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!view.backAction">
        <ion-icon name="close"></ion-icon>
      </ion-button>

    </ion-buttons>

    <ion-title [innerHTML]="view.title|translate"></ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="submitMultiple()" icon-only>
        <ion-icon name="checkmark-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <ion-toolbar class="ion-no-border container">
    <ion-searchbar [(ngModel)]="search.query" [placeholder]="'search'|translate" (ionInput)="runSearch()">
    </ion-searchbar>
  </ion-toolbar>

</ion-header>

<ion-content fullscreen="true">
  <div class="container">

    <ion-radio-group [(ngModel)]="view.item" *ngIf="view.data && view.data.length" [disabled]="!!view.multiple">
      <div class="cdk-wrapper">
        <cdk-virtual-scroll-viewport [itemSize]="view.itemSize" [minBufferPx]="view.itemSize * 25"
          [maxBufferPx]="view.itemSize * 25" class="ion-content-scroll-host">

          <div *cdkVirtualFor="let item of view.data" [hidden]="item.hidden" (click)="select(item)">
            <ion-item>
              
              <ion-icon *ngIf="!!item.icon" [name]="item.icon" slot="start"></ion-icon>

              <ion-thumbnail *ngIf="(item.photo || item.thumbnail) && (item.type !== 'video') && !item.videoSrc" slot="start">
                <ion-img [src]="(item.photo || item.thumbnail) || fallbackImg" (ionError)="thumbnailLoadingFailed(item)"></ion-img>
              </ion-thumbnail>
              
              <video *ngIf="!!item.guid || !!item.videoSrc" [poster]="fallbackImg" [src]="item.videoSrc || item.guid" playsinline webkit-playsinline [muted]="'muted'" loop autoplay></video>

              <ion-checkbox alignment="end" [(ngModel)]="item.checked" justify="space-between" labelPlacement="start" *ngIf="view.multiple" (click)="onMultiItemSelected(item)">
                <p [innerHTML]="((!!view.labelPrefix ? view.labelPrefix : '') + item[view.labelKey])|translate"></p>
                <small *ngIf="!!view.subLabelKey" [innerHTML]="((!!view.subLabelPrefix ? view.subLabelPrefix : '') + item[view.subLabelKey])|translate"></small>
              </ion-checkbox>
              
              <ion-radio alignment="end" (click)="select(item)" [value]="item[view.valueKey]" justify="space-between" *ngIf="!view.multiple" labelPlacement="start">
                <p [innerHTML]="((!!view.labelPrefix ? view.labelPrefix : '') + item[view.labelKey])|translate"></p>
                <small *ngIf="!!view.subLabelKey" [innerHTML]="((!!view.subLabelPrefix ? view.subLabelPrefix : '') + item[view.subLabelKey])|translate"></small>
              </ion-radio>
              
            </ion-item>
          </div>

        </cdk-virtual-scroll-viewport>
      </div>
    </ion-radio-group>

  </div>
</ion-content>

<ion-footer *ngIf="view.multiple">
  <ion-toolbar class="container">
    <ion-button (click)="toggleSelectAll()">
      <ion-label [innerHTML]="'select_all'|translate" [hidden]="view.allSelected"></ion-label>
      <ion-label [innerHTML]="'deselect_all'|translate" [hidden]="!view.allSelected"></ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>