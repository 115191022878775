import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  get(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('surveys', options, null, null, blForceRefresh)
      .then((surveys: survey[]) => {

        if(surveys && surveys.length) {
          surveys.forEach((survey: survey) => {
            survey = this.getFullSurvey(survey);
          });
        }

        console.log('> surveys', surveys);
        resolve(surveys);
      })
      .catch(reject);
    });
  }

  getByUid(surveyId: number, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('surveys', {}, [surveyId], null, blForceRefresh)
      .then((survey: survey) => {
        survey = this.getFullSurvey(survey);
        resolve(survey);
      })
      .catch(reject);
    });
  }

  getFullSurvey(survey: survey) {

    if(!!survey && !survey.hasOwnProperty('slides') && survey.hasOwnProperty('questions') && !!survey.questions) {
      survey.slides = this.parseQuestionsToSlides(survey.questions);
    }
    
    return survey;
  }

  parseQuestionsToSlides(questions: surveyQuestion[]) {
    let slides: surveySlide[] = [];

    if(questions && questions.length) {
      questions.forEach((question: surveyQuestion, index: number) => {
        slides.push({
          active: true,
          questions: [question],
          sort: index,
        });
      });
    }

    return slides;
  }

  submit(survey: survey) {
    return this.AppCMS.loadPluginData('surveys', {
      survey: survey,
    }, ['submit', survey.uid]);
  }

}
