import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { EventsService } from 'src/app/services/core/events.service';

import { ModalService } from 'src/app/services/core/modal.service';

@Component({
  selector: 'app-choose-action-sheet',
  templateUrl: './choose-action-sheet.page.html',
  styleUrls: ['./choose-action-sheet.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseActionSheetPage implements OnInit {

  fallbackImg: string = './assets/img/fallback.webp';
  
  search: searchOptions = {
    query: '',
  };

  view: any = {
    data: [],
    itemSize: 48,
    labelPrefix: '',
    multiple: false,
    templateView: {
      aspectRatio: '1x1',
      editable: false,
      infinite: true,
      running: true,
      //maxHeight: 300,
      maxWidth: 150,
    },
    title: 'choose',
    type: 'custom',
  };

  constructor(
    private changeDetector: ChangeDetectorRef,
    private events: EventsService,
    private modalCtrl: ModalService,
    private navParams: NavParams,
    private zone: NgZone,
  ) {
    let backAction: any = this.navParams.get('backAction');
    let data: any = this.navParams.get('data');
    let labelKey: any = this.navParams.get('labelKey');
    let labelPrefix: any = this.navParams.get('labelPrefix');
    let multiple: any = this.navParams.get('multiple');
    let title: any = this.navParams.get('title');
    let type: any = this.navParams.get('type');
    let service: any = this.navParams.get('service');
    let subLabelKey: any = this.navParams.get('subLabelKey');
    let subLabelPrefix: any = this.navParams.get('subLabelPrefix');
    let valueKey: any = this.navParams.get('valueKey');

    this.view.backAction = backAction || null;

    this.view.labelKey = labelKey || this.view.labelKey;
    this.view.labelPrefix = labelPrefix || this.view.labelPrefix;
    this.view.multiple = multiple;
    this.view.service = service || this.view.service;
    this.view.subLabelKey = subLabelKey || this.view.subLabelKey;
    this.view.subLabelPrefix = subLabelPrefix || this.view.subLabelPrefix;
    this.view.title = title || this.view.title;
    this.view.type = type || this.view.type;
    this.view.valueKey = valueKey || this.view.valueKey;

    this.view.data = this.prepare(data || this.view.data);
  }

  detectChanges() {
    this.zone.run(() => {
      this.changeDetector.detectChanges();
    });
  }

  public async dismiss(data: any = null) {
    (await this.modalCtrl).dismiss(data);
  }

  ionViewWillEnter() {
    this.detectChanges();
  }

  ngOnInit() {
  }

  onMultiItemSelected(item: any) {
    item.checked = !item.checked;
  }

  prepare(data: any[]) {
    
    if(!!this.view.type && this.view.type === 'template') {
      data = data.map((item: any) => {
        item.view = item.view || JSON.parse(JSON.stringify(this.view.templateView));
        
        return item;
      });
    }

    return data;
  }

  async runBackAction() {
    try {
      this.view.backAction({
        modalService: (await this.modalCtrl),
        page: this,
      });
    } catch(e) {
      console.warn('executing back action failed', e);
    }
  }

  runGlobalSearch() {
    try {
      this.view.service.search(this.search.query)
      .then((searchResponse: any) => {
        if(!!searchResponse && searchResponse.length) {
          
          this.view.data = searchResponse;
          delete this.view.data_backup;

          this.detectChanges();
          
          if(!!this.view.data && !!this.view.data.length) {
            this.runSearch(null, false);
          }
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    } catch(error) {
      console.warn('> search failed', error);
    }
  }

  runSearch(event: any = null, blAllowGlobal: boolean = true) {
    let key = 'data', backupKey = `${key}_backup`;
    let searchKeys = ['firstname', 'lastname', 'email', 'nickname', 'title', 'excerpt', 'url', 'description', 'name', 'shift_name', 'region'];

    if(!this.view.hasOwnProperty(backupKey) && this.view[key] && this.view[key].length) {
      this.view[backupKey] = JSON.parse(JSON.stringify(this.view[key]));
    } else
    if(this.view[backupKey] && this.view[backupKey].length) {
      this.view[key] = JSON.parse(JSON.stringify(this.view[backupKey]));
    }

    blAllowGlobal = blAllowGlobal && !!this.search.query && (this.search.query.length > 2);

    if(this.view[key] && this.view[key].length) {

      this.view[key] = this.view[key].filter((item: any) => {
        let blShow: boolean = false;
        searchKeys.forEach((searchKey: string) => {
          if(item.hasOwnProperty(searchKey)) {
            blShow = blShow || `${item[searchKey] ? item[searchKey] : ''}`.toLowerCase().indexOf(this.search.query.toLowerCase()) !== -1;
          }
        });
        return blShow;
      });

      if((!!blAllowGlobal && !this.view[key] || !this.view[key].length)) {
        this.runGlobalSearch();
      }

    } else
    if(!!blAllowGlobal) {
      this.runGlobalSearch();
    }

    this.detectChanges();
  }

  select(item: any) { 
    if(!this.view.multiple) {
      this.dismiss({
        item: item,
        items: [item],
      });
    }
  }

  submitMultiple() {
    this.dismiss({
      items: this.view.data.filter((item: any) => {
        return !!item.checked;
      }),
    });
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
    this.detectChanges();
  }
  
  toggleSelectAll() {

    this.view.data.forEach((item: any) => {
      if(!item.hidden) {
        if(this.view.allSelected) {
          item.checked = false;
        } else {
          item.checked = true;
        }
      }
    });

    this.view.allSelected = !this.view.allSelected;
  }

}