<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">
    
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    
    <ion-buttons slot="end">
      <ion-button (click)="submit()" [disabled]="!view.canSubmit" icon-only>
        <ion-icon name="checkmark-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <ion-toolbar class="container ion-no-border">
    <ion-segment scrollable [(ngModel)]="view.segment" (ionChange)="segmentChanged()">

      <ion-segment-button value="upload" *ngIf="!!view.services.upload">
        <ion-icon [name]="'cloud-upload-outline'"></ion-icon>
        <ion-label [innerHTML]="'upload'|translate"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="media_library" *ngIf="!!view.services.media_library">
        <ion-icon [name]="'images-outline'"></ion-icon>
        <ion-label [innerHTML]="'assets'|translate"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="database" *ngIf="!!view.services.database">
        <ion-icon [name]="'search-outline'"></ion-icon>
        <ion-label [innerHTML]="'stock_photos'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>
  </ion-toolbar>

  <ion-toolbar class="ion-no-border container" *ngIf="view.segment == 'database'">
    <ion-grid>
      <ion-row>

        <ion-col size="10">
          <ion-searchbar autocorrect="on" [(ngModel)]="search.query" #searchInput id="searchInput" [placeholder]="'search_something'|translate" (ionInput)="runSearch($event)"></ion-searchbar>
        </ion-col>

        <ion-col size="2" *ngIf="(view.segment == 'database') && (!!view.filters && !!view.mimeTypes)">
          <ion-item>
            <ion-icon [name]="view.mimeType === 'image' ? 'image-outline' : (view.mimeType === 'video' ? 'film-outline' : 'images-outline')" slot="start"></ion-icon>
            <ion-select [(ngModel)]="view.filters.mimeType" [label]="'type'|translate" labelPlacement="stacked" [okText]="'okay'|translate" [cancelText]="'cancel'|translate">
              <ion-select-option *ngFor="let type of view.mimeTypes" [innerHTML]="type|translate" [value]="type"></ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>

      </ion-row>
    </ion-grid>
  </ion-toolbar>

</ion-header>

<ion-content>

  <ion-refresher slot="fixed" *ngIf="view.segment === 'media_library'" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <!-- upload -->
    <ion-grid [hidden]="view.segment != 'upload'">
      
      <ion-row>
        <ion-col [size]="view.hasThumbnailImg ? 8 : 12">

          <ion-card [hidden]="!view.allowUrl">
            <ion-item lines="none">
              <ion-label>
                <h3 [innerHTML]="'upload_type_url'|translate"></h3>
              </ion-label>
            </ion-item>

            <ion-item lines="none">
              <ion-icon name="link-outline" slot="start"></ion-icon>
              <ion-input type="text" [(ngModel)]="view.url"
                [placeholder]="'url'|translate" (ionInput)="urlChanged()"></ion-input>
            </ion-item>
          </ion-card>

          <ion-card [hidden]="!view.allowYoutubeEmbed">
            <ion-item lines="none">
              <ion-label>
                <h3 [innerHTML]="'upload_type_youtube_embed'|translate"></h3>
              </ion-label>
            </ion-item>

            <ion-item lines="none">
              <ion-textarea rows="5" [(ngModel)]="view.youtube_embed_code"
                [placeholder]="'youtube_embed_code'|translate"
                (ionInput)="youtubeEmbedCodeChanged()"></ion-textarea>
            </ion-item>
          </ion-card>

          <ion-card [hidden]="!view.allowFileUpload">
            <ion-item lines="none">
              <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
              <ion-label>
                <h3 [innerHTML]="'upload_type_file'|translate"></h3>
              </ion-label>
            </ion-item>
            <input type="file" class="web-uploader-file"
              [multiple]="!!view.multiple" />
          </ion-card>

        </ion-col>

        <ion-col [hidden]="!view.hasThumbnailImg" size="4">
          <ion-img [src]="view.thumbnailImg" class="thumbnail-preview
            background" (ionError)="thumbnailLoadingFailed()" (ionImgDidLoad)="ionImgDidLoad($event)"></ion-img>
          <img [src]="view.url" class="thumbnail-preview" />
        </ion-col>

      </ion-row>

      <ion-row *ngIf="!!view.files">
        <ion-col>

          <ion-card>
            <ion-radio-group [(ngModel)]="view.url" (ionChange)="urlChanged()">
              <ion-item *ngFor="let file of view.files">

                <ion-thumbnail *ngIf="!!file.src && file.type !== 'video/mp4'"
                  slot="start">
                  <ion-img [src]="file.src"></ion-img>
                </ion-thumbnail>

                <video *ngIf="!!file.src && file.type === 'video/mp4'"
                  [poster]="fallbackImg" [src]="file.src" playsinline
                  webkit-playsinline [muted]="'muted'" loop autoplay></video>
                <ion-label class="ion-text-wrap">
                  <h2 [innerHTML]="file.name"></h2>
                  <p [innerHTML]="file.url || file.src"></p>
                </ion-label>

                <ion-spinner slot="end" [hidden]="file.response ||
                  !!file.error"></ion-spinner>

                <ion-icon name="warning-outline" color="danger" slot="end"
                  [hidden]="!file.error"></ion-icon>

                <ion-checkbox *ngIf="!!view.multiple" [disabled]="!file.response" [(ngModel)]="file.checked" [hidden]="!!file.error" [value]="file.url" slot="end" (ionChange)="onFileMultiItemClick(file)"></ion-checkbox>
                <ion-radio *ngIf="!view.multiple" [disabled]="!file.response" [hidden]="!!file.error" [value]="file.url" slot="end"></ion-radio>
                
              </ion-item>

            </ion-radio-group>
          </ion-card>

        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- media library -->
    <pipeline-media-library [hidden]="view.segment != 'media_library'" [(view)]="view.libraryView"></pipeline-media-library>

    <!-- stock photos -->
    <ion-grid [hidden]="view.segment != 'database'">

      <ion-spinner [hidden]="!view.loading"></ion-spinner>

      <ion-row *ngIf="!!view.mediaList || !!view.mediaFolders" [hidden]="view.loading">
        <ion-col *ngFor="let result of search.results; let i = index;" [size]="view.colSize" [hidden]="view.segment === 'media_library'">
          <ion-card (click)="chooseMediaFromList(result, i, search.results)" class="mediaItem" [class.active]="result.checked">
            
            <ion-thumbnail>
              <ion-img [src]="(result.thumbnail || result.photo) || fallbackImg" (ionError)="thumbnailLoadingFailed(result)"></ion-img>
            </ion-thumbnail>
            
            <ion-icon name="checkmark-outline" class="video" [hidden]="!result.checked"></ion-icon>
            <ion-icon name="film-outline" class="video" [hidden]="!!result.checked || result.post_mime_type != 'video'"></ion-icon>
            
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>

</ion-content>