import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';

@Component({
  selector: 'app-report-content',
  templateUrl: './report-content.page.html',
  styleUrls: ['./report-content.page.scss'],
})
export class ReportContentPage implements OnInit {

  reportingService: any;

  view: any = {
    reason: '',
    reasons: ['broken_content', 'fake_news', 'hate_speech', 'outdated', 'spam', 'other'],
  };

  constructor(
    private events: EventsService,
    private modalCtrl: ModalService,
    private navParams: NavParams,
  ) {
    this.reportingService = this.navParams.get('reportingService');

    this.view.item = this.navParams.get('item');
  }

  calcCanSubmit() {
    this.view.canSubmit = !!(this.view && (!!this.view.item && !!this.view.item.uid) && !!this.view.type && !!this.view.reason);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
    this.view.item = (this.view && this.view.item && this.view.item ? this.view.item : null);
    this.view.type = (this.view && this.view.item && !!this.view.item.type ? this.view.item.type : null);
    
    this.calcCanSubmit();
  }

  onReasonChanged(event: any = null) {
    this.calcCanSubmit();
  }

  submit() {

    this.reportingService.submit({
      item: this.view.item.uid,
      reason: this.view.reason,
      type: this.view.type,
    })
    .then(() => {
      this.dismiss();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

}
