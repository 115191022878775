import { Component, Input, OnInit, NgZone } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AbonnementService } from 'src/app/services/core/abonnement.service';
import { EventsService } from 'src/app/services/core/events.service';
import { PurchaseService } from 'src/app/services/ecommerce/purchase.service';
import { UserService } from 'src/app/services/core/user.service';
import { WebService } from 'src/app/services/core/web.service';

@Component({
  selector: 'pipeline-post-vip-card',
  templateUrl: './post-vip-card.component.html',
  styleUrls: ['./post-vip-card.component.scss'],
})
export class PostVipCardComponent implements OnInit {
  @Input() post: post;

  user: user;

  view: any = {};

  constructor(
    private abo: AbonnementService,
    private events: EventsService,
    private navCtrl: NavController,
    private purchaseService: PurchaseService,
    public userService: UserService,
    private webService: WebService,
    private zone: NgZone,
  ) {

  }

  changeAbonnement() {
    this.navCtrl.navigateForward('/abonnement');
    this.events.publish('post:reader:dismiss');
  }

  initPurchases() {
    if(!this.view.purchaseInitialized) {
      try {
        this.purchaseService.init();
        this.view.purchaseInitialized = true;
      } catch(e) {
        console.warn('post: purchase init failed', e);
      }
    }
  }

  ngOnInit() {

  }

  promptBetaTest() {

    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    this.abo.promptBetaTest()
    .then(() => {
      this.user = this.userService.getUser();

      this.post.vip = false;
      this.events.publish('post:reader:updated', this.post);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  // purchase a content
  purchase(post: post) {
    this.initPurchases();

    setTimeout(() => {
      this.purchaseService.purchase('de.app.tie.vip')
      .then((response: any) => {
        this.zone.run(() => {
          this.post.vip = false;
          this.events.publish('post:reader:updated', this.post);
        });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }, 150);
  }

}
