import { Injectable } from '@angular/core';
import { SettingsService } from 'src/app/services/core/settings.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';
import { TabsService } from 'src/app/services/core/tabs.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { BannersService } from 'src/app/services/extensions/banners.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  fallbackState: any;

  placeholder: any = {
    items: [{},{},{}]
  };

  state: state;

  visibleCategories: any[];

  constructor(
    private banners: BannersService,
    private cache: CacheService,
    private events: EventsService,
    private settings: SettingsService,
    private tabs: TabsService,
    private tools: ToolsService,
    public userService: UserService,
  ) {
    this.initFallbackState();
  }

  async applyBlogEntriesToSlide(blogEntries: post[], state = null, slideIndex: number) {
    let cols = [];

    blogEntries.forEach((blogEntry: post) => {
      let col: col = {
        active: !!blogEntry.active,
        allowed: !!(this.isPostAllowed(blogEntry)),
        color: blogEntry.color,
        collection: blogEntry.collection,
        category: blogEntry.category,
        date_gmt: blogEntry.date_gmt,
        date_gmt_formatted: blogEntry.date_gmt_formatted,
        excerpt: blogEntry.excerpt,
        external: !!blogEntry.external,
        genre: blogEntry.genre,
        host: blogEntry.host,
        host_uid: blogEntry.host_uid,
        //hostLabel: blogEntry.hostLabel || (blogEntry.host ? 'recommended_by_us' : null),
        local: !!blogEntry.local,
        modified_gmt: blogEntry.modified_gmt,
        modified_gmt_formatted: blogEntry.modified_gmt_formatted,
        name: blogEntry.name,
        post_content: blogEntry.post_content,
        rating: blogEntry.rating,
        reactions: blogEntry.reactions,
        region: blogEntry.region,
        relatedArticles: blogEntry.relatedArticles,
        sponsored: !!blogEntry.sponsored,
        thumbnail: blogEntry.thumbnail,
        timestamp: blogEntry.timestamp,
        timestamp_formatted: blogEntry.timestamp_formatted,
        type: blogEntry.type || blogEntry.post_type,
        uid: (blogEntry.uid || blogEntry.ID),
        url: blogEntry.url,
        verified: blogEntry.verified,
        vip: !!blogEntry.vip,
      };
      cols.push(col);
    });

    if(state && state.slides && !!state.slides[slideIndex]) {
      state.slides[slideIndex].postIds = state.slides[slideIndex].postIds || [];
      state.slides[slideIndex].rows = state.slides[slideIndex].rows || [];
      state.slides[slideIndex].rows[0] = state.slides[slideIndex].rows[0] || {cols:[]};
      cols.forEach((col: col) => {
        if(state.slides[slideIndex].postIds.indexOf(col.uid) === -1) {
          state.slides[slideIndex].postIds.push(col.uid);
          state.slides[slideIndex].rows[0].cols.push(col);
        }
      });
    }

    this.state = state;
    return state;
  }

  async applyBlogEntries(blogEntries: post[], state = null, slideIndex: number = null) {
    state = state || (await this.get());

    if(slideIndex !== null) {
      return this.applyBlogEntriesToSlide(blogEntries, state, slideIndex);
    }

    if(state && state.slides && state.slides.length) {
      state.slides.forEach((slide: slide) => {
        slide.postIds = slide.postIds || [];
        blogEntries.forEach((blogEntry: post) => {
          if((blogEntry.category === slide.uid) || (slide.uid === 'local' && this.isRegionalMatch(blogEntry))) {
            slide.rows = slide.rows || [];
            slide.rows[0] = slide.rows[0] || { cols: []};
            let col: col = {
              active: blogEntry.active,
              allowed: this.isPostAllowed(blogEntry),
              color: blogEntry.color,
              collection: blogEntry.collection,
              category: blogEntry.category,
              date_gmt: blogEntry.date_gmt,
              date_gmt_formatted: blogEntry.date_gmt_formatted,
              excerpt: blogEntry.excerpt,
              external: blogEntry.external,
              genre: blogEntry.genre,
              host: blogEntry.host,
              host_uid: blogEntry.host_uid,
              //hostLabel: blogEntry.hostLabel || (blogEntry.host ? 'recommended_by_us' : null),
              local: blogEntry.local,
              modified_gmt: blogEntry.modified_gmt,
              modified_gmt_formatted: blogEntry.modified_gmt_formatted,
              rating: blogEntry.rating,
              reactions: blogEntry.reactions,
              region: blogEntry.region,
              relatedArticles: blogEntry.relatedArticles,
              name: blogEntry.name,
              post_content: blogEntry.post_content,
              sponsored: blogEntry.sponsored,
              thumbnail: blogEntry.thumbnail,
              timestamp: blogEntry.timestamp,
              timestamp_formatted: blogEntry.timestamp_formatted,
              type: blogEntry.type || blogEntry.post_type,
              uid: (blogEntry.uid || blogEntry.ID),
              url: blogEntry.url,
              verified: blogEntry.verified,
              vip: blogEntry.vip,
            };
            if(slide.postIds.indexOf(col.uid) === -1) {
              slide.postIds.push(col.uid);
              slide.rows[0].cols.push(col);
            }
          }
        });
        if(slide.rows && slide.rows && slide.rows.length) {
          slide.rows.forEach((row: row) => {
            if(row.cols && row.cols.length) {
              row.cols = this.tools.shuffle(row.cols);
            }
          });
        }
      });
    }

    return this.applyGridSize(state);
  }

  async applyBlogEntriesToHome(blogEntries: any, state = null) {
    state = state || (await this.get());

    let cols: col[] = [];

    blogEntries.forEach((blogEntry: any) => {
      let col = this.toCol(blogEntry);
      if(!!(col.excerpt && col.post_content && col.thumbnail)) {
        cols.push(col);
      }
    });

    let row: row = { cols: this.tools.shuffle(cols) };
    state.slides[0].rows = [row];

    return this.applyGridSize(state);
  }

  applyCategories(categories: any, state = null, blFilter: boolean = true) {
    return new Promise((resolve, reject) => {
      this.getCategoryStates()
      .then(async (categoryStates: any) => {
        
        if(blFilter && categoryStates && Object.values(categoryStates).length) {
          let filteredCategories = this.filterVisibleCategories(categories, categoryStates);
          if(filteredCategories) {
            categories = filteredCategories;
          }
        }

        if(blFilter) {
          this.setVisibleCategories(categories);
        }

        state = state || (await this.get());
        let slides = [];

        if(categories && categories.length) {
          categories.forEach((category: any) => {
            let slide: slide = {
              uid: category.remote_uid,
              icon: category.icon,
              title: category.name,
              categories: category.categories,
              rows: [],
            };
            slides.push(slide);
          });
        }
        
        slides = this.tools.shuffle(slides);

        if(state.slides && state.slides.length) {
          state.slides = state.slides.concat(slides);
        }

        resolve(state);
      })
      .catch(reject);
    });
  }

  applyGridSize(state = null, blForceOverwrite: boolean = false) {
    let last: number;
    //let twoBefore: number, threeBefore: number, fourBefore: number;

    if(state.slides && state.slides.length) {
      state.slides.forEach((slide: slide) => {
        if(slide.rows && slide.rows.length) {
          slide.rows.forEach((row: row) => {
            if(row.cols && row.cols.length) {
              row.cols.forEach((col: any, iCol: number) => {
                last = row.cols.hasOwnProperty(iCol-1) ? row.cols[iCol-1].size : null;
                //twoBefore = row.cols.hasOwnProperty(iCol-2) ? row.cols[iCol-2].size : null;
                //threeBefore = row.cols.hasOwnProperty(iCol-3) ? row.cols[iCol-3].size : null;
                //fourBefore = row.cols.hasOwnProperty(iCol-3) ? row.cols[iCol-3].size : null;
                col.color = col.color || 'light';
                col.size = !!col.size && !blForceOverwrite && (!col.category || col.category !== 'home')? col.size : this.tools.getCalcRandomSize(last);
              });
            }
          });
        }
      });
    }

    this.state = state;
    return state;
  }

  async applyProducts(products: product[], state = null) {
    state = state || (await this.get());

    state.slides.forEach((slide: slide) => {
      let productsBySlide = products.filter((product: product) => {
        let blMatch = false;
        if(product.categories && product.categories.length) {
          product.categories.forEach((category: category) => {
            blMatch = blMatch || (category.name.replace('&amp;', '&').toLowerCase().indexOf(slide.title.replace('&amp;', '&').toLowerCase()) !== -1);
          });
        }
        return blMatch;
      });
      if(productsBySlide && productsBySlide.length) {
        let row: row = { cols: [] };
        productsBySlide.forEach((product: product) => {
          row.cols.push(product as col);
        });
        slide.rows = slide.rows || [];
        slide.rows.push(row);
        slide.rows = this.tools.shuffle(slide.rows);
      }
    });
    return this.applyGridSize(state);
  }

  async applySlides(state: state) {
    let homeView = await this.settings.getSetting('homeView');
    let appSideBanners = this.banners.getAppSideBanners();

    if(state && state.slides) {
      state.slides.forEach((slide: slide, iSlide: number) => {
        if(slide && slide.rows && slide.rows.length) {

          slide.rows.forEach((row: row, iRow: number) => {
            if(row && row.cols && row.cols.length) {
              row.cols.forEach((col: col, iCol: number) => {
                state.slides[iSlide].rows[iRow].cols[iCol] = this.toCol(col);
              });
              state.slides[iSlide].rows[iRow].cols = this.tools.shuffle(state.slides[iSlide].rows[iRow].cols);
            }
          });

          let appSideBannersByCategory = appSideBanners.filter((appSideBanner: col) => {
            return appSideBanner.category === slide.title;
          });

          // apply slide app banner ads to slide
          if(appSideBannersByCategory && appSideBannersByCategory.length && (Math.random() < 0.2)) {
            state.slides[iSlide].rows[0].cols = state.slides[iSlide].rows[0].cols.concat(appSideBannersByCategory);
            state.slides[iSlide].rows[0].cols = this.tools.shuffle(state.slides[iSlide].rows[0].cols);
          }

        }
      })
    }

    if(homeView === 1) {
      return this.applyGridSize(state, true);
    }
    
    return state;
  }

  calcPairs(items: any[], blShuffle: boolean = true, options: any = {}) {
    let size: number = (window.innerWidth >= 1024 ? 4 : (window.innerWidth >= 768 ? 6 : 12)),
        pairs: any[] = [],
        a: number = 0;

    if(items && items.length) {

      if(!!blShuffle) {
        items = this.tools.shuffle(items);
      }

      let customWidgets: string[] = [];

      if(!!options.includeCouponsSuggestions) {
        customWidgets.push('coupons-suggestions-slider');
      }

      if(!!options.includeJobPostingsSuggestions) {
        customWidgets.push('jobpostings-suggestions-slider');
      }

      if(!!options.includeMoviesSuggestions) {
        customWidgets.push('movies-suggestions-slider');
      }

      if(!!options.includePodcastsSuggestions) {
        customWidgets.push('podcasts-suggestions-slider');
      }

      items.forEach((item: any, index: number) => {
        let x: number = (12 / size), 
            m: number = (index % x),
            iRand: number = Math.random(),
            blRand: boolean = (iRand < 0.05),
            bl: boolean = !!(m === (x - 1)) || ((x > 1) && blRand);

        pairs[a] = pairs[a] || [];

        if((m === 0 || !bl) && !!blRand) {
          let randWidget = customWidgets[Math.floor(Math.random() * customWidgets.length)];
          
          if(!!randWidget) {
            pairs[a].push({
              type: 'component',
              widget: randWidget,
            });
          }
          
        } else {
          pairs[a].push(item);
        }

        if(bl) {
          a++;
        }
      });
    }

    return pairs;
  }

  cleanCategory(category: any) {
    delete category.cat_name;
    delete category.category_count;
    delete category.category_description;
    delete category.category_parent;
    delete category.category_nicename;
    delete category.count;
    delete category.description;
    delete category.filter;
    delete category.parent;
    delete category.slug;
    delete category.taxonomy;
    delete category.term_group;
    delete category.term_taxonomy;
    delete category.term_taxonomy_id;

    category.icon = this.tools.getCategoryIcon(category.name);
    
    return category;
  }

  filterVisibleCategories(categories: category[], categoryStates: any) {
    return categories.filter((category: category) => {
      let percentage = (categoryStates[category.term_id] / 5), random = Math.random() < percentage;
      
      if(category.hasOwnProperty('categories') && category.categories.length) {
        category.categories = category.categories.filter((subCategory: category) => {
          if(subCategory.count) {
            let percentage = (categoryStates[subCategory.term_id] / 2), random = Math.random() < percentage;
            return random;
          }
        });
        category.categories = category.categories.slice(0,3);
      }

      return random;
    });
  }

  async get() {
    return new Promise((resolve, reject) => {
      if(!this.state) {
        this.reset().then((state: state) => {
          this.state = state;
          resolve(state);
        }).catch(reject);
      } else {
        resolve(this.state);
      }
    });
  }

  getCategoryStates() {
    return new Promise((resolve, reject) => {
      this.settings.getSetting('categories')
      .then((categories: any) => {
        let categoryStates = {};
        if(categories) {
          Object.keys(categories).forEach((categoryId: any) => {
            categoryStates[categoryId] = categories[categoryId].rank;
            Object.keys(categories[categoryId].children).forEach((childId: any) => {
              categoryStates[childId] = categories[categoryId].children[childId] ? 3 : 0;
            });
          });
        }
        resolve(categoryStates);
      })
      .catch(reject);
    });
  }

  getFallbackState() {
    let user = this.userService.getUser() || {};

    return new Promise(async (resolve, reject) => {
      let slides: slide[] = [];

      let showHome = await this.tabs.isActive('home') as boolean;

      if(showHome) {
        slides.push({
          uid: 'home',
          title: 'home',
          icon: 'home-outline',
          rows: [],
        });
      }

      let showDiscover = await this.tabs.isActive('discover') as boolean;

      if(showDiscover) {
        slides.push({
          uid: 'discover',
          title: 'discover',
          icon: 'search-outline',
          rows: [],
        });
      }

      let showPeople = await this.tabs.isActive('people') as boolean;

      if(showPeople) {
        slides.push({
          uid: 'people',
          title: 'follow',
          icon: 'people-outline',
          rows: [],
        });
      }

      let showLocal = await this.tabs.isActive('local') as boolean;

      if(showLocal) {
        slides.push({
          uid: 'local',
          title: 'local',
          icon: 'location-outline',
          selectData: {
            selection: user.city,
            options: [
              {
                value: user.city,
                label: user.city,
              }
            ]
          },
          rows: [],
        });
      }

      let fallbackState = {
        activeIndex: 0,
        searchTab: 'everything',
        slides: slides
      }

      resolve(fallbackState);

    });
  }

  getPlaceholder() {
    return this.placeholder;
  }

  async getVisibleCategories() {
    let fromCache: cacheItem = await this.cache.get('pipeline_visible_categories', -1);
    return this.visibleCategories || (fromCache && fromCache.data ? fromCache.data : null);
  }
  
  initFallbackState() {
    this.getFallbackState()
    .then((fallbackState: state) => {
      this.fallbackState = fallbackState;
    })
    .catch((error: any) => {
      console.warn('fallback state error', error);
    });
  }

  isPostAllowed(post: post) {
    return !post.vip || (post.vip && post.purchased);
  }

  isRegionalMatch(post: post) {
    let user = this.userService.getUser() || {};
    return post.local && post.region === user.city;
  }

  set(state: any) {
    this.state = state;
    this.events.publish('state:changed', this.state);
  }

  setBlogEntries(blogEntries: post[], iSlide: number, state: state) {
    if(state.slides[iSlide]) {
      state.slides[iSlide].rows = [
        {
          cols: []
        }
      ];
      blogEntries.forEach((blogEntry: post) => {
        let col: col = {
          active: blogEntry.active,
          allowed: this.isPostAllowed(blogEntry),
          category: blogEntry.category,
          collection: blogEntry.collection,
          color: blogEntry.color,
          date_gmt: blogEntry.date_gmt,
          date_gmt_formatted: blogEntry.date_gmt_formatted,
          excerpt: this.tools.stripHtml(blogEntry.excerpt),
          external: blogEntry.external,
          host: blogEntry.host,
          host_uid: blogEntry.host_uid,
          //hostLabel: blogEntry.hostLabel || (blogEntry.host ? 'recommended_by_us' : null),
          local: blogEntry.local,
          modified_gmt: blogEntry.modified_gmt,
          modified_gmt_formatted: blogEntry.modified_gmt_formatted,
          name: blogEntry.name,
          post_content: blogEntry.post_content,
          price: blogEntry.price,
          rating: blogEntry.rating,
          reactions: blogEntry.reactions,
          region: blogEntry.region,
          relatedArticles: blogEntry.relatedArticles,
          sponsored: !!blogEntry.sponsored,
          type: blogEntry.type || blogEntry.post_type,
          thumbnail: blogEntry.thumbnail,
          timestamp: blogEntry.timestamp,
          timestamp_formatted: blogEntry.timestamp_formatted,
          uid: (blogEntry.uid || blogEntry.ID),
          url: blogEntry.url,
          verified: !!blogEntry.verified,
          vip: !!blogEntry.vip,
        };
        state.slides[iSlide].rows[0].cols.push(col);
      });
      if(state.slides[iSlide].rows && state.slides[iSlide].rows[0] && state.slides[iSlide].rows[0].cols && !state.slides[iSlide].rows[0].cols.length) {
        state.slides[iSlide].rows = [];
      }
    }

    this.state = state;
    return state;
  }

  setVisibleCategories(categories: any) {
    if(categories && categories.length) {
      categories.forEach((category: any) => {
        if(category.categories && category.categories.length) {
          category.categories.forEach((subCategory: any) => {
            subCategory = this.cleanCategory(subCategory);
          });
        }
        category = this.cleanCategory(category);
      });
      categories = categories.filter((_category: category) => {
        return _category.name !== 'Allgemein';
      })
      this.visibleCategories = (this.tools.shuffle(categories) || []).slice(0, 10);
      this.cache.set('pipeline_visible_categories', categories);
      this.events.publish('categories:visible:set', categories);
    }
  }

  reset() {
    return new Promise(async (resolve, reject) => {
      let fallbackState = await this.getFallbackState();
      this.state = JSON.parse(JSON.stringify(fallbackState));
      resolve(this.state);
    });
  }

  toCol(blogEntry: any) {
    let col: col = {
      active: !!blogEntry.active,
      allowed: !!(this.isPostAllowed(blogEntry)),
      author: blogEntry.author,
      category: 'home',
      collection: blogEntry.collection,
      color: blogEntry.color,
      date_gmt: blogEntry.date_gmt,
      date_gmt_formatted: blogEntry.date_gmt_formatted,
      excerpt: this.tools.stripHtml(blogEntry.excerpt && blogEntry.excerpt.rendered ? blogEntry.excerpt.rendered : (blogEntry.excerpt || blogEntry.post_excerpt)),
      external: blogEntry.external,
      host: blogEntry.host,
      host_uid: blogEntry.host_uid,
      //hostLabel: blogEntry.hostLabel || (blogEntry.host ? 'recommended_by_us' : null),
      local: blogEntry.local,
      modified_gmt: blogEntry.modified_gmt,
      modified_gmt_formatted: blogEntry.modified_gmt_formatted,
      name: blogEntry.name,
      post_content: blogEntry.post_content,
      price: blogEntry.price,
      purchased: !!blogEntry.purchased,
      rating: blogEntry.rating,
      region: blogEntry.region,
      reactions: blogEntry.reactions,
      relatedArticles: blogEntry.relatedArticles,
      size: 12,
      sponsored: !!blogEntry.sponsored,
      type: blogEntry.type || blogEntry.post_type,
      thumbnail: blogEntry.thumbnail,
      timestamp: blogEntry.timestamp,
      timestamp_formatted: blogEntry.timestamp_formatted,
      uid: (blogEntry.uid || blogEntry.ID),
      url: blogEntry.url,
      verified: !!blogEntry.verified,
      vip: !!blogEntry.vip,
    };
    return col;
  }

}
